<template>
  <div id="app">
      <notifications/>
      <div class="router-view-wrapper">
        <router-view/>
        <audio :src="$defaultAudio"  preload="auto" type="audio/mpeg" id="audio" controls style="display:none"> </audio>
      </div>
  </div>
</template>
<style>
#app {
  font-family: 'muller';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}


</style>
<script>
import firebase from 'firebase'
import "firebase/messaging";
export default{
 
  data(){
    return{
      idleTime : 0
    }
  },
  created(){
 
        var self = this;
        self.$root.$on("audioplay",() => {
          
          //  var sound = document.getElementById("audio");
          //   sound.currentTime = 0;
          //   sound.play();
        })
        self.$root.$on("messageSendAudio",() => {
          
          var sound = document.getElementById("audioMessageSend");
            sound.currentTime = 0;
            sound.play();
        })
    
   
  },
 mounted()
  {
   

    var self = this

    firebase.auth().onAuthStateChanged(user=>{
      if(user)
      {
        var idleInterval;
       
        function timerIncrement() {
          self.idleTime = self.idleTime + 1;
          // console.log("idleTime",self.idleTime)
          if (self.idleTime >= 30) { // 30 minutes
            
           window.location.reload();

            clearInterval(idleInterval);

            resetTimer;
          
          }
        }
    
        function resetTimer() {
          self.idleTime = 0;
        // console.log('resetTimer',self.idleTime)
          
        }
       
    
        idleInterval = setInterval(timerIncrement, 60000); // 1 minute
    
     
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer;
        window.onclick = resetTimer;
        window.onscroll = resetTimer;
        window.onkeypress = resetTimer;
      
      }
    })

    
  },
}
</script>
