<!-- Shraddha Chaniyara, 22/06/2021 added validation and update new password in db. -->

<template>
    <div class="resetpassword-wrapper login-wrapper">
         <div class="logo">
                <img src="@/assets/images/logo.png" class="logo-login">
            </div>
             <ValidationObserver ref="log" v-slot="{ handleSubmit }" @reset.prevent="resetForm">
            <form class="login-form c_form" @submit.prevent="handleSubmit(resetPassword)">
                <div class="form-title text-center">
                    <h2>Reset Password</h2>
                    <img src="@/assets/images/afterimg.png">
                </div>
                 <ValidationProvider rules="required|min:6" name="newpassword" v-slot="{ errors }">  
                <div class="form-group mb-5">
                    <!-- <label>New Password *</label> -->
                    <input :type="passwordType" maxlength="25" class="form-control" placeholder="Enter new password*" v-model="newpassword" autocomplete="off" @keyup="validatePassword($event)">
                    <img v-if="passwordType=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye eye_createaccount">
                    <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye eye_createaccount">
                    <b-button v-b-tooltip.click.rightbottom class="btn-tooltip btn-tooltip-resetpass"  @click="showtooltip()" :title="showtooitip ? 'Password contains Minimum 8 characters, at least one letter, one number and one special character.': ''"><img src="@/assets/images/view_detail-orange.png" title='' alt="signin" style="cursor:pointer!important"></b-button>
                    <span class="InvalidInput">{{ errors[0] }}</span>
                    <span class="InvalidInput password_validation_message" v-if="passwordErrorMessage!= '' ">{{passwordErrorMessage}}</span>

                </div>
                </ValidationProvider>
                <ValidationProvider rules="required|confirmed:newpassword" name="confirmPassword" v-slot="{ errors }">                                
                <div class="form-group">
                    <!-- <label>confirm new Password *</label> -->
                    <input :type="passwordTypes" maxlength="25" class="form-control" placeholder="Enter confirm new password*" v-model="confirmPassword">
                    <img v-if="passwordTypes=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPasswords();" id="togglePassword" class="password_eye eye_createaccount">
                    <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPasswords();" id="togglePassword" class="password_eye eye_createaccount">
                    <span class="InvalidInput">{{ errors[0] }}</span>
                </div>
                 </ValidationProvider>
                <div class="btn-wrapper">
                     <b-button type="button" variant="primary" class="btn btn-save" disabled v-if="isSpinner">
                        Loading....
                    </b-button>
                    <button class="btn btn-yellow" v-if="!isSpinner">Submit</button>
                </div>
            </form>
            </ValidationObserver>
            <FrontFooter/>
    </div>
</template>
<script>
import firebase from '../firebaseInit'
import FrontFooter from "@/components/forntfooter.vue";
import firebaseData from '../../utils/firebaseCollection.js';


// import admin from '../../admin_firebase'

export default {
    name:"adminresetpassword",
    components: {
        FrontFooter,
        firebaseData
    },
    data(){
        return{
            isSpinner: false,
            newpassword:null,
            confirmPassword:null,
            docId:this.$route.params.docId,
            showPage:false,
            passwordType:"password",
            passwordTypes:"password",
             showtooitip:false,
            passwordErrorMessage : ''
        }
    },
    
    created(){
        const db = firebase.firestore()
        db.collection(firebaseData.firebasecollection.USER).doc(this.docId).get().then(doc=>{
            if(doc.exists)
            {
                if(doc.data().emailVerified)
                {
                    this.$router.push({name:"adminforgotpassword"})
                }
                else
                {
                    this.showPage=true

                    db.collection(firebaseData.firebasecollection.USER).doc(this.docId).update({emailVerified:true})
                    .catch(error=>{
                        console.log(error)
                    })
                }
            }
            else{
                this.$router.push({name:"adminforgotpassword"})
            }
        })
        .catch(error=>{
            console.log(error)
        })
    },
    methods:{
    // SET NEW PASSWORD (Shraddha)    
        resetPassword()
        {
            // //debugger // eslint-disable-line
            var self = this
            self.showtooitip = false;
            self.isSpinner=true;
            if(self.newpassword==self.confirmPassword && self.passwordErrorMessage == '')
            {
                self.$axios.post(firebaseData.firebasecollection.APIDOMAIN +'/reset',{docId:self.docId,newpassword:self.newpassword})
                .then(data=>{
                    //console.log(data)

                    self.isSpinner=false;
                    self.$notify({
                        title:"Success",
                        text:"Password updated successfully!",
                        type:"success"
                    })
                    self.$router.push({name:'adminsignin'})
                        
                })
                .catch(error=>{
                    self.isSpinner=false;
                    self.$notify({
                        title:"Error",
                        text:error.message,
                        type:"error"
                    })
                })
            }
            else
            {
                self.isSpinner=false;
                self.$notify({
                    title:"ERROR",
                    text:"Password did not match.",
                    type:"error"
                })
            }
        },
         // TOGGLE PASSWORD (Shraddha)        
        showPassword(){
            if(this.passwordType=='password')
                {
                    this.passwordType='text'
                }
                else
                {
                    this.passwordType='password'
                }
        },
        showPasswords(){
            if(this.passwordTypes=='password')
                {
                    this.passwordTypes='text'
                }
                else
                {
                    this.passwordTypes='password'
                }
        },
        validatePassword(evt){
            //debugger // eslint-disable-line
            var self=this;
            //console.log(evt.target.value)
            var regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
            if(regex.test(evt.target.value)){
                // //console.log('dfd',regex.test(evt.target.value))
                self.passwordErrorMessage = ''
            }else{
                if(evt.target.value != ''){
                    self.passwordErrorMessage = 'Password contains Minimum 8 characters, at least one letter, one number and one special character.';
                    self.$refs.log.reset();
                }else{
                    self.passwordErrorMessage = ''
                }
            }
        },
        showtooltip(){
        //debugger // eslint-disable-line
            if(this.showtooitip == true){
                this.showtooitip = false
            }else{
                this.showtooitip = true
            }
        },   
    }
}
</script>
