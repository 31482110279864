<!-- Shraddha Chaniyara, 22/06/2021 added validation and update new password in db. -->

<template>
    <div class="resetpassword-wrapper login-wrapper" v-if="showPage">
        <div class="logo">
            <router-link to="/"><img src="@/assets/images/logo.png" class="logo-login"></router-link>
        </div>
        <ValidationObserver ref="log" v-slot="{ handleSubmit }" @reset.prevent="resetForm">
        <form v-if="linkExpired" style="text-align: center;" class="login-form c_form" @submit.prevent="handleSubmit(resetPassword)">
            <h2 class="center">Link Expired.</h2>
            <h5 style="margin-top:30px">{{showText}}</h5>
            <div class="btn-wrapper" style="text-align: center;">
                <!-- <router-link to="/"><button style="margin-right: 20px;" class="btn btn-yellow">Go to Login page</button></router-link> -->
                
                <!-- JYOTI YADAV [13-01-22-01-20] -->
                <!-- <button style="margin-right: 20px;" @click='gotoLoginBtn()' class="btn btn-yellow">Go to Login page</button> -->
                <a href='dooey://app' @click='gotoLoginBtn()' style="margin-top: 20px;" v-show="osProto != 'iOS'" class="btn btn-yellow android_verification_btn">Go to Login page</a>
                <a href='https://apps.apple.com/us/app/dooey-messenger/id1196280355' style="margin-top: 20px;" v-show="osProto == 'iOS'" class="btn btn-yellow ios_verification_btn">Download App</a>
                <a href='dooey://app' @click='gotoLoginBtn()' style="margin-top: 20px;" v-show="osProto == 'iOS'" class="btn btn-yellow ios_verification_btn">Open App</a>
            </div>
        </form>
        <form v-if="!linkExpired" class="login-form c_form" @submit.prevent="handleSubmit(resetPassword)" style="position:relative;">
            <!-- <sync-loader style="text-align: center;" v-if="showLoader" :loading="true" color="#ffab3f"></sync-loader> -->
             <div class="loader_div" v-if="showLoader">
          <!-- <sync-loader :loading="true" color="#77bef6"></sync-loader> -->
                <img src="@/assets/images/Orange_GIF.gif" class="lodar_gif">
            </div>
            <div v-else class="form-title text-center">
                <h2>Account Verified</h2>
                <img src="@/assets/images/afterimg.png">
                <h5 style="margin-top:30px">Your account has been verified successfully.</h5>
            </div>
            <div v-if="!showLoader" class="btn-wrapper">
                <!-- <router-link to="/" ><button class="btn btn-yellow">Go to Login page</button></router-link> -->

                <!-- JYOTI YADAV [13-01-22-01-20] -->
                <!-- <button class="btn btn-yellow" @click='gotoLoginBtn()'>Go to Login page</button>  -->
                <a href='dooey://app' @click='gotoLoginBtn()' style="margin-top: 20px;" v-show="osProto != 'iOS'" class="btn btn-yellow android_verification_btn">Go to Login page</a>
                <a href='https://apps.apple.com/us/app/dooey-messenger/id1196280355' style="margin-top: 20px;" v-show="osProto == 'iOS'" class="btn btn-yellow ios_verification_btn">Download App</a>
                <a href='dooey://app' @click='gotoLoginBtn()' style="margin-top: 20px;" v-show="osProto == 'iOS'" class="btn btn-yellow ios_verification_btn">Open App</a>

                <!-- <router-link to="dooey:://app" v-else-if="origin == 'ios'"><button class="btn btn-yellow">Go to Login page</button></router-link> -->
                <!-- <a href="dooey://app" v-else-if="origin == 'ios'"><button class="btn btn-yellow">Go to Login page</button></a> -->
                <!-- <router-link to="/" v-else><button class="btn btn-yellow">Go to Login page</button></router-link> -->
            </div>
        </form>
        </ValidationObserver>
        <FrontFooter/>
    </div>
</template>

<script>
import Vue from 'vue'
import FrontFooter from '@/components/forntfooter.vue'
import firebase from './firebaseInit'
import firebaseData from '../../src/utils/firebaseCollection.js';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
const db = firebase.firestore()

export default {
 name:"resetPassword",
    components:{
        FrontFooter,
        firebaseData,
        SyncLoader
    },
    data(){
        return{
            isSpinner: false,
            newpassword:null,
            confirmPassword:null,
            docId:this.$route.params.docId,
            resetId:this.$route.params.resetId,
            origin:this.$route.params.origin,
            showPage:false,
            linkExpired:true,
            showLoader:true,
            showText:'',
            osProto:Vue.prototype.$currentOs,
            dooeyBotAdmin : {}
        }
    },
    created(){
        console.log(this.osProto,'this is a proto');
        this.$axios.post(firebaseData.firebasecollection.APIDOMAIN + '/botuserAddInContact',{docId : this.docId})
        .then((results)=>{
            console.log('result',results)
            db.collection(firebaseData.firebasecollection.USER)
            .doc(this.docId)
            .get()
            .then(doc=>{
                if(doc.exists){
                    // console.log('====',doc.data().verifyLinkId, doc.data().resetLinkId == this.resetId)
                    this.showPage=true
                    if(doc.data().emailVerified)
                    {
                        // this.linkExpired = true
                        this.showText="Your account is already verified."
                    }
                    else if(doc.data().verifyLinkId=='')
                    {
                        // this.linkExpired = true
                        this.showText="This link is already expired."
                    }
                    else if(doc.data().verifyLinkId != this.resetId)
                    {
                        // this.linkExpired = true
                        this.showText="This link is already expired."
                    }
                    else if(doc.data().verifyLinkId == this.resetId)
                    {
                        this.linkExpired=false
                        this.resetPassword()
                    }
                }
                else
                {
                    this.$router.push({name:"forgotpassword"})
                }
                console.log(doc.data(),this.linkExpired,this.showPage)
            })
            .catch(error=>{
                console.log(error)
            })
       })
    },
    methods:{
    //JYOTI YADAV [13-01-22-01-20]
        gotoLoginBtn(){
            
            // console.log(Vue.prototype.$currentOs,"CURRENT OPERATING SYSTEM....")
            let getOs = Vue.prototype.$currentOs;

            
            let redirect_url = window.location.origin;
            // let application_url = 'dooey://app';
            
            if(getOs=='iOS'){
                redirect_url = 'https://apps.apple.com/us/app/dooey-messenger/id1196280355'; 
            }
            if(getOs=='Android'){
                redirect_url = 'https://play.google.com/store/apps/details?id=com.dooeyapp.dev&hl=en&gl=US';
            }
            setTimeout(function(){
                window.location.replace(redirect_url);
            },5000);
        },

    //SET NEW PASSWORD(Shraddha)    
        resetPassword(){
            var self = this
            self.isSpinner=true;

            if(self.newpassword==self.confirmPassword)
            {
                self.$axios.post(firebaseData.firebasecollection.APIDOMAIN +'/verifyAccount',{docId:self.docId})
                .then(data=>{
                    //console.log(data)
                    self.isSpinner=false;
                    self.$notify({
                        title:"Success",
                        text:"Account verified successfully.",
                        type:"success"
                    })
                    self.showLoader=false
                    // self.$router.push({name:'login'})
                })
                .catch(error=>{
                    self.isSpinner=false;
                    self.$notify({
                        title:"Error",
                        text:error.message,
                        type:"error"
                    })
                })
            }
            else
            {
                self.isSpinner=false;
                self.$notify({
                    title:"ERROR",
                    text:"Password did not match.",
                    type:"error"
                })
            }
        },
    // FORM RESET(Shraddha)    
        resetForm()
        {
            this.newpassword=null
            this.confirmPassword=null

            this.$refs.log.reset()
        },
    }
}
</script>