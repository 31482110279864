<template>
   
        <div class="login-wrapper uploadphoto" :class="{loaderBackground : isSpinner}">
          <!-- <div v-if="isSpinner" style="position: sticky;top: 50%; z-index:20;">
            <sync-loader :loading="true" color="#ffab3f" style="text-align: center"></sync-loader>
          </div> -->
          <div class="loader_div" v-if="isSpinner" >
            <!-- <sync-loader :loading="true" color="#77bef6"></sync-loader> -->
            <img src="@/assets/images/Orange_GIF.gif" class="lodar_gif">
          </div>
            <div class="logo">
                <img src="@/assets/images/logo.png" class="logo-login">
            </div>
            <form class="login-form verify-from c_form" @submit.prevent="uploadProfile()">

                <div class="form-title text-center">
                    <h2>Upload a Photo</h2>
                    <img src="@/assets/images/afterimg.png">
                     <p class="text-center">So your friends know who they're talking to. Please allow Dooey access so you can upload pictures and video.</p>
                </div>
               
                 <div class="photoupload-wrapper">
                    <img v-if="preview" :src="preview" class="img-fluid" />
                    <img v-else src="@/assets/images/nouser.png">
                    <div class="choosephoto_wrapper_afterlogin">
                      <label for="my-filenew">Choose Photo</label>
                      <input type="file" accept="image/*" @change="previewImage"  class="" id="my-filenew">
                    </div>
                  </div>

                   <a class="link" href="" @click.prevent="redirectTologin()">Do this later</a>
                <div class="btn-wrapper">
                    <button class="btn btn-yellow" type="submit" v-if="!isSpinner">Continue</button>
                    <button class="btn btn-yellow" v-if="isSpinner">Uploading</button>
                </div>
               
            </form>
            <FrontFooter/>
        </div>
        
</template>

<script>

import FrontFooter from '@/components/forntfooter.vue'
import firebase from "./firebaseInit";
import firebaseData from '../../src/utils/firebaseCollection.js';
import {getLoginUserData} from '../utils/getQueeryActiveChatList.js';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
const db = firebase.firestore()
import Vue from "vue";
export default {
  name: 'uploadphoto',
  components:{
    FrontFooter,
    firebaseData,
    SyncLoader,
    userData:{}
    
  },
  data(){
      return{
        preview: null,
        image: null,
        preview_list: [],
        image_list: [],
        docId:this.$route.params.docId,
        isSpinner:false
      }
  },
  created(){
    var self = this;
    getLoginUserData(firebaseData.firebasecollection.USER,(res) => {
      self.userData = res;
    })
  },
   methods: {
  // FOR PRIVIEW IMAGE
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while(count --) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          }
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index ++;
        }
      }
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    // REDIRECT TO LOGIN IF NOT WANT TO UPLOAD PROFILE(Shraddha)
    redirectTologin(){
      debugger // eslint-disable-line
      var self=this;
      self.$axios.post(self.$WPbaseurl+'wp-json/user/profile-picture',{wp_user_id:self.userData.wp_id,profile_url:Vue.prototype.$defaultProfile})
      .then((res)=>{
      })
      self.$router.push({'name':'chat'});
    },
    //UPLOAD CUSTOMER PHOTO IN LOCALSTORAGE(Shraddha)
    uploadProfile(){
      // debugger // eslint-disable-line
      var self=this;
      // self.getData = {}
      const storageRef = firebase.storage().ref();
      const db = firebase.firestore()
      self.isSpinner = true
      if(self.image != null){
        if(self.image.size < 5 * 1024 * 1024){
          var ImageNameSplit = self.image.name.split('.')
          var str = (
              Math.random().toString(36).substring(2, 8) +
              Math.random().toString(36).substring(2, 8)
          ).toUpperCase();
          var ImageName="";
          if(ImageNameSplit.length>2){
              for (let index = 0; index < ImageNameSplit.length-1; index++) {
                  ImageName += `${ImageNameSplit[index]}`
              }
              ImageName+=`_${str}.${ImageNameSplit[ImageNameSplit.length-1]}`
          }
          else{
              ImageName=`${ImageNameSplit[0]}_${str}.${ImageNameSplit[1]}`
          }
          var uploadTask = storageRef.child(firebaseData.firebasecollection.PROFILEIMAGES+ImageName).put(self.image)
          uploadTask.on('stateChanged',
          (snapshot)=>{
            // //console.log('snapshot',snapshot)
              var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
              //console.log(progress)
          },
          (error)=>{
              self.$notify({
                  title:"Error",
                  text:error.message,
                  type:"error"
              })
              self.isSpinner=false;
          },
          ()=>{
            uploadTask.snapshot.ref.getDownloadURL()
            .then(URL=>{
              self.image=URL
              db.collection(firebaseData.firebasecollection.USER).doc(self.docId).update({
                profileUrl:URL,
              }) 
              .then(()=>{
                 self.$axios.post(self.$WPbaseurl+'wp-json/user/profile-picture',{wp_user_id:self.userData.wp_id,profile_url:URL})
                  .then((res)=>{
                    console.log(res)
                  })
                self.$router.push({'name':'chat'});
                self.isSpinner=false
              })
              .catch(error=>{
                console.log(error)
                self.isSpinner=false
              })
            })
          })
        }else{
          self.$notify({
              title:"Error",
              text:'You can not upload file more than 5mb',
              type:"error"
          })
          self.isSpinner=false
        }
      }
      else
      {
        self.$notify({
          title:"Error",
          text:'Please select an image to continue.',
          type:"error"
        })
        self.isSpinner=false
      }
    }
  }
}
</script>