<!-- Shraddha Chaniyara, 22/06/2021 added validation and update new password in db. -->

<template>
    <div class="resetpassword-wrapper login-wrapper" v-if="showPage">
        <div class="logo">
            <router-link to="/"><img src="@/assets/images/logo.png" class="logo-login"></router-link>
        </div>
        <ValidationObserver ref="log" v-slot="{ handleSubmit }" @reset.prevent="resetForm">
        <form v-if="linkExpired" style="text-align: center;" class="login-form c_form" @submit.prevent="handleSubmit(resetPassword)">
            
            <h2 class="center">Link Expired.</h2>
            <img src="@/assets/images/afterimg.png">
            <h5 style="margin-top:30px">Your request to reset your password has expired or the link has already been used.</h5>
            <div class="btn-wrapper" style="text-align: center;">
                <router-link to="/"><button style="margin-right: 20px;" class="btn btn-yellow">Go to Login page</button></router-link>
                <router-link to="/forgotpassword"><button class="btn btn-yellow">Forgot Password</button></router-link>
            </div>
        </form>
        <form v-if="!linkExpired" class="login-form c_form" @submit.prevent="handleSubmit(resetPassword)">
             <div class="loader_div" v-if="isSpinner">
                <!-- <sync-loader :loading="true" color="#77bef6"></sync-loader> -->
                <img src="@/assets/images/Orange_GIF.gif" class="lodar_gif">
            </div>
            <div class="form-title text-center">
                <h2>Reset Password</h2>
                <img src="@/assets/images/afterimg.png">
            </div>
                <ValidationProvider rules="required" name="newpassword" v-slot="{ errors }">  
            <div class="form-group mb-5">
                <!-- <label>New Password *</label> -->
                <input :type="passwordType" maxlength="25" class="form-control" placeholder="Enter new password*" v-model="newpassword" autocomplete="off" @keyup="validatePassword($event)">
                <b-button class="btn-tooltip btn-tooltip-resetpass"  @click="showtooltip()"><img src="@/assets/images/view_detail-orange.png" title='' alt="signin" style="cursor:pointer!important"></b-button>
                <img v-if="passwordType=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye password_eye_first ">
                <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye password_eye_first">
                <div v-if="showtooitip" class="showtooitip">Password must contains at least 8 characters, including one upper/lowercase, numbers and one special character(@#$%).</div>
                <span class="InvalidInput">{{ errors[0] }}</span>
                <span class="InvalidInput password_validation_message" v-if="passwordErrorMessage!= '' ">{{passwordErrorMessage}}</span>

            </div>
            </ValidationProvider>
            <ValidationProvider rules="required|confirmed:newpassword" name="confirmPassword" v-slot="{ errors }">                                
            <div class="form-group">
                <!-- <label>confirm new Password *</label> -->
                <input :type="passwordTypes" maxlength="25" class="form-control" placeholder="Enter confirm new password*" v-model="confirmPassword">
                <img v-if="passwordTypes=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPasswords();" id="togglePassword" class="password_eye">
                <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPasswords();" id="togglePassword" class="password_eye ">
                <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                <span class="InvalidInput">{{ errors[0]=='The confirmPassword field confirmation does not match'?'The password does not match':errors[0] }}</span>

            </div>
                </ValidationProvider>
            <div class="btn-wrapper">
                    <!-- <b-button type="button" variant="primary" class="btn btn-save" disabled v-if="isSpinner">
                    Loading....
                </b-button> -->
                <button class="btn btn-yellow" >Submit</button>
            </div>
        </form>
        </ValidationObserver>
        <FrontFooter/>
    </div>
</template>

<script>

import FrontFooter from '@/components/forntfooter.vue'
import firebase from './firebaseInit'
import firebaseData from '../../src/utils/firebaseCollection.js';
const db = firebase.firestore()

export default {
 name:"resetPassword",
    components:{
        FrontFooter,
        firebaseData
    },
    data(){
        return{
            isSpinner: false,
            newpassword:null,
            confirmPassword:null,
            docId:this.$route.params.docId,
            resetId:this.$route.params.resetId,
            showPage:false,
            linkExpired:true,
            passwordType:"password",
            passwordTypes:"password",
            showtooitip:false,
            passwordErrorMessage : ''
        }
    },
    created(){
        // debugger // eslint-disable-line

        const db = firebase.firestore()
        db.collection(firebaseData.firebasecollection.USER)
        .doc(this.docId)
        .get()
        .then(doc=>{
            if(doc.exists){
                console.log('====',doc.data().resetLinkUsed, doc.data().resetLinkId == this.resetId)
                if(doc.data().resetLinkUsed)
                {
                    this.linkExpired = true
                    this.showPage=true
                }
                else if(!doc.data().resetLinkUsed && doc.data().resetLinkId != this.resetId)
                {
                    this.linkExpired = true
                    this.showPage=true
                }
                else if(!doc.data().resetLinkUsed && doc.data().resetLinkId == this.resetId)
                {
                    this.showPage=true
                    this.linkExpired=false
                }
            }
            else
            {
                this.showPage=true
                // this.$router.push({name:"forgotpassword"})
            }
            console.log(doc.data(),this.linkExpired,this.showPage)
        })
        .catch(error=>{
            console.log(error)
        })
    },
    methods:{
    //SET NEW PASSWORD(Shraddha)    
        resetPassword(){
             debugger // eslint-disable-line
            var self = this
            self.showtooitip = false;
            self.isSpinner=true;

            if(self.newpassword==self.confirmPassword && self.passwordErrorMessage == '' )
            {
                self.$axios.post(firebaseData.firebasecollection.APIDOMAIN +'/resetPassword',{docId:self.docId,newpassword:self.newpassword})
                .then(data=>{
                    //console.log(data)
                    db
                        .collection(firebaseData.firebasecollection.USER)
                        .doc(self.docId)
                        .update({
                            resetLinkUsed:true,
                            resetLinkId:'',
                            emailVerified:true,
                            verifyLinkId:''
                        })
                        .then(()=>{
                            self.isSpinner=false;
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:"Password updated successfully.",
                                type:"success"
                            })
                            self.$router.push({name:'login'})
                        })
                        .catch(error=>{
                            self.isSpinner=false;
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Error",
                                text:error.message,
                                type:"error"
                            })
                        })
                })
                .catch(error=>{
                    self.isSpinner=false;
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title:"Error",
                        text:error.message,
                        type:"error"
                    })
                })
            }
            else
            {
                self.isSpinner=false;
                self.$root.$emit("audioplay");
                self.$notify({
                    title:"ERROR",
                    text:"Password did not match.",
                    type:"error"
                })
            }
        },
    // FORM RESET(Shraddha)    
        resetForm()
        {
            this.newpassword=null
            this.confirmPassword=null

            this.$refs.log.reset()
        },
    // PASSWORD VALIDATIONS
      validatePassword(evt){
    // //debugger // eslint-disable-line
    var self=this;
    //console.log(evt.target.value)
    var regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if(regex.test(evt.target.value)){
        // //console.log('dfd',regex.test(evt.target.value))
        self.passwordErrorMessage = ''
      }else{
        if(evt.target.value != ''){
          self.passwordErrorMessage = 'Password must contains at least 8 characters, including one upper/lowercase, numbers and one special character(@#$%).';
          self.$refs.log.reset();
        }else{
          self.passwordErrorMessage = ''
        }
      }
        },

        showtooltip(){
            if(this.showtooitip == true){
                this.showtooitip = false
            }else{
                this.showtooitip = true
            }
            setTimeout(() => {
                this.showtooitip = false
            }, 3 * 1000);
        },
        // TOGGLE PASSWORD (Shraddha)        
        showPassword(){
            if(this.passwordType=='password')
            {
                this.passwordType='text'
            }
            else
            {
                this.passwordType='password'
            }
        },
        showPasswords(){
            if(this.passwordTypes=='password')
                {
                    this.passwordTypes='text'
                }
                else
                {
                    this.passwordTypes='password'
                }
        }     
    }
}
</script>
<style scoped>
.showtooitip {
    position: absolute;
    top: 34px;
    padding: 10px;
    z-index: 99;
    border-radius: 10px;
    right: -25px;
    background-color: #fff;
    color:red;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 30%);
}
/* .showtooitip:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    top: -10px;
    left: 97%;
} */
@media(max-Width:575px){
    form.c_form span.InvalidInput.password_validation_message {
    position: relative;
    bottom: 6px;
}
.resetpassword-wrapper .btn-wrapper {
    margin-top: 30px;
}
.form-group.mb-5 {
    margin-bottom: 0px!important;
}

form.c_form span.InvalidInput {
    bottom: -27px;
}
}
</style>