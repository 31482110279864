import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import firebase from 'firebase'


Vue.use(BootstrapVue)

Vue.config.productionTip = false

import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);

import {ValidationObserver} from "vee-validate";
import {ValidationProvider} from 'vee-validate/dist/vee-validate.full.esm';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import Notifications from "vue-notification";
Vue.use(Notifications)

import axios from "axios";
Vue.prototype.$defaultGroupProfile = `https://firebasestorage.googleapis.com/v0/b/dooey-c34e1.appspot.com/o/default-organization-logo-6aecc771.gif?alt=media&token=dfd2c189-5d75-43ff-9bb1-c97eab50fd97`;
Vue.prototype.$defaultProfile = `https://firebasestorage.googleapis.com/v0/b/dooey-c34e1.appspot.com/o/defaultProfile2.png?alt=media&token=8b3bcbd5-e58b-4e32-b6cd-19fa18ae4857`;
Vue.prototype.$defaultAudio = `https://firebasestorage.googleapis.com/v0/b/dooey-c34e1.appspot.com/o/4797.mp3?alt=media&token=b3a8d218-161c-47c9-98fc-fdd988d14691`;
// Vue.use(axios);
Vue.prototype.$axios = axios
// Vue.prototype.$apiurl = 'http://localhost:4000/'
// Vue.prototype.$apiurl = 'https://apidooey.aliansoftware.net/'
Vue.prototype.$apiurl = 'https://api.dooey.io/'
// Vue.prototype.$apiurl = 'https://dooey-devapi.aliansoftware.net/'


Vue.prototype.$companyName = '';
Vue.prototype.$email = 'dooey@mailinator.com';  

Vue.prototype.$adminDetails = ''
// Vue.prototype.$WPbaseurl = 'https://dooeywp.aliansoftware.net/';
Vue.prototype.$WPbaseurl = 'https://dooey.io/';
// Vue.prototype.$WPbaseurl = 'https://dooeywp.aliansoftware.net/testing/';
// Vue.prototype.$WPbaseurl = 'https://dooeywp.aliansoftware.net/livesite/';

//JYOTI YADAV [13-01-22-02-24] START GET OPERATING SYSTEM TYPE
    var userAgent = window.navigator.userAgent;
    let platform = window.navigator.platform;
    let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    let iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

   if (macosPlatforms.indexOf(platform) !== -1) {
     os = 'Mac OS';
   } else if (iosPlatforms.indexOf(platform) !== -1) {
     os = 'iOS';
   } else if (windowsPlatforms.indexOf(platform) !== -1) {
     os = 'Windows';
   } else if (/Android/.test(userAgent)) {
     os = 'Android';
   } else if (!os && /Linux/.test(platform)) {
     os = 'Linux';
   }

Vue.prototype.$currentOs = os;
//END OF OS PROTOTYPE

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/style.css'
import './assets/css/Admin_style.css'
import './assets/css/responsive.css'


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')