<template>
    <div class="front-footer">
        <p>© Dooey Ltd. {{footerText}}</p>
    </div>
</template>
<script>
export default ({
   data(){
       return{
           footerText : new Date().getFullYear()
       }
   },
})
</script>

