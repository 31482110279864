/**
 * 
 * This file is contains all of the firestore collection name which is used in our 
 * project. If your have required to create any new collection then you must be decalred here
 * and used as globally.
 * 
 */

const firebasecollection = {
    //api domain
    // APIDOMAIN : "https://apidooey.aliansoftware.net",
    APIDOMAIN :  "https://api.dooey.io",
    // APIDOMAIN : "http://localhost:4000",
    // APIDOMAIN : "https://dooey-devapi.aliansoftware.net",
    USER:'users',
        // storage name
    PROFILEIMAGES : "ProfileImage/",
    //database collectiongut 
    PRIVACYPOLICY : "privacypolicy",
    TERMSANDCONDITIONS : "termsandcondition",
    PACKAGEMANAGMENT : "packagemanagment",
    PACKAGEMANAGMENTDATA: "packageManagementData",
    SOCIALREQUEST:"socialRequests",
    SOCIALMESSAGE:"socialMessages",
    PLAN:'plan',
    CUSTOMERSUPPORT : "customerSupport",
    SOCIALPLAN:'socialPlan',
    BUSINESSPLAN:'businessPlan',
    SOCIALINVITE:'socialInvite',
    BUSINESSREQUEST :'businessRequests',
    BUSINESSMESSAGE:'businessMessages',
    BUSINESSINVITE:'businessInvite',
    COMPANY:'companies',
    SOCIALCONTACT:'socialContact',
    NOTIFICATIONS : 'notifications',
    PLANMANAGEMENT:'planManagement',
    REVENUEMANAGEMENT : 'revenueManagement',
    OTPBLOCKUSER : 'otpBlockUser',
    SELFIENOTIFICATION:'SelfieNotification',
    BUSINESSSELFIENOTIFICATION:'BusinessSelfieNotification',
    FEEDBACK:'feedback'
   
 }
module.exports = {
    firebasecollection,
};