<!-- Shraddha Chaniyara, 22/06/2021 added validation and send verification link to email for reset password -->

<template>
        <div class="login-wrapper forgot-wrapper">
            <div class="logo">
                <img src="@/assets/images/logo.png" class="logo-login">
            </div>
            <ValidationObserver ref="log" v-slot="{ handleSubmit }">
            <form class="login-form c_form" @submit.prevent="handleSubmit(submitEmail)">
                <div class="form-title text-center">
                    <h2>Forgot Password</h2>
                    <img src="@/assets/images/afterimg.png">
                     <p>Enter your email address and we'll send you a link to reset your password.</p>
                </div>
               
                <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                <div class="form-group mb-3">
                    <!-- <label>Email *</label> -->
                    <input type="text" class="form-control" placeholder="Enter email*" name="email" v-model="email" @blur="IsfirstLetterCapital()">
                     <span class="InvalidInput">{{ errors[0] }}</span>
                </div>
                </ValidationProvider>
                <div class="btn-wrapper">
                    <!-- <b-button type="button" variant="primary" class="btn btn-primary" disabled v-if="isSpinner">
                        Loading....
                    </b-button> -->
                    <button class="btn btn-yellow" v-bind:class="{disabled: isSpinner == true}">Submit</button>
                </div>
                <p class="login-bottom-text">
                    Click here to <router-link to="/admin"><span style="color: #77bef6;">Login</span></router-link>
                </p>
               
            </form>
            </ValidationObserver>
            <FrontFooter/>
        </div>
        
</template>
<script>
import firebase from '../firebaseInit'
import FrontFooter from "@/components/forntfooter.vue";
import firebaseData from '../../utils/firebaseCollection.js';


export default {
    name:'adminforgotpassword',
    components: {
        FrontFooter,
        firebaseData
    },
    data()
    {
        return{
            isSpinner: false,
            email:null,
        }
    },
    methods:{
// SEND EMAIL IF ADMIN FORGOTPASSWORD(Shraddha)        
        submitEmail(){
            // //debugger // eslint-disable-line
            var self = this
            self.isSpinner=true;
            var noUserFound = true
            const db = firebase.firestore();
            var docId=null
            var firstName = ''

            db.collection(firebaseData.firebasecollection.USER)
            .get()
            .then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    if(doc.data().email==self.email && doc.data().isAdmin)
                    {
                        firstName = doc.data().firstName
                        noUserFound = false
                        docId = doc.id
                    }
                })
                if(noUserFound)
                {
                    self.$notify({
                        title:"Error",
                        text:'No such user email found.',
                        type:"error"
                    })
                    self.isSpinner=false;
                }
                else
                {
                    // var actionCodeSettings = {
                    //     url: window.location.origin + '/admin',
                    // };
                    // firebase
                    // .auth()
                    // .sendPasswordResetEmail(self.email, actionCodeSettings)
                    self.$axios.post(firebaseData.firebasecollection.APIDOMAIN+'/forgotPassword',{email:self.email,docId:docId,firstName:firstName})
                    .then((result)=>{
                        console.log(result)
                        if(result.data.error=="")
                        {
                            self.$notify({
                                title:"Success",
                                text:'Password reset mail is sent to your email.',
                                type:'success'
                            })
                            self.isSpinner=false;
                            self.$router.push({'name':'adminsignin'});
                        }
                        else
                        {
                            self.$notify({
                                title:"Error",
                                text:result.data.error,
                                type:'error'
                            })
                            self.isSpinner=false;
                        }
                    })
                    .catch(error=>{
                        self.$notify({
                            title:"Error",
                            text:error.message,
                            type:"error"
                        })
                        self.isSpinner=false;
                    })
                }
            })
            .catch(error=>{
                self.isSpinner=false;
                self.$notify({
                    title:"Error",
                    text:error.message,
                    type:"error"
                })
            })
        },
// EMAIL IS CONVERT IN LOWERCASE
        IsfirstLetterCapital(){
            //email small case
            if(this.email!='')
            {
                this.email= this.email.toLowerCase()
            }
        },
    },
}
</script>