import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from '../views/firebaseInit'
import firebaseapp from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

//Customer 

import Signin from '../views/login.vue'
import businessUserLogin from '../views/businessUserLogin.vue'
import Signup from '../views/createaccount.vue'
import inviteNewUser from '../views/inviteNewUserRagistration.vue'
import Home from '../views/Home.vue'
import Forgotpassword from '../views/forgotpassword.vue'
import ResetPassword from "../views/resetpassword.vue";
import VerificationOtp from "../views/verifyAccountNew.vue";
import UploadPhoto from "../views/uploadphoto.vue";
import SocialInvite from "../views/socialinvite.vue"

//Admin
import Admin_signin from '../views/Admin_Panel/Admin_Signin.vue'
import Admin_forgotpassword from '../views/Admin_Panel/Admin_forgotPassword.vue'
import Admin_ResetPassword from "../views/Admin_Panel/Admin_resetPassword.vue";
import Admin_home from '../views/Admin_Panel/Admin_home.vue'




const routes = [
  //customer routes
  {
    path: '/',
    name: 'login',
    component: Signin,
    meta:{title:'Login'}
  },
  {
    path: '/login/:id/:comapnyid/:rid/:planid',
    name: 'businessUserLogin',
    component: businessUserLogin,
    meta:{title:'Business Login'}
  },
  
  {
    path: '/invitePlateform',
    name: 'invitePlateform',
    meta: {
      title:'Dooey'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '../views/invitePlateform.vue')
  },
  {
    path: '/pendingVerify/:id',
    name: 'verifyAccount',
    meta: {
      title:'Pending Verify'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '../views/verifyPage.vue')
  },
  {
    path:'/createaccount',
    name:'createaccount',
    component:Signup,
    meta:{title:'Create Account'}
  },
  {
    path:'/createaccount/:id',
    name:'createaccount',
    component:SocialInvite,
    meta:{title:'Invite Social User'}
  },
  {
    path:'/createaccount/:id/:comapnyid/:planid/:randomecode',
    name:'inviteNewUser',
    component:inviteNewUser,
    meta:{title:'Invite New User'}
  },
 
  {
    path:'/forgotpassword',
    name:'forgotpassword',
    component:Forgotpassword,
    meta:{title:'Forgot Password'}
  },
  {
    path:'/resetpassword/:docId/:resetId',
    name:'customerresetpassword',
    component:ResetPassword,
    meta:{
     
      title:'Reset Password'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:{title:'Home'}
  },

  {
    path:'/verifyaccount/:docId/:resetId',
    name:'verifyaccount',
    component:VerificationOtp,
    meta:{title:'Verify Account'}
  },

  {
    path:'/upload-photo/:docId',
    name:'uploadphoto',
    component:UploadPhoto,
    meta:{
      requiresAuth: true,
      title:'Upload Photo'
    }
  },

  //Admin route
  {
    path:'/admin',
    name:'adminsignin',
    component:Admin_signin,
    meta:{title:'Admin Signin'}
  },
  {
    path:'/admin-forgotpassword',
    name:'adminforgotpassword',
    component:Admin_forgotpassword,
    meta:{
      title:'Admin Forgot Password'
    }
  },
  {
    path:'/admin-resetpassword/:docId',
    name:'adminresetpassword',
    component:Admin_ResetPassword,
    meta:{

      title:'Admin Reset Password'
    }
  },
  {
    path:'/admin-home',
    name:'adminhome',
    component:Admin_home,
    meta:{
      requiresAuth: true,
      title:'Admin Home'
    }
  },
  {
    path: '/chat',
    name: 'chat',
    meta: {
      requiresAuth: true,
      // title:localStorage.getItem('IsLoginUser') == 'Admin'?'Admin Dashboard':'Social Chat'
      title:'Social Chat'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '../views/chat.vue')
  },
  {
    path: '/admin-panel',
    name: 'admin-panel',
    meta: {
      requiresAuth: true,
      title:'Admin Dashboard'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '../views/chat.vue')
  },
  
  {
    path: '/myprofile/:id',
    name: 'myprofile',
    component: () =>
        import ( /* webpackChunkName: "about" */ '../views/myprofile.vue'),
    meta:{
      requiresAuth: true,
      title:'My Profile'
    }
  },
  {
    path: '/userprofile/:id',
    name: 'userprofile',
    meta:{
      requiresAuth: true,
      title:'User Profile'
    },
    component: () =>
        import ( /* webpackChunkName: "about" */ '../views/userprofile.vue')
  },
  // {
  //   path: '/userprofile/:id',
  //   name: 'Businessuserprofile',
  //   meta:{
  //     requiresAuth: true,
  //     title:'Business User Profile'
  //   },
  //   component: () =>
  //       import ( /* webpackChunkName: "about" */ '../views/businessViews/businessUserProfile.vue')
  // },
  {
    path: '/generalsettings/:activetab',
    name: 'generalsettings',
    meta:{
      requiresAuth: true,
      title:'Account Settings'
    },
    component: () =>
        import ( /* webpackChunkName: "about" */ '../views/generalsettings.vue')
  },
  {
    path: '/generalsettings',
    name: 'generalsettings',
    meta:{
      requiresAuth: true,
      title:'Account Settings'
    },
    component: () =>
        import ( /* webpackChunkName: "about" */ '../views/generalsettings.vue')
  },
  {
    path: '/Accountsettings',
    name: 'Accountsettings',
    meta:{
      requiresAuth: true,
      title:'General Settings'
    },
    component: () =>
        import ( /* webpackChunkName: "about" */ '../views/Accountsettings.vue')
  },
  // 
  {
    path: '/feedback',
    name: 'feddback',
    meta:{
      requiresAuth: true,
      title:'Feedback'
    },
    component: () =>
        import ( /* webpackChunkName: "about" */ '../views/feedback.vue')
  },
  {
    path: '/ContentManagement',
    name: 'ContentManagement',
    meta:{
      requiresAuth: true,
      title:'Content Management'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "about" */ '../views/Admin_Panel/ContentManagement.vue')
  },
  {
  path: '/ContentManagementDetail/:docId',
  name: 'ContentManagementDetail',
  meta:{
    requiresAuth: true,
    title:'Content Management'
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
      import ( /* webpackChunkName: "about" */ '../views/Admin_Panel/ContentManagementDetail.vue')
  },
  {
    path: '/sidebar',
    name: 'Sidebar',
    meta:{
      requiresAuth: true,
      title:'Sidebar'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '../components/sidebar.vue')
  },

  //BUSINESS ROUTES(Shraddha)
  {
    path: '/businesschat',
    name: 'businesschat',
    meta: {
      requiresAuth: true,
      title:'Business Chat'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '@/views/businessViews/businessChat.vue')
  },
  {
    path: '/businessgeneralsettings',
    name: 'businessgeneralsettings',
    meta: {
      requiresAuth: true,
      title:'Business Account Settings'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '@/views/businessGeneralSetting/businessGeneralSetting.vue')
  },
  {
    path: '/businessgeneralsettings/:activetab',
    name: 'businessgeneralsettings',
    meta: {
      requiresAuth: true,
      title:'Business Account Settings'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '@/views/businessGeneralSetting/businessGeneralSetting.vue')
  },
  {
    path: '/businessmyprofile/:id',
    name: 'businessmyprofile',
    meta: {
      requiresAuth: true,
      title:'Business My Profile'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '@/views/businessViews/businessMyProfile.vue')
  },
  {
    path: '/businessuserprofile/:id',
    name: 'businessuserprofile',
    meta: {
      requiresAuth: true,
      title:'Business User Profile'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '@/views/businessViews/businessUserProfile.vue')
  },
  {
    path: '*',
    name: 'errorPage',
    meta: {
      requiresAuth: true,
      title:'Error'
    },
    component: () =>  
        import ( /* webpackChunkName: "about" */ '@/views/error404.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  Vue.nextTick(() => {
    document.title = 'Dooey | '+to.meta.title || 'Dooey';
  });

  if (localStorage.getItem('IsLoginUser') == 'Admin') {
    Vue.prototype.$isLogginedIn = true;
  }else if (localStorage.getItem('IsLoginUser') == 'User'){
    Vue.prototype.$isLogginedIn = true;
  }else {
    Vue.prototype.$isLogginedIn = false;
  }
  
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const userAuth = to.matched.some(record => record.meta.userAuth);
  //console.log(requiresAuth);
    if (requiresAuth && !await firebaseapp.getCurrentUser()) {
        next('/');
    }
    else if (to.matched.some(record => record.meta.loginAuth) && await firebaseapp.getCurrentUser() && !requiresAuth) {
        next('/chat');
    }
    else if(userAuth && !await firebase.getCurrentUser()){
        next('/chat');
    }
    else {
        next();
    }
})


export default router
