<!-- Shraddha Chaniyara, 21/06/2021 added validation and bind with firebase and also make code for stayloggin-->

<template>
  <div class="login-wrapper">
            <div class="logo">
                <img src="@/assets/images/logo.png" class="logo-login">
            </div>
            <ValidationObserver ref="log" v-slot="{ handleSubmit }">
            <form class="login-form c_form" @submit.prevent="handleSubmit(loginData)">
                <div class="form-title text-center">
                    <h2>Login</h2>
                    <img src="@/assets/images/afterimg.png">
                </div>
                <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                <div class="form-group mb-5">
                    <!-- <label>Email *</label> -->
                    <input style="color: black !important" type="text" class="form-control" v-model="email" placeholder="Enter email*" @blur="IsfirstLetterCapital()">
                    <span class="InvalidInput">{{ errors[0] }}</span>
                </div>
                </ValidationProvider>
                <ValidationProvider rules="required" name="password" v-slot="{ errors }">
                <div class="form-group login-password">
                    <!-- <label>Password *</label> -->
                    <input style="color: black !important" :type="passwordType" maxlength="25" class="form-control" v-model="password" placeholder="Enter password*" id="password">
                    <img v-if="passwordType=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye">
                    <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye">
                    <span class="InvalidInput">{{ errors[0] }}</span>

                </div>
                </ValidationProvider>
                <div class="forget-link">
                    <div>
                       <label class="checkbox-c">Remember me
                            <input type="checkbox" checked="checked" v-model="stayLoggedIn" @change="stayLogged(email)">
                            <span class="checkmark"></span>
                        </label>
                     </div>
                     <router-link to="/forgotpassword" class="link"> Forgot Password?</router-link>
                </div>
                <div class="btn-wrapper">
                    <button class="btn btn-yellow" v-bind:class="{disabled: isSpinner == true}" >Login</button>
                </div>
                <div style="text-align: center;position: relative;top: 20px;">
                    <span style="color:#42ba96; margin-right:auto;" v-if="resendTimer!=0">You can resend verification mail after 0:{{resendTimer &lt; 10 ?'0'+resendTimer:resendTimer}} min</span>
                </div>
                <p class="login-bottom-text">New to dooey? Click here to  <router-link to="/createaccount"><span style="color: #77bef6;">create an account</span> </router-link></p>
                <span style="color:red; padding-left:118px; " v-if="errorMessage != '' ">{{errorMessage}}</span>
            </form>
            </ValidationObserver>
            <FrontFooter />
        </div>
        
</template>

<script>
import firebase from "./firebaseInit"
import FrontFooter from "@/components/forntfooter.vue";
import firebaseData from '../utils/firebaseCollection.js';
import firebaseTimestamp from "firebase";
const db = firebase.firestore()
import Vue from "vue";

import { mapActions } from 'vuex'

export default {
    name:'Signin',
    components: {
        FrontFooter,
        firebaseData
    },
    data(){
        return{
            isSpinner:false,
            email:'',
            password:'',
            stayLoggedIn:false,
            isImage:'',
            resendTimer:0,
            signInClicked:false,
            tokenArray : [],
            passwordType:"password",
            errorMessage:'',
            userData : []

        }
    },

    created()
    {
        //check if remember me is checked
        if(localStorage.getItem("rememberCustomer")!=null)
        {
            this.stayLoggedIn=true
            var remember = JSON.parse(localStorage.getItem("rememberCustomer"))
            this.email = remember.email
        }
        else
        {
            this.stayLoggedIn=false
        }
        localStorage.removeItem('CompanyDetail');
        localStorage.removeItem('SelectedCompany')
        
        db.collection(firebaseData.firebasecollection.USER).get().then(doc => {
            doc.forEach(docdata => {
                this.userData.push(docdata.data())
                if(this.userData.isAdmin){
                    Vue.prototype.$adminDetails = doc.data()
                }
            })
            
        })        
       
    },
    
    methods:{
        
        ...mapActions({setUserStoreData:"setUserData"}),
        // LOGIN CUSTOMER(Shraddha)
        loginData(){
            // debugger // eslint-disable-line
            var self = this
            let isInArray = self.userData.some(function(item) {
                return item.email == self.email && !item.isDeleted
            });
            var index = self.userData.findIndex(product => {
                return product.email == self.email && !product.isDeleted
            })
            // console.log('isInArray',isInArray,index)
            if(isInArray){
                self.tokenArray = []
                // console.log("navigator.userAgent",navigator.userAgent);
                let userAgent = navigator.userAgent;
                let browserName;
                
                if(userAgent.match(/chrome|chromium|crios/i)){
                    browserName = "chrome";
                }else if(userAgent.match(/firefox|fxios/i)){
                    browserName = "firefox";
                }  else if(userAgent.match(/safari/i)){
                    browserName = "safari";
                } else if(userAgent.match(/edg/i)){
                    browserName = "edge";
                }else{
                    browserName="No browser detection";
                }
                // console.log("browserName,,,,,,,,,,,,,,",browserName);
                var userpasswordFalse=false
                self.isSpinner = true;
                self.isImage = '';
                firebase.auth().signInWithEmailAndPassword(self.email,self.password)
                .then(userData=>{
                    db.collection(firebaseData.firebasecollection.USER)
                    .doc(userData.user.uid)
                    .get()
                    .then(doc=>{
                    //console.log(userData)
                            if(!userData.user.emailVerified)
                            {
                                self.$axios.post(firebaseData.firebasecollection.APIDOMAIN + '/userVerificationEmail',{email:self.email,docId:doc.id})
                                .then((result)=>{
                                    if(result.data.status==200)
                                    {
                                        firebase.auth().signOut()
                                        .then(()=>{
                                            // self.errorMessage = 'Please verify your Email before login.';
                                            self.$root.$emit("audioplay");
                                            self.$notify({
                                                title:"Error",
                                                text:'Please verify your details via email sent.',
                                                type:"error",
                                                duration: 10000
                                            })
                                            self.isSpinner = false;
                                        })
                                    }
                                    else
                                    {
                                        self.$root.$emit("audioplay");
                                        self.$notify({
                                            title:"Error",
                                            text:result.data.message,
                                            type:"error",
                                            duration: 10000
                                        })
                                        self.isSpinner = false;
                                    }
                                })
                                .catch(error=>{
                                    self.isSpinner = false;
                                    self.$root.$emit("audioplay");
                                    self.$notify({
                                        title:"Error",
                                        text:error.message,
                                        type:"error",
                                        duration: 10000
                                    })
                                    self.signInClicked=true
                                    if(self.resendTimer==0)
                                    {
                                        self.resentLinkTimer()
                                    }
                                
                                })
                            }
                            else
                            {
                                self.errorMessage = ''
                                if(doc.data().firstTimeLogin)
                                {
                                    self.setUserStoreData(userData.user.uid)
                                    db
                                        .collection(firebaseData.firebasecollection.USER)
                                        .doc(userData.user.uid)
                                        .update({
                                            firstTimeLogin:false,
                                            userStatus:'Active'
                                        })
                                        .then(()=>{
                                            userpasswordFalse=true
                                            self.isImage = doc.data().profileUrl
                                            localStorage.setItem("IsLoginUser",'User');
                                            localStorage.setItem("customerDocKey",userData.user.uid)
                                            localStorage.setItem("rememberUser","")
                                            localStorage.setItem("rememberUser",JSON.stringify({email:self.email}))
                                            if(self.userData[index].profileUrl == Vue.prototype.$defaultProfile){
                                                self.$router.push({name:'uploadphoto',params: { docId: userData.user.uid }, })
                                            }else{
                                                self.$router.push({'name':'chat'});
                                            }
                                            //  //debugger // eslint-disable-line
                                            if(browserName != "safari"){
                                                const messaging = firebase.messaging();
                                                messaging.requestPermission()
                                                    .then(() => {
                                                    return messaging.getToken().then((currentToken) => {
                                                        if (currentToken) {
                                                            // console.log('currentToken:', currentToken)
                                                                localStorage.setItem('authToken',currentToken);
                                                            // TODO send token to api
                                                            self.tokenArray.push({
                                                                from: "Web", token: currentToken
                                                            })
                                                            const docRef = db.collection('users').doc(userData.user.uid);
                                                            docRef.update({
                                                                LoginFCMToken:self.tokenArray
                                                            });
                                                            return currentToken;
                                                        }
                                                    })
                                                }).catch(error => {
                                                    self.isSpinner = false;
                                                    // console.log(error)
                                                })                                   
                                                messaging.onTokenRefresh(() => {
                                                    messaging.getToken().then((refreshedToken) => {
                                                    // console.log('Token refreshed:', refreshedToken);
                                                    // TODO send refreshed token to api
                                                    }).catch((err) => {
                                                    // console.log(err);
                                                    });
                                                });
                                            }
                                            self.$root.$emit("audioplay");
                                            self.$notify({                                        
                                                title:"Success",
                                                text:'User login successful.',
                                                type:"success"
                                            })
                                            self.isSpinner = false;
                                            // if(self.isImage != 'https://firebasestorage.googleapis.com/v0/b/dooeydev.appspot.com/o/ProfileImage%2F24-248253_user-profile-default-image-png-clipart-png-download.png?alt=media&token=b297bf19-fe8f-4272-84a3-315aa2b7e3a9'){
                                            //     self.$router.push({'name':'chat'});
                                            // }
                                            // else{
                                                
                                            // }
                                        })
                                        .catch(error=>{
                                            self.isSpinner = false;
                                            // console.log(error)
                                        })
                                }
                                else if(doc.data().email==self.email && !doc.data().isAdmin && doc.data().userStatus == 'Active' && !doc.data().firstTimeLogin)
                                {
                                    // debugger // eslint-disable-line
                                    self.setUserStoreData(userData.user.uid)
                                    userpasswordFalse=true
                                    self.isImage = doc.data().profileUrl
                                    localStorage.setItem("IsLoginUser",'User');
                                    localStorage.setItem("customerDocKey",userData.user.uid)
                                    localStorage.setItem("rememberUser","")
                                    localStorage.setItem("rememberUser",JSON.stringify({email:self.email}))
                                    self.$router.push({'name':'chat'});
                                    if(browserName != "safari"){
                                        const messaging = firebase.messaging();
                                        messaging.requestPermission()
                                            .then(() => {
                                            return messaging.getToken().then((currentToken) => {
                                                if (currentToken) {
                                                    // console.log('currentToken:', currentToken)
                                                        localStorage.setItem('authToken',currentToken);
                                                    // TODO send token to api
                                                    self.tokenArray.push({
                                                        from: "Web", token: currentToken
                                                    })
                                                    const docRef = db.collection('users').doc(userData.user.uid);
                                                    docRef.update({
                                                        LoginFCMToken:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                                                        from: "Web", token: currentToken 
                                                        })
                                                    });
                                                    return currentToken;
                                                }
                                            })
                                        }).catch(error => {
                                            self.isSpinner = false;
                                            // console.log(error)
                                        })
                                        messaging.onTokenRefresh(() => {
                                            messaging.getToken().then((refreshedToken) => {
                                            // console.log('Token refreshed:', refreshedToken);
                                            // TODO send refreshed token to api
                                            }).catch((err) => {
                                            // console.log(err);
                                            });
                                        });
                                    }
                                    self.$root.$emit("audioplay");
                                    self.$notify({
                                        title:"Success",
                                        text:'User login successful.',
                                        type:"success"
                                    })
                                    self.isSpinner = false;
                                        
                                    // if(self.isImage != 'https://firebasestorage.googleapis.com/v0/b/dooeydev.appspot.com/o/ProfileImage%2F24-248253_user-profile-default-image-png-clipart-png-download.png?alt=media&token=b297bf19-fe8f-4272-84a3-315aa2b7e3a9'){
                                        
                                    // }else{
                                    //     self.$router.push({name:'uploadphoto',params: { docId: userData.user.uid }, })
                                    // }
                                }

                                else if(!doc.exists || !userpasswordFalse)
                                {
                                    self.$root.$emit("audioplay");
                                    self.$notify({
                                        title:"ERROR",
                                        text:'no such user exists.',
                                        type:"error"
                                    })
                                    self.isSpinner = false;
                                    firebase.auth().signOut()
                                }
                                // //console.log('Test DocData',doc.data().email==self.email , doc.data().userRole == "Social" , doc.data().userStatus == 'Active', !doc.data().firstTimeLogin)
                            }
                    
                    })
                    .catch(error=>{
                        self.isSpinner = false;
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"ERROR",
                            text:error.message,
                            type:"error"
                        })
                        self.password = ''
                    })
                })
                .catch(error=>{

                    self.isSpinner = false;
                    // console.log('errror',error)
                    if(error.message == 'There is no user record corresponding to this identifier. The user may have been deleted.'){
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"ERROR",
                            text:'Entered email is invalid. please try again.',
                            // text:'Your account is deactivate, please contact to administrator.',
                            type:"error"
                        })
                    }else if(error.message == 'The password is invalid or the user does not have a password.'){
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"ERROR",
                            text:'Entered password is invalid. please try again',
                            type:"error"
                        })
                    }else{
                        self.$root.$emit("audioplay");
                        self.$notify({
                            title:"ERROR",
                            text:'Your account is inactive, please contact to administrator.',
                            type:"error"
                        })
                    }
                
                    firebase.auth().signOut()
                })
            }else{
                 self.$root.$emit("audioplay");
                 self.$notify({
                    title:"ERROR",
                    text:'no such user exist.',
                    type:"error"
                }) 
            }
        },

// REMEMBER ME CHECKBOX CODE IF RIGHT THEN SAVE USER IN LOCALSTORAGE OTHERWISE REMOVE IT  (Shraddha)     
        stayLogged(email){
            // //debugger // eslint-disable-line
            localStorage.setItem('StayUserLoggedIn',this.stayLoggedIn)
            if(!this.stayLoggedIn)
            {
                localStorage.removeItem('rememberCustomer')
                localStorage.removeItem("customerDocKey")
            }else{
                localStorage.setItem("rememberCustomer",JSON.stringify({email:email}))
            }
        },
// EMAIL IS CONVERT IN LOWERCASE
        IsfirstLetterCapital(){
            //email small case
            if(this.email!='')
            {
                this.email= this.email.toLowerCase()
            }
        },
        resentLinkTimer()
        {
            var self = this
            if(self.signInClicked && self.resendTimer==0)
            {
                self.resendTimer=60
                self.$root.$emit("audioplay");
                self.$notify({
                    title:"Error in verification",
                    text:'Please check your mail if verification link is sent recently else wait for 1 minutes to sent another verification mail',
                    type:"error",
                    duration: 10000
                })
                var interval = setInterval(()=>{
                    if(self.resendTimer>0)
                    {
                        self.resendTimer--
                    }
                    if(self.resendTimer==0)
                    {
                        self.signInClicked=false
                        self.resendTimer=0
                        clearInterval(interval)
                    }
                },1000)
            }
        },
// TOGGLE PASSWORD (Shraddha)        
        showPassword(){
            if(this.passwordType=='password')
            {
                this.passwordType='text'
            }
            else
            {
                this.passwordType='password'
            }
        }        
    }
}
</script>

