<!-- Shraddha Chaniyara, 21/06/2021 added validation and bind with firebase and also make code for stayloggin-->


<template>
    <div class="login-wrapper">
        <div class="logo">
            <img src="@/assets/images/logo.png" class="logo-login" :class="{loaderBackground : showLoaders}">
        </div>
        <div class="loader_div " v-if="showLoaders">
            <img src="@/assets/images/Blue_GIF.gif" class="lodar_gif">
        </div>
        <div>
        <div class="downloadButtonFromIos">
            <div>
                <a
                href="https://apps.apple.com/us/app/dooey-messenger/id1196280355"
                style="margin-top: 20px"
                v-show="osProto == 'iOS'"
                class="btn btn-yellow ios_verification_btn"
                >Download App</a
                >
            </div>
            <div>
                <a
                href="dooey://app"
                @click="gotoLoginBtn()"
                style="margin-top: 20px"
                v-show="osProto == 'iOS'"
                class="btn btn-yellow ios_verification_btn"
                >Open App</a
                >
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import firebase from "./firebaseInit"
import firebaseTimestamp from "firebase";
import FrontFooter from "@/components/forntfooter.vue";
import firebaseData from '../utils/firebaseCollection.js';
import Vue from 'vue'
const db = firebase.firestore()

export default {
    name:'businessUserLogin',
    components: {
        FrontFooter,
        firebaseData
    },
    data(){
        return{
            isSpinner:false,
            email:'',
            password:'',
            stayLoggedIn:false,
            isImage:'',
            resendTimer:0,
            signInClicked:false,
            routeuserId:this.$route.params.id,
            routecompanyId:this.$route.params.comapnyid,
            routeRequestId:this.$route.params.rid,
            routePlanId:this.$route.params.planid,
            showLoaders:false,
            osProto:Vue.prototype.$currentOs,
        }
    },

    created()
    {
        debugger // eslint-disable-line
        let getOs = Vue.prototype.$currentOs;
        let redirect_url = window.location.origin;
        // console.log(getOs,redirect_url,osProto)
       var self=this;
       self.showLoaders = true;
        // console.log('retertterte4te4',self.routeuserId,self.routecompanyId,self.routeRequestId)
        db.collection(firebaseData.firebasecollection.USER)
            .doc(self.routeRequestId)
            .get().then(doc=>{
                var companies=[]
                var users = []
                var businessContact = []

                if(doc.exists)
                {
                    companies=doc.data().companies||[]
                    
                    var found = false
                    if(companies.length!=0)
                    {
                        companies.forEach(data=>{
                            if(data.id==self.routecompanyId)
                            {
                                found = true;
                                Vue.prototype.$companyName = data;
                            }
                        })
                    }
                    // console.log(found,doc.data())

                    if(!found)
                    {
                        //debugger // eslint-disable-line
                        db.collection('companies')
                            .doc(self.routecompanyId)
                            .get().then(doc2=>{
                                var plan = doc2.data().plan
                                var users = doc2.data().users
                                plan.forEach(plans => {
                                    if(plans.plan_id == self.routePlanId){
                                        companies.push({
                                            id:doc2.id,
                                            companyName:doc2.data().companyName,
                                            isAdmin:false,
                                            planName:plans.plan_name,
                                            planId:self.routePlanId,
                                        })
                                        users=doc2.data().users||[];

                                        let connectedUserArray = [];
                                        connectedUserArray.push(self.routeRequestId);
                                        connectedUserArray.push(self.routeuserId);
                                        let messageSeenIds=[];
                                        messageSeenIds.push({id:self.routeuserId,count:0});
                                        messageSeenIds.push({id:self.routeRequestId,count:0})

                                        db.collection(self.routecompanyId).doc(self.routecompanyId).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                                        .add({
                                            connectedUser : connectedUserArray,
                                            id:'',
                                            isGroup : false,
                                            lastMsgtime : "",
                                            title:'',
                                            updateChat:{},
                                            msgFromBranch:false,
                                            muteArray:[],
                                            mainBranches:[],
                                            updateBranchData:{},
                                            SurpriseSelfie : {},
                                            ToggleSelfie : [],
                                            messageSeenIds: messageSeenIds,
                                            blockBy : ''
                                        })
                                        .then((docRef) => {
                                            let docId = docRef.id;
                                            db.collection(self.routecompanyId).doc(self.routecompanyId).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                                            .doc(docId)
                                            .update({id: docId})

                                            db.collection(firebaseData.firebasecollection.USER).doc(self.routeuserId).collection(self.routecompanyId+'_businessContact').doc(self.routeRequestId).set({
                                                id:self.routeRequestId,
                                                chatId: docId
                                            })
                                            db.collection(firebaseData.firebasecollection.USER).doc(self.routeRequestId).collection(self.routecompanyId+'_businessContact').doc(self.routeuserId).set({
                                                id:self.routeuserId,
                                                chatId: docId
                                            })
                                        })
                                        .catch((error) => {
                                            console.error("ERROR in create chat room: ", error);
                                        })

                                        users.forEach(data => {
                                            if(data.planId == self.routePlanId && data.id == self.routeRequestId){
                                                data.status = 'Active'
                                                data.dataUsage = 0,
                                                data.selfieTime = new Date(),
                                                data.userUsageLimit =  plans.userUsageLimit,
                                                data.noOfSelfie  = plans.noOfSelfie,
                                                data.max_file_size = plans.max_file_size,
                                                data.next_billing_date = plans.next_billing_date,
                                                data.messages_history = plans.messages_history,
                                                data.selfieUsers  =  [],
                                                data.usedSelfie = 0
                                                data.device_plan = plans.device_plan
                                            }
                                        })
                                    }
                                })
                                console.log('users',users)
                                console.log(companies,users,self.routecompanyId,self.routeRequestId)

                                db
                                    .collection(firebaseData.firebasecollection.USER)
                                    .doc(self.routeRequestId)
                                    .update({
                                        companies:companies,
                                        currentSelectedCompany:self.routecompanyId
                                    })
                                    .then((
                                        db
                                            .collection('companies')
                                            .doc(self.routecompanyId)
                                            .update({
                                                users:users
                                            }).then(() =>{
                                                 console.log(firebaseData.firebasecollection.USER,self.routeuserId,self.routecompanyId+'_businessContact')
                                                db.collection(firebaseData.firebasecollection.USER).doc(self.routeuserId).collection(self.routecompanyId+'_businessContact').get().then(docs => {
                                                    docs.forEach(docdata => {
                                                      console.log(docdata.data())
                                                      businessContact.push(docdata.data());
                                                    })
                                                }).then(() => {
                                                      businessContact.forEach(datas => {
                                                        if(datas.id != self.routeRequestId){
                                                            db.collection(firebaseData.firebasecollection.USER).doc(self.routeRequestId).collection(self.routecompanyId+'_businessContact').doc(datas.id).set({
                                                                id:datas.id
                                                            }).then(() => {
                                                                db.collection(firebaseData.firebasecollection.USER).doc(datas.id).collection(self.routecompanyId+'_businessContact').doc(self.routeRequestId).set({
                                                                    id:self.routeRequestId
                                                                })
                                                            })
                                                        }
                                                      })
                                                      self.showLoaders = false;
                                                        if(self.osProto != 'iOS'){
                                                            self.$router.push('/businesschat') 
                                                        }else{
                                                            self.showLoaders = false; 
                                                        }
                                                    //   self.$router.push('/businesschat')
                                                        self.$axios.post(self.$apiurl+'sendAcseptNotification',{receiverId:self.routeRequestId,userData:`${doc.data().firstName} ${doc.data().lastName}`})
                                                        .then(()=>{
                                                            
                                                        })
                                                })
                                            })
                                            .catch(error=>{
                                                console.log(error)
                                                self.showLoaders = false;
                                            })
                                    ))
                                    .catch(error=>{
                                        console.log(error)
                                        self.showLoaders = false;
                                    })
                            })
                    } else {
                        self.$notify({
                            title:"Error",
                            text:'User already exists in the company.',
                            type:"error"
                        })
                        self.$router.push('/businesschat');

                        if(doc.data().currentSelectedCompany !== self.routecompanyId) {
                            db.collection(firebaseData.firebasecollection.USER).doc(self.routeRequestId).update({currentSelectedCompany:self.routecompanyId})
                        }
                    }

                }
            }).then(() => {
                self.showLoaders = false; 
                setTimeout(function(){
                    if(getOs=='iOS'){
                        redirect_url = 'dooey://app';
                        window.location.replace(redirect_url);
                        setTimeout(function(){
                            window.location.replace('https://apps.apple.com/us/app/dooey-messenger/id1196280355');
                        },1000);
                    }
                    if(getOs=='Android'){
                            redirect_url = 'dooey://app';
                            window.location.replace(redirect_url);
                            setTimeout(function(){
                                window.location.replace('https://play.google.com/store/apps/details?id=com.dooeyapp.dev&hl=en&gl=US');
                            },1000);
                    }
                    // else{
                //         redirect_url = window.location.href;
                //     }
                },3000)
            })
       
        if(localStorage.getItem("rememberCustomer")!=null)
        {
            this.stayLoggedIn=true
            var remember = JSON.parse(localStorage.getItem("rememberCustomer"))
            this.email = remember.email
        }
        else
        {
            this.stayLoggedIn=false
        }

    },
    
    methods:{
       gotoLoginBtn(){
            this.showLoaders = false;
            let getOs = Vue.prototype.$currentOs;

            
            let redirect_url = window.location.origin;
            // let application_url = 'dooey://app';
            
            if(getOs=='iOS'){
                redirect_url = `https://apps.apple.com/us/app/dooey-messenger/id1196280355`;
            }
            if(getOs=='Android'){
                redirect_url = 'https://play.google.com/store/apps/details?id=com.dooeyapp.dev&hl=en&gl=US';
            }
            setTimeout(function(){
                window.location.replace(redirect_url);
            },5000);
        },    
    }
}
</script>

