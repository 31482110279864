<!-- Shraddha Chaniyara, 22/06/2021 added validation and save customer into firebase -->

<template>
  <div class="login-wrapper create-account-wrapper" :class="{loaderBackground : isSpinner}" v-if="osProto == 'Windows'">
    <div class="logo">
      <img src="@/assets/images/logo.png" class="logo-login" />
    </div>
    <ValidationObserver ref="log" v-slot="{ handleSubmit }">
    <form class="login-form c_form" @submit.prevent="handleSubmit(signUpUser)" onkeydown="return event.key != 'Enter';"  >
      <!-- <div v-if="isSpinner" style="position: sticky;top: 50%; z-index:20;">
          <sync-loader :loading="true" color="#77bef6" style="text-align: center"></sync-loader>
      </div> -->
      <div class="loader_div" v-if="isSpinner"  >
        <!-- <sync-loader :loading="true" color="#77bef6"></sync-loader> -->
        <img src="@/assets/images/Blue_GIF.gif" class="lodar_gif">
      </div>
      <div class="form-title text-center">
        <h2>Create an Account</h2>
        <img src="@/assets/images/afterimg.png" />
      </div>

      <div class="row">
        <div class="col-md-6">
          <ValidationProvider rules="required|alpha_spaces|min:3" name="first name" v-slot="{ errors }">
          <div class="form-group mb-5">
            <!-- <label>First Name *</label> -->
            <input style="color: black !important" type="text" class="form-control" placeholder="Enter first name*" :maxlength="21" v-model="firstname" autocomplete="off" @blur="IsfirstLetterCapital()" @keypress="OnlyCharacter($event)" @keyup="checkChrecterLengthFirstname(firstname)" />
            <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
            <span class="InvalidInput">{{ errors[0]=='The first name field must be at least 3 characters'?'Minimum 3 character is required.':errors[0] }}</span>

            <span v-if="charecterErrorMessageForFirstname != '' && errors.length == 0"  class="InvalidInput_error">{{charecterErrorMessageForFirstname}}</span>

          </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider rules="required|alpha_spaces|min:3" name="last name" v-slot="{ errors }">
          <div class="form-group">
            <!-- <label>Last Name *</label> -->
            <input style="color: black !important" type="text" class="form-control" placeholder="Enter last name*" :maxlength="21"  v-model="lastname" autocomplete="off" @blur="IsfirstLetterCapital()" @keypress="OnlyCharacter($event)" @keyup="checkChrecterLengthLastname(lastname)"/>
            <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
            <span class="InvalidInput">{{ errors[0]=='The last name field must be at least 3 characters'?'Minimum 3 character is required.':errors[0] }}</span>

            <span v-if="charecterErrorMessageForLastname != '' && errors.length == 0"  class="InvalidInput_error">{{charecterErrorMessageForLastname}}</span>

          </div>
          </ValidationProvider>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
          <div class="form-group mb-5">
            <!-- <label>Email *</label> -->
            <input style="color: black !important" type="text" class="form-control" placeholder="Enter email*" :maxlength="51" v-model="email" autocomplete="off" @blur="IsfirstLetterCapital()" :disabled="disabledemail!=''" @keyup="validateemail(),checkChrecterLengthEmail(email)" />
            <span class="InvalidInput">{{ errors[0] }}</span>
            <span class="InvalidInput" v-if="emaiErrorMessage!= '' ">{{emaiErrorMessage}}</span>
            <span v-if="charecterErrorMessageForEmail != ''&& errors.length == 0" class="InvalidInput">{{charecterErrorMessageForEmail}}</span>


          </div>
          </ValidationProvider>
           <div class="form-group mb-5">
                <!-- <input type="text" id="phone" placeholder="Enter phone number" class="form-control" v-model="phone" @keypress="isCharAt($event)"> -->
                <!-- <vue-tel-input v-model="phonenumber"  @input="checkPhoneNumber"  name="phone number" ></vue-tel-input> -->
                <VuePhoneNumberInput v-model="phonenumber" default-country-code="GB" placeholder="Enter mobile number"  @update="checkPhoneNumber"  :maxlength = "15" :disabled="!showOtp || disabledPhone"/>
                <!-- <span class="InvalidInput">{{ errors[0] }}</span> -->
                <span style="color:red;" v-if="phonenumberErrorMessage!= '' ">{{phonenumberErrorMessage}}</span>
                <span class="InvalidInput" v-if="phoneerror != '' ">{{phoneerror}}</span>

                <button class="resend_otp" type="button" ref="ref_otpbutton" @click="SendOtp()" :disabled="phoneerror != '' || phonenumberErrorMessage != '' " v-if="resendCode == false && resendTimer == 0 && showOtp == true">Get OTP</button>
                <button class="resend_otp" type="button"  @click="SendOtp()" v-if="resendCode == true && resendTimer == 0 && showOtp == true" >Resend Code</button>
                <span class="resend_otp" v-if="resendTimer!=0  && showOtp == true">{{resendTimer}}</span>
                <img  class="resend_otp" src="@/assets/images/Approved.png" v-if="showOtp == false"/>
            </div>
              <ValidationProvider  name="otpcode" v-slot="{ errors }">
                  <div v-if="showVerifyDigitScreen == true && showOtp == true">
                    <div class="title_verification" ><span>Verification code Must be 6 digits</span></div>
                      <div class="otp-wrapper" >
                        <v-otp-input
                        ref="otpInput"
                        v-model="otpinput"
                        input-classes="otp-input"
                        separator=""
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                        />
                      <span class="InvalidInput">{{ errors[0] }}</span>
                      <span class="InvalidInput" v-if="otpError!= '' ">{{otpError}}</span>
                      </div>
                  </div>
                 </ValidationProvider>
          <ValidationProvider rules="required" name="password" v-slot="{ errors }">
          <div class="form-group mb-5">
            <!-- <label>Password *</label> -->
            <input style="color: black !important" :type="passwordType" maxlength="25" class="form-control" placeholder="Enter password*" autocomplete="off" v-model="password" @keyup="validatePassword($event)" id="password"/>
            <img v-if="passwordType=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye eye_createaccount">
            <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye eye_createaccount">
            <!-- <img title="Strong passwords have at least 6 characters and a mix of letters and numbers." src="@/assets/images/view_detail-orange.png" alt="signin" style="cursor:pointer" class="infoicon_createaccount" > -->
            <b-button id="clickbox"  v-b-tooltip.click.rightbottom class="btn-tooltip" @blur="showtooitip = false" @click="showtooltip(),insideDivClick()" :title="showtooitip ? 'Password must contains at least 8 characters, including one upper/lowercase, numbers and one special character(@#$%).':''"><img src="@/assets/images/view_detail-orange.png" title='' alt="signin" style="cursor:pointer!important"></b-button>
            <span class="InvalidInput">{{ errors[0] }}</span>
            <span class="InvalidInput password_validation_message" v-if="passwordErrorMessage!= '' ">{{passwordErrorMessage}}</span>
          </div>
          </ValidationProvider>
          <ValidationProvider rules="required|confirmed:password" name="confirm password" v-slot="{ errors }">
          <div class="form-group">
            <!-- <label>ConFirm password *</label> -->
             <input style="color: black !important" :type="passwordTypes" maxlength="25" class="form-control" placeholder="Enter confirm password*" autocomplete="off" v-model="confirmpassword" id="confirmpassword" />
            <img v-if="passwordTypes=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPasswords();" id="togglePassword" class="password_eye eye_createaccount">
            <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPasswords();" id="togglePassword" class="password_eye eye_createaccount">
            <span class="InvalidInput">{{ errors[0]=='The confirm password field confirmation does not match'?'The password does not match':errors[0] }}</span>
          </div>
          </ValidationProvider>
        </div>
      </div>

      <div class="btn-wrapper">
        <!-- <b-button type="button" variant="primary" disabled v-if="isSpinner">                                        
            Loading....
        </b-button> -->
        <button class="btn btn-yellow"   v-bind:class="{disabled: showOtp == true}" >SIGN UP</button>
      </div>
      <p class="login-bottom-text">
        Have an account? Click here to <router-link to="/">Login</router-link>
      </p>
    </form>
    </ValidationObserver>
    <FrontFooter />
  </div>
  <div class="login-wrapper create-account-wrapper" v-else>
    <div class="logo">
        <img src="@/assets/images/logo.png" class="logo-login" :class="{loaderBackground : showLoaders}">
    </div>
    <div class="downloadButtonFromIos">
      <div>
        <a
          href="https://apps.apple.com/us/app/dooey-messenger/id1196280355"
          style="margin-top: 20px"
          v-show="osProto == 'iOS'"
          class="btn btn-yellow ios_verification_btn"
          >Download App</a
        >
      </div>
      <div>
        <a
          href="dooey://app"
          @click="gotoLoginBtn()"
          style="margin-top: 20px"
          v-show="osProto == 'iOS'"
          class="btn btn-yellow ios_verification_btn"
          >Open App</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "./firebaseInit";
import FrontFooter from "@/components/forntfooter.vue";
import firebaseTimestamp from "firebase";
import intlTelInput from 'intl-tel-input';
import firebaseData from '../utils/firebaseCollection.js';
import {getAllEmailArray,getAllPhoneArray,getAllUserIdArray} from '@/utils/getQueeryActiveChatList.js'
// import Vue from "vue";
const db = firebase.firestore()
import Vue from "vue";
// import { VueTelInput } from 'vue-tel-input';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
export default {
  name: "inviteNewUser",
  components: {
    FrontFooter,
    // intlTelInput,
    firebaseData,
     SyncLoader,
     VuePhoneNumberInput
    
  },
  data(){
    return{
      isSpinner:false,
      firstname:'',
      lastname:'',
      email:'',
      phonenumber:'',
      password:'',
      confirmpassword:'',
      randomeCode:'',
      passwordErrorMessage:'',
      phonenumberArray:[],
      phonenumberErrorMessage:'',
      invitee:this.$route.params.id,
      UserId:[],
      inViteUserId:[],
      senderId:'',
      routecompanyId:this.$route.params.comapnyid,
      routeRequestId:this.$route.params.id,
      routePlanId:this.$route.params.planid,
      randomeCode:this.$route.params.randomecode,
      disabledphone:'',
      disabledemail: '',
       passwordType:"password",
      passwordTypes:"password",
      showtooitip : false,
       phoneerror:'',
      phoneValid : '' ,
      phonenumber:'',
      mainNumber:'',
      errorphone:'',
      showtooitip:false,
      showVerifyDigitScreen : false,
      resendCode:false,
      resendTimer:0,
      otpinput : '',
      otpvalue : '',
      getData:{},
      showOtp : true,
      otpError : '',
      phonenumberArray:[],
      countryflag:'',
      emaiErrorMessage:'',
      emailArray:[],
      disabledPhone : false,
      countrycallingCode:'',
      charecterErrorMessageForFirstname:'',
      charecterErrorMessageForLastname:'',
      charecterErrorMessageForEmail:'',
      msgCount:0,
      requestCount:0,
      businessMsgCount:0,
      osProto:Vue.prototype.$currentOs,
      
    }
  },
  created(){
    let getOs = Vue.prototype.$currentOs;
    let redirect_url = window.location.origin;
    //   if(getOs=='iOS'){
    //     redirect_url = 'dooey://app';
    //       window.location.replace(redirect_url);
    //   }
    //   else if(getOs=='Android'){
    //         redirect_url = 'dooey://app';
    //         window.location.replace(redirect_url);
    //         setTimeout(function(){
    //             window.location.replace('https://play.google.com/store/apps/details?id=com.dooeyapp.dev&hl=en&gl=US');
    //         },1000);
    //   }else{
    //       redirect_url = window.location.href;
    //   }
    setTimeout(function(){
      if(getOs=='iOS'){
        redirect_url = 'dooey://app';
        window.location.replace(redirect_url);
        setTimeout(function(){
          window.location.replace('https://apps.apple.com/us/app/dooey-messenger/id1196280355');
        },1000);
      } else if (getOs=='Android'){
        redirect_url = 'dooey://app';
        window.location.replace(redirect_url);
        setTimeout(function(){
            window.location.replace('https://play.google.com/store/apps/details?id=com.dooeyapp.dev&hl=en&gl=US');
        },1000);
      }
    },3000)
    var self = this;
    self.phonenumberArray = [];
    self.emailArray = [];
    // debugger // eslint-disable-line
    // console.log(self.routecompanyId)
    db.collection(firebaseData.firebasecollection.COMPANY).doc(self.routecompanyId).get().then(snap =>{
      var companyuser = snap.data().users
      companyuser.forEach(usr => {
        if(usr.randomecode == self.randomeCode){
          if(usr.email != ''){
            self.email = usr.email
            self.disabledemail = usr.email
          }
          if(usr.phone){
           self.phonenumber = usr.phone 
           self.disabledphone = usr.phone
          }
        }
      })
    })
    getAllEmailArray(firebaseData.firebasecollection.USER,(res) => {
      self.emailArray=res;
    })
    getAllPhoneArray(firebaseData.firebasecollection.USER,(res) => {
      self.phonenumberArray=res;
    })
    getAllUserIdArray(firebaseData.firebasecollection.USER,(res) => {
      self.UserId = res;
      // console.log('self.UserId',self.UserId)
    })
  },
  methods:{
    checkPhoneNumber(phone)
    {
     
      // console.log(phone)
       let isInArray = this.phonenumberArray.some(function(item) {
          return item.phoneNo == phone.nationalNumber && !item.isDeleted
        });
        if(isInArray == true && this.phonenumber != ''){
          this.$refs.log.reset();
          this.phoneerror = ''
          this.phonenumberErrorMessage = 'Phone number is already exists'
        }else{
          this.phonenumberErrorMessage = ''
            this.mainNumber = phone.formattedNumber;
            this.phonenumber = phone.nationalNumber;
            this.countryflag = phone.countryCode;
            this.phoneValid = phone.isValid;
            this.countrycallingCode = '+'+phone.countryCallingCode
            // console.log("number,phone",this.phonenumber, this.phoneValid);
              if(this.phoneValid == false  && this.phonenumber != ''){
                if(this.phonenumber != undefined){
                  this.phoneerror = 'please enter valid phone number';
                }
              }else{
                this.phoneerror = '';
                this.$nextTick(() => {
                    this.$refs.ref_otpbutton.focus();
                })
              }
        }
      
          // if(this.phoneValid == false && this.phonenumber != '' ){
          //   return false
          // }
          // if(this.phoneValid == false  && this.phonenumber != ''  ){
          //      this.phoneerror = 'please enter valid phone number'
          // }else{
          //   this.phoneerro
          // }
        
      
    
      
    },
    validateemail(){
        debugger // eslint-disable-line
        var self=this;
        let isInArray = self.emailArray.some(function(item) {
            return item.email == self.email && !item.isDeleted
        });
        if(isInArray == true && self.email != ''){
          self.$refs.log.reset();
          self.emaiErrorMessage = 'Email is already exists'
        }else{
          self.emaiErrorMessage = ''
        }
    },
    validatePhonenumber(){
      // //debugger // eslint-disable-line
      var self=this;
      let isInArray = self.phonenumberArray.some(function(item) {
          return item == self.phonenumber
      });
      if(isInArray == true && self.phonenumber != ''){
        self.$refs.log.reset();
        self.phonenumberErrorMessage = 'Phone number is already exists'
      }else{
        self.phonenumberErrorMessage = ''
      }
      
    },
    // SendOtp(){
    //   debugger // eslint-disable-line
    //   var self=this;
    //   self.phonenumber = self.phonenumber.replace(' ', '');
    //   if(self.phonenumber.slice(0,1) == 0){
    //       self.phonenumber= self.phonenumber.substring(1);
    //   }
    //   var after_concate_phone_no = self.countrycallingCode+' '+self.phonenumber
    //   if(self.phonenumber == '' || self.phonenumber == undefined){
    //     self.phoneerror = 'Please enter your phone number';
    //   }else{
    //     self.phoneerror = ''
    //     self.disabledPhone = true
    //     if(self.phoneValid && self.phonenumber != '' && self.phonenumberErrorMessage == ''){
    //       self.randomeCodes = Math.floor(Math.random() * 99999) + 100000;
    //       localStorage.removeItem('signupData');
    //       localStorage.setItem('signupData',JSON.stringify({phoneNumber:self.mainNumber,otp:self.randomeCodes}))
    //       console.log(self.randomeCodes)
    //       self.$axios.post(self.$apiurl+'sendOtpOnMObile',{phoneNumber:after_concate_phone_no,otp:self.randomeCodes})
    //       .then((res)=>{
    //         self.$root.$emit("audioplay");
    //         self.$notify({
    //               title:"Success",
    //               text:'Verification SMS sent.',
    //               type:"success",
    //               duration: 10000,
    //         })
    //         // console.log('resresres',res)
    //         if(res.data.status == true){
    //           self.showVerifyDigitScreen = true
    //           self.resentLinkTimer();
    //         }else{
    //           self.$root.$emit("audioplay");
    //           self.$notify({
    //               title:"Error",
    //               text:res.data.error,
    //               type:"error",
    //               duration: 10000,
    //           })
    //         }
    //       })
    //     }else{
    //       self.phoneerror = 'please enter valid phone number'
    //     }
    //   }
      
    // },
    SendOtp(){
      debugger // eslint-disable-line
      var self=this;
      if(self.firstname != '' && self.lastname != ''){
        var userFullName = `${self.firstname} ${self.lastname}`
        self.phonenumber = self.phonenumber.replace(' ', '');
        if(self.phonenumber.slice(0,1) == 0){
            self.phonenumber= self.phonenumber.substring(1);
        }
        var after_concate_phone_no = self.countrycallingCode+' '+self.phonenumber
        if(self.phonenumber == '' || self.phonenumber == undefined){
          self.phoneerror = 'Please enter your phone number';
        }else{
          self.phoneerror = ''
          self.disabledPhone = true
          if(self.phoneValid && self.phonenumber != '' && self.phonenumberErrorMessage == ''){
            self.randomeCodes = Math.floor(Math.random() * 99999) + 100000;
            localStorage.removeItem('signupData');
            localStorage.setItem('signupData',JSON.stringify({phoneNumber:self.mainNumber,otp:self.randomeCodes}))
            // console.log(self.randomeCodes)
            self.$axios.post(self.$apiurl+'sendOtpOnMObile',{phoneNumber:after_concate_phone_no,otp:self.randomeCodes,userName : userFullName})
            .then((res)=>{
              // console.log('resresres',res)
              self.$root.$emit("audioplay");
              self.$notify({
                    title:"Success",
                    text:'Verification SMS sent.',
                    type:"success",
                    duration: 10000,
              })
              if(res.data.status == true){
                self.showVerifyDigitScreen = true
                self.resentLinkTimer();
              }else{
                self.$root.$emit("audioplay");
                self.$notify({
                    title:"Error",
                    text:res.data.error,
                    type:"error",
                    duration: 10000,
                })
              }
            })
          }else{
            self.phoneerror = 'please enter valid phone number'
          }
        }
      }else{
        self.$notify({
              title:"Error",
              text:'Please enter firstname and lastname.',
              type:"error",
              duration: 10000,
        })
      }
    },
   resentLinkTimer()
        {
            var self = this
            if(self.resendTimer==0)
            {
                self.resendTimer=120
                
                var interval = setInterval(()=>{
                    if(self.resendTimer>0)
                    {
                        self.resendTimer--
                    }
                    if(self.resendTimer==0)
                    {

                        self.resendTimer=0
                        clearInterval(interval)
                        self.resendCode = true
                        self.otpError = ''
                        localStorage.removeItem('signupData');
                       
                    }
                },1000)
            }
    },
    // SEND VERIFICATION OTP TO EMAIL FOR VERIFICATION(Shraddha)
    signUpUser(){
      // debugger // eslint-disable-line
      var self=this;
      self.showtooitip = false;
      if(self.charecterErrorMessageForFirstname == '' && self.charecterErrorMessageForLastname == '' && self.charecterErrorMessageForEmail == ''){
        var businessContact = []
        if(self.$refs.log){
          self.$refs.log.validate().then(success => {
            // console.log('success',success)
              if(success){
                // console.log('firebaseData',firebaseData)
                const db = firebase.firestore()
                self.isSpinner = true;
                if(self.passwordErrorMessage == '' && self.phonenumberErrorMessage == '' && self.emaiErrorMessage == '' && self.phoneerror == ''){
              // if(self.password == self.confirmpassword){
                firebase.auth().createUserWithEmailAndPassword(self.email, self.password)
                .then((result)=>{
                  // console.log(result)
                  var uid = result.user.uid
                    db.collection(firebaseData.firebasecollection.USER)
                    .doc(result.user.uid)
                    .set({
                        BioDiscription : '',
                        company : '',
                        countryCode : self.countrycallingCode,
                        countryFlag : self.countryflag,
                        createdAt:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                        currentSelectedCompany : '',
                        email : self.email,
                        emailVerified :false,
                        firstName : self.firstname,
                        lastName : self.lastname,
                        id : result.user.uid,
                        isOnline : '',
                        lastOnlineTimestamp : '',
                        phoneNo :  self.phonenumber.replace(/\s/g, ''),
                        // profileUrl : '',
                        profileUrl : Vue.prototype.$defaultProfile,
                        pushTokens : '',
                        userRole : "Social",
                        BusinessName:'',
                        jobTitle:'',
                        userStatus:'Inactive',
                        userType:'Free',
                        SocialreadReceipts:true,
                        firstTimeLogin:true,
                        isAdmin:false,
                        feedback: "",
                        rating: "",
                        SocialSurpriseSelfie: true,
                        BusinessSurpriseSelfie: true,
                        BusinessreadReceipts: true,
                        resetLinkUsed:false,
                        resetLinkId:'',
                        verifyLinkId:'',
                        selfieUsers:[],
                        
                    })
                    .then(()=>{
                      
                      //ADD USER IN COMPANIES AFTER REGISTRATION
                      db.collection(firebaseData.firebasecollection.USER)
                        .doc(result.user.uid)
                        .get().then(doc=>{
                            var companies=[]
                            var users = []
                            if(doc.exists)
                            {
                                companies=doc.data().companies||[]
                                
                                var found = false
                                if(companies.length!=0)
                                {
                                    companies.forEach(data=>{
                                        if(data.id==Number(self.routecompanyId))
                                        {
                                            found = true
                                        }
                                    })
                                }
                                // console.log(found,doc.data())

                                if(!found)
                                {
                                    db.collection('companies')
                                        .doc(self.routecompanyId)
                                        .get().then(doc2=>{
                                          var plan = doc2.data().plan
                                        plan.forEach(plans => {
                                            if(plans.plan_id == Number(self.routePlanId)){
                                              companies.push({
                                                id:doc2.id,
                                                companyName:doc2.data().companyName,
                                                isAdmin:false,
                                                planName:plans.plan_name,
                                                planId:Number(self.routePlanId)
                                              })

                                              users=doc2.data().users||[]
                                              users.forEach(usr=>{
                                                if(usr.randomecode == self.randomeCode){
                                                  // users.push({
                                                    usr.id = result.user.uid,
                                                    usr.planName = plans.plan_name
                                                    usr.name = `${doc.data().firstName} ${doc.data().lastName}`,
                                                    usr.status = 'Active',
                                                    usr.dataUsage = 0,
                                                    usr.selfieTime  = new Date(),
                                                    usr.userUsageLimit = plans.userUsageLimit,
                                                    usr.noOfSelfie = plans.noOfSelfie,
                                                    usr.max_file_size = plans.max_file_size,
                                                    usr.next_billing_date = plans.next_billing_date,
                                                    usr.profileUrl = doc.data().profileUrl,
                                                    usr.messages_history = plans.messages_history,
                                                    usr.selfieUsers  =  [],
                                                    usr.usedSelfie = 0,
                                                    usr.noOfSelfie = plans.noOfSelfie,
                                                    usr.device_plan = plans.device_plan
              
                                                  // })
                                                }
                                              })
                                              
                                            }
                                          })
                                            // console.log('users',users)
                                            // console.log(companies,users,self.routecompanyId,result.user.uid)

                                            db.collection(firebaseData.firebasecollection.USER)
                                                .doc(result.user.uid)
                                                .update({
                                                  companies:companies,
                                                })
                                                .then((
                                                    db.collection('companies')
                                                        .doc(self.routecompanyId)
                                                        .update({
                                                            users:users
                                                        })
                                                        .then(() => {
                                                          let connectedUserArray = [];
                                                          connectedUserArray.push(self.routeRequestId);
                                                          connectedUserArray.push(result.user.uid);
                                                          let messageSeenIds=[];
                                                          messageSeenIds.push({id:result.user.uid,count:0});
                                                          messageSeenIds.push({id:self.routeRequestId,count:0})

                                                          db.collection(self.routecompanyId).doc(self.routecompanyId).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                                                          .add({
                                                              connectedUser : connectedUserArray,
                                                              id:'',
                                                              isGroup : false,
                                                              lastMsgtime : "",
                                                              title:'',
                                                              updateChat:{},
                                                              msgFromBranch:false,
                                                              muteArray:[],
                                                              mainBranches:[],
                                                              updateBranchData:{},
                                                              SurpriseSelfie : {},
                                                              ToggleSelfie : [],
                                                              messageSeenIds: messageSeenIds,
                                                              blockBy : ''
                                                          })
                                                          .then((docRef) => {
                                                              let docId = docRef.id;
                                                              db.collection(self.routecompanyId).doc(self.routecompanyId).collection(firebaseData.firebasecollection.BUSINESSMESSAGE)
                                                              .doc(docId)
                                                              .update({id: docId})

                                                            db.collection(firebaseData.firebasecollection.USER).doc(result.user.uid).collection(self.routecompanyId+'_businessContact').doc(self.routeRequestId).set({
                                                                id:self.routeRequestId,
                                                                chatId: docId
                                                            }).then(() => {
                                                                db.collection(firebaseData.firebasecollection.USER).doc(self.routeRequestId).collection(self.routecompanyId+'_businessContact').doc(result.user.uid).set({
                                                                  id:result.user.uid,
                                                                  chatId: docId
                                                                }).then(() =>{
                                                                  // console.log(firebaseData.firebasecollection.USER,self.routeRequestId,self.routecompanyId+'_businessContact')
                                                                  db.collection(firebaseData.firebasecollection.USER).doc(self.routeRequestId).collection(self.routecompanyId+'_businessContact').get().then(docs => {
                                                                      docs.forEach(docdata => {
                                                                        // console.log(docdata.data())
                                                                        businessContact.push(docdata.data());
                                                                      })
                                                                  }).then(() => {
                                                                    businessContact.forEach(datas => {
                                                                      if(datas.id != result.user.uid){
                                                                        db.collection(firebaseData.firebasecollection.USER).doc(result.user.uid).collection(self.routecompanyId+'_businessContact').doc(datas.id).set({
                                                                            id:datas.id
                                                                        }).then(() => {
                                                                          db.collection(firebaseData.firebasecollection.USER).doc(datas.id).collection(self.routecompanyId+'_businessContact').doc(result.user.uid).set({
                                                                            id:result.user.uid
                                                                          })
                                                                        })
                                                                      }
                                                                    })
                                                                  })
                                                                }) 

                                                              firebase.auth().signInWithEmailAndPassword(self.email, self.password)
                                                              .then(userData=>{
                                                                  // console.log(userData)
                                                                  if(!userData.user.emailVerified)
                                                                  {
                                                                      // var actionCodeSettings = {
                                                                      //     url: window.location.origin,
                                                                      //     handleCodeInApp: true,
                                                                      // };
                                                                      // userData.user.sendEmailVerification(actionCodeSettings)
                                                                      // .then(()=>{
                                                                      self.$axios.post(firebaseData.firebasecollection.APIDOMAIN + '/userVerificationEmail',{email:self.email,docId:userData.user.uid})
                                                                      .then((results)=>{
                                                                        if(results.data.status==200)
                                                                        {
                                                                          firebase.auth().signOut()
                                                                          .then(()=>{
                                                                              self.$root.$emit("audioplay");
                                                                              self.$notify({
                                                                                  title:"Success",
                                                                                  text:'Sign Up Successful - Verification sent to your email address.',
                                                                                  type:"success",
                                                                                  duration: 10000,
                                                                              })
                                                                              //send mail to admin for new user
                                                                              if(Vue.prototype.$adminDetails.userSignupNotification)
                                                                              {
                                                                                self.$axios.post(self.$apiurl+'sendMailToAdmin',{adminId:Vue.prototype.$adminDetails.id,message:'New user registered with '+self.email+' email.',operation:'register'})
                                                                                .catch(error=>{
                                                                                  console.log(error)
                                                                                })
                                                                              }
                                                                              self.$axios.post(self.$WPbaseurl+'wp-json/create/user',{email:self.email,fname:self.firstname,lname:self.lastname,phone:self.phonenumber,user_id:result.user.uid,plan_type:'social'})
                                                                                .then((res)=>{
                                                                                  // console.log('wordpress ragister api response',res,res.data.wp_user_id)
                                                                                self.$axios.post(self.$apiurl+'sendAcseptNotification',{receiverId:self.routeRequestId,userData:`${self.firstname} ${self.lastname}`})
                                                                                  .then((res)=>{
                                                                                    // console.log('res',res)
                                                                                  })
                                                                                // console.log(res)
                                                                                self.$router.push({name:'verifyAccount', params:{id: uid}});
                                                                                self.resetForm()
                                                                                self.isSpinner = false;
                                                                                
                                                                              })
                                                                              
                                                                          })
                                                                        }
                                                                        else
                                                                        {
                                                                          self.$root.$emit("audioplay");
                                                                          self.$notify({
                                                                            title:"Error",
                                                                            text:results.data.message,
                                                                            type:'error'
                                                                          })
                                                                          self.isSpinner=false;
                                                                        }
                                                                      })
                                                                      .catch(error=>{
                                                                          self.isSpinner = false;
                                                                          self.$root.$emit("audioplay");
                                                                          self.$notify({
                                                                              title:"Error",
                                                                              text:error.message,
                                                                              type:"error"
                                                                          })
                                                                      })
                                                                  }
                                                              })
                                                              .catch(error=>{
                                                                self.$root.$emit("audioplay");
                                                                self.$notify({
                                                                  title:"ERROR",
                                                                  text:'We could not match an account to what you have entered, please try again.',
                                                                  type:"error"
                                                                })
                                                              })
                                                          
                                                            }) 
                                                          })
                                                          .catch((error)=>{
                                                            console.error("ERROR in create chat room: ", error);
                                                          })
                                                  })
                                                  .catch(error=>{
                                                      self.isSpinner = false;
                                                      self.$root.$emit("audioplay");
                                                      self.$notify({
                                                          title:"ERROR",
                                                          text:error.message,
                                                          type:"error"
                                                      })
                                                  })
                                                        .catch(error=>{
                                                            console.log(error)
                                                            alert('added')
                                                        })
                                                ))
                                                .catch(error=>{
                                                    console.log(error)
                                                })
                                        })
                                }

                            }
                        })
                      
                    
                    })
                  
                })
                .catch(error=>{
                  self.isSpinner = false;
                    self.$root.$emit("audioplay");
                    self.$notify({
                        title:"ERROR",
                        text:error.message,
                        type:"error"
                    })
                })
                } 
              }
          })
        }
      }
      
      
   },
    // IN FIRST AND LAST NAME FIRST LETTER CAPITAL AND IN EMAIL ID FIRST LETTER IS SMALL (Shraddha)
    IsfirstLetterCapital(){
      if(this.firstname!='')
      {
          this.firstname= this.firstname.charAt(0).toUpperCase() + this.firstname.substring(1)
      }
      if(this.lastname!='')
      {
          this.lastname= this.lastname.charAt(0).toUpperCase()+this.lastname.substring(1)
      }
      //email small case
      if(this.email!='')
      {
          this.email= this.email.toLowerCase()
      }
    },
    OnlyCharacter: function (event) {
      const char = String.fromCharCode(event.keyCode)
      if (!/[a-zA-Z ]/.test(char)) {
        event.preventDefault()
      }
    },
    // ONLY ENTER NUMBER(PHONE NUMBER) (Shraddha)
    isCharAt(evt)
    {
      // //debugger // eslint-disable-line
      const char = String.fromCharCode(evt.keyCode);
        if (!/[0-9 ||.]/.test(char)) {
            evt.preventDefault();
        }
    },
    showtooltip(){
      // debugger // eslint-disable-line
      if(this.showtooitip == true){
        this.showtooitip = false
      }else{
        this.showtooitip = true
      }
    },

    validatePassword(evt){
    // //debugger // eslint-disable-line
    var self=this;
    // console.log(evt.target.value)
    var regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if(regex.test(evt.target.value)){
        // console.log('dfd',regex.test(evt.target.value))
        self.passwordErrorMessage = ''
      }else{
        if(evt.target.value != ''){
          self.passwordErrorMessage = 'Password must contains at least 8 characters, including one upper/lowercase, numbers and one special character(@#$%).';
          self.$refs.log.reset();
        }else{
          self.passwordErrorMessage = ''
        }
      }
    },
    //FOR RESET FORM (Shraddha)
    resetForm(){
      var self=this;
      self.firstname = '',
      self.lastname = '',
      self.email = '',
      self.phonenumber = '',
      self.password = '',
      self.confirmpassword = ''
    },
     // TOGGLE PASSWORD (Shraddha)        
      showPassword(){
          if(this.passwordType=='password')
            {
                this.passwordType='text'
            }
            else
            {
                this.passwordType='password'
            }
      },
      showPasswords(){
        if(this.passwordTypes=='password')
        {
            this.passwordTypes='text'
        }
        else
        {
            this.passwordTypes='password'
        }
      },
     
      handleOnComplete(value) {
        // debugger // eslint-disable-line
        var self =this;
        self.otpvalue = value
        var signupdata= localStorage.getItem("signupData");
        self.getData = JSON.parse(signupdata)
        // console.log('self.getData',self.getData)
        if(self.getData && self.getData.phoneNumber == self.mainNumber && self.getData.otp == self.otpvalue){
          self.showOtp = false;
          self.otpError = '';
        }else{
          self.otpError = 'Your otp is incorrect.'
          setTimeout(() => {
            self.handleClearInput();
          }, 100)
        }
    
        
      },
      handleOnChange(value) {
        //console.log('OTP changed: ', value);
      },
      handleClearInput() {
        this.$refs.otpInput.clearInput();
      }, 
       // OUTSIDE DIV EVENT CLICK FOR CLOSE TOOLTIP(Shraddha)
      insideDivClick(){
        //  debugger // eslint-disable-line
         var self=this;
         window.addEventListener('click', function(e){   
          if (document.getElementById('clickbox').contains(e.target)){
              // alert('inside')
          } else{
            // alert('outside')
            self.showtooitip = false
          }
        });
      } ,
       //CHECK GROUP NAME CHRECTER LENGTH(Shraddha)
      checkChrecterLengthFirstname(groupName){
        // debugger // eslint-disable-line
        var self = this;
        self.firstname=groupName.replace(/[^A-Za-z]/ig,'')

        if(groupName.length < 21){
            self.firstname=groupName.replace(/[^A-Za-z]/ig,'')
            self.charecterErrorMessageForFirstname = '';
        }else{
            self.charecterErrorMessageForFirstname = 'The firstname may not be greater than 20 characters';
            self.firstname = (groupName).slice(0, 20)
        }
      },
      checkChrecterLengthLastname(groupName){
          // debugger // eslint-disable-lines
          var self = this;
          self.lastname=groupName.replace(/[^A-Za-z]/ig,'')
          if(groupName.length < 21){
              self.lastname=groupName.replace(/[^A-Za-z]/ig,'')
              self.charecterErrorMessageForLastname = '';
          }else{
              self.charecterErrorMessageForLastname = 'The lastname may not be greater than 20 characters';
              self.lastname = (groupName).slice(0, 20)
          }

      },
      checkChrecterLengthEmail(groupName){
          // debugger // eslint-disable-lines
          var self = this;
          // self.email=groupName.replace(/[^A-Za-z0-9]/ig,'')
          if(groupName.length < 51){
              self.email = groupName
              self.charecterErrorMessageForEmail = '';
          }else{
              self.charecterErrorMessageForEmail = 'The email may not be greater than 50 characters';
              self.email = (groupName).slice(0, 50)
          }
      },
      gotoLoginBtn(){
        this.showLoaders = false;
        let getOs = Vue.prototype.$currentOs;

        
        let redirect_url = window.location.origin;
        // let application_url = 'dooey://app';
        
        if(getOs=='iOS'){
            redirect_url = 'https://apps.apple.com/us/app/dooey-messenger/id1196280355';
        }
        if(getOs=='Android'){
            redirect_url = 'https://play.google.com/store/apps/details?id=com.dooeyapp.dev&hl=en&gl=US';
        }
        
        setTimeout(function(){
            window.location.replace(redirect_url);
        },5000);
    },        
  
  },
 
};
</script>
<style>
.vue-phone-number-input.flex .flex-1 .input-tel input,.vue-phone-number-input.flex .country-selector input {
  color: black !important;
}
</style>