<!-- Shraddha Chaniyara, 22/06/2021 added validation and send verification link to email for reset password -->

<template>
        <div class="login-wrapper forgot-wrapper">
            <div class="logo">
                <img src="@/assets/images/logo.png" class="logo-login">
            </div>
            <ValidationObserver ref="log" v-slot="{ handleSubmit }">
            <form class="login-form c_form" @submit.prevent="handleSubmit(submitEmail)">
                <div class="form-title text-center">
                    <h2>Forgot Password</h2>
                    <img src="@/assets/images/afterimg.png">
                     <p>Enter your email address and we'll send you a link to reset your password.</p>
                </div>
               
                <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                <div class="form-group mb-3">
                    <!-- <label>Email *</label> -->
                    <input style="color: black !important" type="text" class="form-control" placeholder="Enter email*" maxlength="50" name="email" v-model="email" @blur="IsfirstLetterCapital()">
                     <span class="InvalidInput">{{ errors[0] }}</span>
                </div>
                </ValidationProvider>
                <div class="btn-wrapper">
                     <!-- <b-button type="button" variant="primary" class="btn btn-primary" disabled v-if="isSpinner">
                        Loading....
                    </b-button> -->
                    <button class="btn btn-yellow"  v-bind:class="{disabled: isSpinner == true}">Submit</button>
                </div>

                <p class="login-bottom-text">
                    Click here to <router-link to="/"><span style="color: #77bef6;">Login</span></router-link>
                </p>
               
            </form>
            </ValidationObserver>
            <FrontFooter/>
        </div>
        
</template>

<script>

import FrontFooter from '@/components/forntfooter.vue'
import firebase from './firebaseInit'
import firebaseData from '../../src/utils/firebaseCollection.js';

export default {
  name: 'forgotpassword',
    components:{
        FrontFooter,
        firebaseData
    },
    data(){
        return{
            isSpinner: false,
            email:''
        }
    },
    methods:{
// SEND EMAIL IF CUSTOMER FORGOTPASSWORD(Shraddha)        
        submitEmail(){
            // //debugger // eslint-disable-line
            var self = this
            self.isSpinner=true;
            // var noUserFound = true
            const db = firebase.firestore()
            // var docId=null
            // var firstName = ''

            // db.collection(firebaseData.firebasecollection.USER)
            // .get()
            // .then(querySnapshot=>{
            //     querySnapshot.forEach(doc=>{
            //         if(doc.data().email==self.email && doc.data().userRole == "Social" && doc.data().userStatus == 'Active')
            //         {
            //             firstName = doc.data().firstName
            //             noUserFound = false
            //             docId=doc.id
            //         }
            //     })
            //     if(noUserFound)
            //     {
            //         self.isSpinner=false;
            //         self.$notify({
            //             title:"Error",
            //             text:'no such user exists.',
            //             type:"error"
            //         })   
            //     }
            //     else
            //     {
                    self.$axios.post(firebaseData.firebasecollection.APIDOMAIN+'/forgotPassword',{email:self.email})
                    .then((result)=>{
                        console.log(result)
                        if(result.data.status==200)
                        {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Success",
                                text:result.data.message,
                                type:'success'
                            })
                            self.isSpinner=false;
                            self.$router.push({'name':'login'});
                        }
                        else
                        {
                            self.$root.$emit("audioplay");
                            self.$notify({
                                title:"Error",
                                text:result.data.message,
                                type:'error'
                            })
                            self.isSpinner=false;
                        }
                    })
                    .catch(error=>{
                        console.log(error)
                        self.isSpinner=false;
                    })

                    
                    // var actionCodeSettings = {
                    //     url: window.location.origin,
                    // };
                    // firebase
                    // .auth()
                    // .sendPasswordResetEmail(self.email, actionCodeSettings)
                    // .then(()=>{
                    //     self.$notify({
                    //         title:"Success",
                    //         text:'Email sent successfully.',
                    //         type:"success"
                    //     })
                    //     self.isSpinner=false;
                    //     self.$router.push({'name':'login'});
                    // })
                    // .catch(error=>{
                    //     self.$notify({
                    //         title:"Error",
                    //         text:error.message,
                    //         type:"error"
                    //     })
                    //     self.isSpinner=false;
                    // })
            //     }
            // })
            // .catch(error=>{
            //     self.isSpinner=false;
            //     this.$notify({
            //         title:"Error",
            //         text:error.message,
            //         type:"error"
            //     })
            // })
        },
// EMAIL IS CONVERT IN LOWERCASE
        IsfirstLetterCapital(){
            //email small case
            if(this.email!='')
            {
                this.email= this.email.toLowerCase()
            }
        },   
    }
}
</script>