export default { 
    // apiKey: "AIzaSyAQ2RvSvah1S1rGfjDjfMhRkvPljM0xP6I",
    // authDomain: "dooeyproject.firebaseapp.com",
    // projectId: "dooeyproject",
    // storageBucket: "dooeyproject.appspot.com",
    // messagingSenderId: "655124868444",
    // appId: "1:655124868444:web:d6256fafbefc843e632599",
    // measurementId: "G-Z1TX3STC45"

    
    // apiKey: "AIzaSyCs6Dd_Mr1nvAC7NAZ3080XZlP28R6G-fU",
    // authDomain: "dooeydev.firebaseapp.com",
    // projectId: "dooeydev",
    // storageBucket: "dooeydev.appspot.com",
    // messagingSenderId: "514925228711",
    // appId: "1:514925228711:web:ebebd78a1b4dd08f336182",
    // measurementId: "G-72BZ53EM94"


    // apiKey: "AIzaSyBPd2f-YW65gPHS21kFSSntfaZVDsITVGA",
    // authDomain: "dooeydevelopment.firebaseapp.com",
    // projectId: "dooeydevelopment",
    // storageBucket: "dooeydevelopment.appspot.com",
    // messagingSenderId: "731616716296",
    // appId: "1:731616716296:web:00dadfb559e3be463193bd",
    // measurementId: "G-NQ2CKRLCTY"
    
    apiKey: "AIzaSyDZ3q_EZT1dmB3rhtUOpOz24Z_pikp1ALw",
    authDomain: "dooey-c34e1.firebaseapp.com",
    projectId: "dooey-c34e1",
    storageBucket: "dooey-c34e1.appspot.com",
    messagingSenderId: "605703423686",
    appId: "1:605703423686:web:8a0c33a28c0bc61ddf79c7",
    measurementId: "G-2J3XX611M4"

    // for testing

        // apiKey: "AIzaSyDBdasP4lsoyMjFvGzSItBWRDuim_dFEiI",
        // authDomain: "doeeytesting.firebaseapp.com",
        // projectId: "doeeytesting",
        // storageBucket: "gs://doeeytesting.appspot.com",
        // messagingSenderId: "328475479619",
        // appId: "1:328475479619:web:ea282026215d258c748ef6",
        // measurementId: "G-N68TZJNZFW"

    // APK SIDE LOCAL DATABASE

    // apiKey: "AIzaSyCvrj6dAgnAaM4IFshctekbIxL05VWb0P4",
    // authDomain: "dooeydev-8dd44.firebaseapp.com",
    // projectId: "dooeydev-8dd44",
    // storageBucket: "dooeydev-8dd44.appspot.com",
    // messagingSenderId: "271164570905",
    // appId: "1:271164570905:web:259013c1eb780781435988",
    // measurementId: "G-VE65HVKY0S"

    // TESTING NEW DATABASE npm run Build
    // apiKey: "AIzaSyD1c0Df5YWYX_Gx2EEREUSmzb4qz40Rapw",
    // authDomain: "testingdooey.firebaseapp.com",
    // projectId: "testingdooey",
    // storageBucket: "testingdooey.appspot.com",
    // messagingSenderId: "332134988659",
    // appId: "1:332134988659:web:4f6d42e485cc3416062306",
    // measurementId: "G-K10D66FSLH"


    
}
