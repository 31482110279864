import firebase from "../views/firebaseInit";
import Vue from 'vue'
const db = firebase.firestore();
var user = localStorage.getItem("customerDocKey");
let businessFunctionCalled = false;

export const getAcseptUserData2 = function(USER,userid,companyId,cb){
    // debugger // eslint-disable-line
    // console.log(userid,"IN QRY")  
    // console.log(companyId,"companyId")  
    db.collection(USER).doc(userid).collection(companyId+'_businessContact').onSnapshot(querySnapshot => {
            var acseptUserData=[];
            querySnapshot.forEach (docdata => {
                // console.log(docdata,"?1")
                if(docdata.data().chatId != '' && docdata.data().chatId!= undefined){
                    if(docdata.data().block == undefined){
                        acseptUserData.push({
                            'docid':docdata.data().id,
                            'chatId':docdata.data().chatId,
                            'block':''
                        })
                    }else{
                        acseptUserData.push({
                            'docid':docdata.data().id,
                            'chatId':docdata.data().chatId,
                            'block':docdata.data().block
                        })
                    }
                   
                }
            })
            cb(acseptUserData)
    })
}
export const getActiveChat2 = function(COMAPNYID,SOCIALMESSAGE,socialMessage,cb)
{
    // var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).where("connectedUser", "array-contains", firebase.auth().currentUser.uid).orderBy('lastMsgtime','desc').onSnapshot(querySnapshot => {
        socialMessage = []
        querySnapshot.forEach (docdata => {
            if(docdata.data().id !== "" && docdata.data().lastMsgtime !== ""){
                var currentData  = 
                ((new Date().getHours() < 10
                    ?
                    '0'+new Date().getHours()
                    :
                    new Date().getHours())+':'+(new Date().getMinutes() < 10
                    ?
                    '0'+new Date().getMinutes()
                    :
                    new Date().getMinutes()))
                if(docdata.data().lastMsgtime){
                    var tdata =  
                    ((new Date(docdata.data().lastMsgtime.seconds * 1000).getHours() < 10
                    ?
                    '0'+new Date(docdata.data().lastMsgtime.seconds * 1000).getHours()
                    :
                    new Date(docdata.data().lastMsgtime.seconds * 1000).getHours())+':'+(new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes() < 10
                    ?
                    '0'+new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes()
                    :
                    new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes()))
                }
                socialMessage.push({
                    'blockBy':docdata.data().blockBy || '',
                    'connectedUser':docdata.data().connectedUser,
                    'msgid':docdata.data().id,
                    'lastmsg':docdata.data().updateChat,
                    'lastmsgTime':docdata.data().lastMsgtime,
                    'currentTime' : currentData,
                    // 'lastMsgArrivedTime' :docdata.data().lastMsgtime ? ((new Date(docdata.data().lastMsgtime.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().lastMsgtime.seconds * 1000).getHours() : new Date(docdata.data().lastMsgtime.seconds * 1000).getHours())+':'+(new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes() : new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes())) :''((new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours())+':'+(new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes())),
                    'lastMsgArrivedTime':tdata,
                    'lastMsgDate' :docdata.data().lastMsgtime?((new Date(docdata.data().lastMsgtime * 1000).getDate() < 10 ?'0'+ new Date(docdata.data().lastMsgtime * 1000).getDate():new Date(docdata.data().lastMsgtime * 1000).getDate())+'/'+((new Date(docdata.data().lastMsgtime * 1000).getMonth()+1) < 10 ? '0'+ (new Date(docdata.data().lastMsgtime * 1000).getMonth()+1):(new Date(docdata.data().lastMsgtime * 1000).getMonth()+1))) :'',
                    'title':docdata.data().title,
                    'isGroup':docdata.data().isGroup,
                    'groupProfile':docdata.data().profileUrl,
                    'firstName':'',
                    'lastName':'',
                    'fullname' : '',
                    'profileUrl':'',
                    'customer_id':'',
                    'messages':[],
                    'block':'',
                    'isOnline':'',
                    'docid':'',
                    'isDelete':'',
                    'isChannel':docdata.data().isChannel||false,
                    'isDeleted':docdata.data().isDeleted||false,
                    'adminId':docdata.data().adminId||"",
                    'mainBranches':docdata.data().mainBranches||[],
                    muteArray:docdata.data().muteArray||[],
                    messageSeenIds:docdata.data().messageSeenIds||[],
                    showMessageCount:true
                })
            } else {
                if(docdata.data().firstName === "" && docdata.data().title === "") {
                    // console.log("to be deleted",docdata.data());
                    // db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).doc(docdata.id).delete();
                }
            }
        })
    
    cb(socialMessage)    
    })
   
}

export const getCount2 = function(socialMessage,acseptUserData,activeChatListArray,user,cb)
{
    //  debugger // eslint-disable-line
    activeChatListArray = []
    let count = 0;
    const getrequestDataFunction = (row) => {
        if(count >= socialMessage.length){
            return;
        }
        const key = row.msgid;
        // var invIndex = acseptUserData.findIndex(item => {
        //     if(item.chatId != undefined){
        //         return item.chatId === key 
        //     }
        // })
        var invIndex = acseptUserData.findIndex(item => {
            // if(item.chatId != undefined){
            if(item.docid != undefined){
                return row.connectedUser.includes(item.docid);
                // return item.chatId === key 
            }
            
        })
        if(invIndex != -1){
            row.customer_id = acseptUserData[invIndex].docid
            row.block = acseptUserData[invIndex].block
        }
        activeChatListArray.push(socialMessage[count])
        count += 1;
        getrequestDataFunction(socialMessage[count]);
    }
    // for(var i=0;i<socialMessage.length;i++){
    //     var temp = socialMessage[i].connectedUser
    //     for(var j=0;j<temp.length;j++){
    //         if(temp[j] == user){
    //         activeChatListArray.push(socialMessage[i]);
    //         }
    //     }
    // }
    cb(activeChatListArray)   
    // console.log('self.activeChatListArray',self.activeChatListArray);
    getrequestDataFunction(socialMessage[count]);
               
}

export const getCounting2 = function(USER,getUserData,activeChatListArray,cb)
{
    db.collection(USER).get().then((querySnapshot) => {
        getUserData = []
        querySnapshot.forEach((doc) => {
          getUserData.push(doc.data());
        })
           
          let counting = 0;
          const getrequestDataFunction = (row) => {
              if(counting >= activeChatListArray.length){
                cb(activeChatListArray)
                return;
              }
              const key = row.customer_id;
              var invIndex = getUserData.findIndex(item => {
                  return item.id === key 
              })
              if(invIndex != -1){
                row.firstName = getUserData[invIndex].firstName
                row.lastName = getUserData[invIndex].lastName
                row.profileUrl = getUserData[invIndex].profileUrl
                row.isOnline = getUserData[invIndex].isOnline
                row.isDelete = getUserData[invIndex].isDeleted
                row.fullname = `${getUserData[invIndex].firstName} ${getUserData[invIndex].lastName}`
              }
            //   chatListArray = activeChatListArray
            //   console.log('>>>>>>>>>>>>',chatListArray);
              counting += 1;
              getrequestDataFunction(activeChatListArray[counting]);
          }
        //   console.log('>>self.socialMessage',self.activeChatListArray);
          getrequestDataFunction(activeChatListArray[counting]);
      })
               
}
export const getActiveMsgArray2 = function(COMAPNYID,SOCIALMESSAGE,chatlistArray,cb){
    // if(businessFunctionCalled) {
    //     cb(chatlistArray);
    //     return;
    // }

    businessFunctionCalled = true;
    let count = 0;
    var index = 0;
    let subbranch = []
    let subSubBranches = []
    let mainBranchArray = []

    const getrequestDataFunction = (row) => {
        if(count >= chatlistArray.length){
            cb(chatlistArray)
            return;
        }
        const key = row.msgid;
        if(key != ''){
                index++;

            if(row.mainBranches){
                mainBranchArray = row.mainBranches;
            }
            db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).doc(key).collection('default').get().then((querySnapshot) => {
                row.messages = [];
                querySnapshot.forEach((doc) => {
                    row.messages.push({
                        docid : doc.data().id,
                        msg : doc.data().msg,
                        name : doc.data().name,
                        type: doc.data().type,
                        mediaUrl :doc.data().mediaUrl,
                        firstName : doc.data().from.name,
                        createdAt:doc.data().createdAt?new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear() :'',
                        createDate:doc.data().createdAt?new Date(doc.data().createdAt.seconds * 1000) :'',
                        lastWeekStartDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                        lastWeekEndDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                        lastMonthDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                    })
                })
            })
            if(mainBranchArray.length>0){
                mainBranchArray.forEach(branchName => {
                    if(branchName.name === "" || branchName.name === undefined) {
                        return;
                    }
                    var found = false;
                    const branchname = branchName.name;
                    subbranch = branchName.subBranches
                    db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).doc(key).collection(branchname).get().then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            row.messages.forEach(match => {
                                if(match.docid == doc.data().id){
                                    found = true;
                                }
                            })
                            if(!found){
                                row.messages.push({
                                    docid : doc.data().id,
                                    msg : doc.data().msg,
                                    name : doc.data().name,
                                    type: doc.data().type,
                                    mediaUrl :doc.data().mediaUrl,
                                    firstName : doc.data().from.name,
                                    createdAt:doc.data().createdAt ? (new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear()) :'',
                                    createDate:doc.data().createdAt ? (new Date(doc.data().createdAt.seconds * 1000)) :'',
                                    lastWeekStartDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                                    lastWeekEndDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                                    lastMonthDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                                })
                            }
                        })
                    })
                    subbranch.forEach(subbranchname => {
                        if(subbranchname.name === "" || subbranchname.name === undefined) {
                            return;
                        }
                        const founds = false;
                        const subbranchName = subbranchname.name;
                        subSubBranches  = subbranchname.subSubBranches
                        db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).doc(key).collection(subbranchName).get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                row.messages.forEach(matchs => {
                                    if(matchs.docid == doc.data().id){
                                        founds = true;
                                    }
                                })
                                if(!founds){
                                    row.messages.push({
                                        docid : doc.data().id,
                                        msg : doc.data().msg,
                                        name : doc.data().name,
                                        type: doc.data().type,
                                        mediaUrl :doc.data().mediaUrl,
                                        firstName : doc.data().from.name,
                                        createdAt:doc.data().createdAt ? (new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear()) :'',
                                        createDate:doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) :'',
                                        lastWeekStartDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                                        lastWeekEndDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                                        lastMonthDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                                    })
                                }
                            })
                        })
                        subSubBranches.forEach(subsubbranchname => {
                            if(subsubbranchname.name === "" || subsubbranchname.name === undefined) {
                                return;
                            }
                            const foundss = false;
                            const subsubbranchName = subsubbranchname.name
                            db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).doc(key).collection(subsubbranchName).get().then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    row.messages.forEach(matchss => {
                                        if(matchss.docid == doc.data().id){
                                            foundss = true;
                                        }
                                    })
                                    if(!foundss){
                                        row.messages.push({
                                            docid : doc.data().id,
                                            msg : doc.data().msg,
                                            name : doc.data().name,
                                            type: doc.data().type,
                                            mediaUrl :doc.data().mediaUrl,
                                            firstName : doc.data().from.name,
                                            createdAt:doc.data().createdAt?(new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear()) :'',
                                            createDate:doc.data().createdAt?new Date(doc.data().createdAt.seconds * 1000) :'',
                                            lastWeekStartDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                                            lastWeekEndDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                                            lastMonthDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                                        })
                                    }
                                })
                            })
                        })
                    })
                })
            }
            count += 1;
            getrequestDataFunction(chatlistArray[count]);
        } else {
            count += 1;
            getrequestDataFunction(chatlistArray[count]);
        }
    }
    getrequestDataFunction(chatlistArray[count]);
}

export const Contactlist2 = function(USER,acseptUserData,cb)
{
    // debugger // eslint-disable-line
    // console.log('Vue.prototype.$companyName.id',Vue.prototype.$companyName.id)
    var user = localStorage.getItem("customerDocKey");
    if(Vue.prototype.$companyName){
        db.collection(USER).doc(user).collection(Vue.prototype.$companyName.id+'_businessContact').onSnapshot(querySnapshot => {
            acseptUserData = []
            querySnapshot.forEach ((docdata) => {
                acseptUserData.push({
                    'docid':docdata.data().id,
                    'chatid':docdata.data().chatId || '',
                    'id':'',
                    'firstName':'',
                    'lastName':'',
                    'profileUrl':'',
                    'messages':[],
                    'block': docdata.data().block || '',
                    'FullName':'',
                    'disable': false,
                    'companies': [],
                    'packageName':'',
                    'companyAdmin':'',
                    'online':false,
                    'isDeleted':false,
                })
            })
            cb(acseptUserData)   
        })
    }
}
export const getGroupListCount2 = function(userSearchList,getUserData,cb){
    setTimeout(() => {               
        let count = 0;
        const getrequestDataFunction = (row) => {
            if(count >= userSearchList.length){
                return;
            }
            const key = row.docid;
            var invIndex = getUserData.findIndex(item => {
                return item.id === key 
            })
            if(invIndex != -1){
                row.id = getUserData[invIndex].id;
                row.firstName = getUserData[invIndex].firstName ;
                row.lastName = getUserData[invIndex].lastName;
                row.profileUrl = getUserData[invIndex].profileUrl  ;
                row.FullName = getUserData[invIndex].fullname;
                row.online = getUserData[invIndex].online
                
            }
            // console.log('>>>>>>>>>>>>',userSearchList);


            count += 1;
            getrequestDataFunction(userSearchList[count]);
        }
        cb(userSearchList)
        getrequestDataFunction(userSearchList[count]);
    }, 1000);
}
export const getUnblockperson2 = function(unblockgroupList,cb){
    setTimeout(() => {  
        var userserchlist = []             
        let count = 0;
        const getrequestDataFunction = (row) => {
            if(count >= unblockgroupList.length){
                cb(userserchlist)
                return;
            }
            if(row.block != true){
                userserchlist.push(row)
                
            }

            count += 1;
            getrequestDataFunction(unblockgroupList[count]);
        }
        getrequestDataFunction(unblockgroupList[count]);
    }, 1000);
}
export const getContactCount2 = function(USER,getUserData,acseptUserData,cb)
{
        let count = 0;
        const getrequestDataFunction = (row) => {
            if(count >= acseptUserData.length){
                return;
            }
            const key = row.docid;
            db.collection(USER).doc(key).get().then(docs => {
                row.id = docs.data().id;
                row.firstName = docs.data().firstName ;
                row.lastName = docs.data().lastName;
                row.profileUrl = docs.data().profileUrl  ;
                row.companies = docs.data().companies || [] ;
                row.isDeleted = docs.data().isDeleted;
                row.online = docs.data().isOnline;
            })
            count += 1;
            getrequestDataFunction(acseptUserData[count]);
        }
        cb(acseptUserData)   
        getrequestDataFunction(acseptUserData[count]);
    // })
}
export const getplanName2 = function(COMPANYID,contectList,cb){
    setTimeout(()=>{
        contectList.forEach(data => {

            data.companies.forEach(docdata => {
                if(docdata.id == COMPANYID){
                    data.packageName = docdata.planName
                    data.companyAdmin = docdata.isAdmin
                }
            })
        })
        cb(contectList)
    },300)
}
// export const getDefaultCollection2 = function(COMAPNYID,SOCIALMESSAGE,contactListData,cb){
//     // debugger // eslint-disable-line
//     let count = 0;
//         const getrequestDataFunction = (row) => {
//             if(count >= contactListData.length){
//                 return;
//             }
//             const key = row.chatid;
//             if(key != ''){
//                 db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).doc(key).collection('default').onSnapshot((querySnapshot) => {
//                     querySnapshot.forEach((doc) => {
//                         row.messages.push({
//                             msg : doc.data().msg,
//                             name : doc.data().name,
//                             type: doc.data().type
//                         })
//                     })
//                 })
//             }
//             count += 1;
//             getrequestDataFunction(contactListData[count]);
//         }
//         cb(contactListData)   
//         getrequestDataFunction(contactListData[count]);
// }
// FOR OUTGOING REQUEST SEND
export const outgoingUserdata2 = function(COMAPNYID,SOCIALREQUEST,outgoingRequestArray,senderId,cb){
    db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALREQUEST).
    onSnapshot(querySnapshot=>{
        outgoingRequestArray = []
        querySnapshot.forEach(doc=>{
            if(doc.data().senderId == senderId && doc.data().status == 0){
                outgoingRequestArray.push({
                    id:doc.data().id,
                    receiverId:doc.data().receiverId,
                    senderId:doc.data().senderId,
                    status:doc.data().status,
                    firstName:'',
                    lastName:'',
                    profileUrl:''
                }) 
            }
        })
        cb(outgoingRequestArray)
    })
}
export const getUserData2 = function(USER,getUserData,cb){
    db.collection(USER).get().then(querySnapshot=>{
    getUserData = []
        querySnapshot.forEach(doc=>{
           getUserData.push({
                'id':doc.data().id,
                'firstName':doc.data().firstName,
                'lastName':doc.data().lastName,
                'profileUrl':doc.data().profileUrl,
                'fullname':(doc.data().firstName +' '+ doc.data().lastName)  
            })
        })
    cb(getUserData)
    })
}
export const getCountingFunction2 = function(outgoingRequestArray,getUserData,cb){
    setTimeout(() => {               
        let count = 0;
        const getInvFunction = (row) => {
            if(count >= outgoingRequestArray.length){
                return;
            }
            const key = row.receiverId;
            var invIndex = getUserData.findIndex(item => {
                return item.id === key 
            })
            if(invIndex != -1){
                row.firstName = getUserData[invIndex].firstName ;
                row.lastName = getUserData[invIndex].lastName;
                row.profileUrl = getUserData[invIndex].profileUrl  ;
                
            }
            // console.log('>>>>>>>>>>>>',outgoingRequestArray);


            count += 1;
            getInvFunction(outgoingRequestArray[count]);
        }
        cb(outgoingRequestArray);
        getInvFunction(outgoingRequestArray[count]);
    }, 1000);
}

// ADMIN SIDE DATA GET
export const getContactManagment2 = function(USER,totalArrayLength,customerListing,cb){
    db.collection(USER).
        orderBy('createdAt','asc').
        get().then(querySnapshot=>{
        customerListing = []
        totalArrayLength = querySnapshot.size
        for (let index = 0; index < totalArrayLength; index++) {
            if((totalArrayLength/10)<=index)
            {
                break;
            }
        }
        querySnapshot.forEach(doc=>{
            if(doc.data().isDeleted == undefined || !doc.data().isDeleted){
                if(!doc.data().isAdmin){
                    customerListing.push(doc.data())
                    // console.log('customerListing',customerListing)
                }
            }
        })
        cb(customerListing)
    })
}
export const getContactManagmentData2 = function(tableData,customerListing,cb){
    var index = 1
    var dataArray=[]
    tableData=[]
    customerListing.forEach(data=>{
    dataArray.push(data)
    if(index % 10 == 0)
    {
        tableData.push(dataArray)
        dataArray=[];
    }
    else if(index == customerListing.length)
    {
        tableData.push(dataArray)
        dataArray=[];
    }

    index++;

    })
    cb(tableData)
          
}

//FOR INCOMING REQUEST ARRAY
 export const incomingUserData2 = function(COMAPNYID,SOCIALREQUEST,IncomingRequestArray,senderId,cb){
    db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALREQUEST).
    onSnapshot(querySnapshot=>{
        IncomingRequestArray = []
        querySnapshot.forEach(doc=>{
            if(doc.data().receiverId == senderId && doc.data().status == 0){
                IncomingRequestArray.push({
                    id:doc.data().id,
                    receiverId:doc.data().receiverId,
                    senderId:doc.data().senderId,
                    status:doc.data().status,
                    firstName:'',
                    lastName:'',
                    profileUrl:''

                }) 
            }
        })
        cb(IncomingRequestArray)
    })
 }

 export const getIncomingrequestCounting2 = function(IncomingRequestArray,getUserData,cb){
    setTimeout(() => {               
        let count = 0;
        const getInvFunction = (row) => {
            if(count >= IncomingRequestArray.length){
                return;
            }
            const key = row.senderId;
            var invIndex = getUserData.findIndex(item => {
                return item.id === key 
            })
            if(invIndex != -1){
                row.firstName = getUserData[invIndex].firstName ;
                row.lastName = getUserData[invIndex].lastName;
                row.profileUrl = getUserData[invIndex].profileUrl  ;
                
            }
            // console.log('>>>>>>>>>>>>',IncomingRequestArray);


            count += 1;
            getInvFunction(IncomingRequestArray[count]);
        }
        cb(IncomingRequestArray)
        getInvFunction(IncomingRequestArray[count]);
    }, 1000);
 }


 // FOR GET OLD AND  NEW MEMBER
 export const  getnewmember2 = function(USER,NewGroupMemberId,NewGroupMember,loginCustomeId,cb){
    // setTimeout(() => { 
        NewGroupMember = []              
        let count = 0;
        const getInvFunction = (row) => {
            if(count >= NewGroupMemberId.length){
                cb(NewGroupMember)
                return;
            }
            const key = row;
            if(key != loginCustomeId && Vue.prototype.$companyName){
                db.collection(USER).doc(key).get().then(docdata => {
                   
                        if(docdata.data().email != Vue.prototype.$email){
                            db.collection(USER).doc(firebase.auth().currentUser.uid).collection(Vue.prototype.$companyName.id+'_businessContact').doc(key).get().then(doc=>{
                                if(doc.exists){
                                    if(!doc.data().block || doc.data().block == undefined )
                                    {
                                        NewGroupMember.push({
                                            'firstName' : docdata.data().firstName,
                                            'lastName' : docdata.data().lastName,
                                            'profileUrl' : docdata.data().profileUrl,
                                            'id' : docdata.data().id,
                                        })
                                    }
                                }
                            })
                        }
                   
                }) 
            }


            count += 1;
            getInvFunction(NewGroupMemberId[count]);
        }
        // cb(NewGroupMember)
        getInvFunction(NewGroupMemberId[count]);
    // }, 1000);
 }

 export const  getoldmember2 = function(USER,NewGroupMemberId,NewGroupMember,loginCustomeId,cb){
    // setTimeout(() => { 
        NewGroupMember = []              
        let count = 0;
        const getInvFunction = (row) => {
            if(count >= NewGroupMemberId.length){
                cb(NewGroupMember)
                return;
            }
            const key = row;
            if(key != loginCustomeId){
                db.collection(USER).doc(key).get().then(docdata => {
                   
                        // if(docdata.data().id == key){
                            NewGroupMember.push({
                                'firstName' : docdata.data().firstName,
                                'lastName' : docdata.data().lastName,
                                'profileUrl' : docdata.data().profileUrl,
                                'id' : docdata.data().id,
                            })
                        // }
                    
                }) 
            }


            count += 1;
            getInvFunction(NewGroupMemberId[count]);
        }
        // cb(NewGroupMember)
        getInvFunction(NewGroupMemberId[count]);
    // }, 1000);
 }

export const getSocialContactData2 = function(USER,senderId,cb){
    // debugger // eslint-disable-line
    db.collection(USER).doc(senderId).collection(Vue.prototype.$companyName.id+'_businessContact').onSnapshot((querySnapshot) => {
        var contactList = []
        querySnapshot.forEach(doc => {
            if(doc.exists){
                if(doc.data().isDeleted == false || doc.data().isDeleted == undefined){
                    db.collection(USER).doc(doc.id).get().then(snap => {
                        contactList.push(snap.data().email) 
                    })
                }
            }
        })
        cb(contactList)
    })
      
}
export const getSocialphonenumberData2 = function(USER,senderId,cb){

    db.collection(USER).doc(senderId).collection(Vue.prototype.$companyName.id+'_businessContact').onSnapshot((querySnapshot) => {
        var contactList = []
        querySnapshot.forEach(doc => {
           if(doc.exists){
            db.collection(USER).doc(doc.id).get().then(snap => {
                contactList.push(snap.data().phoneNo) 
            })
           }
        })
       
        cb(contactList)
      })
      
}
export const selectPollOption2 = function(COMAPNYID,SOCIALMESSAGE,defaultcollection,chatId,defaultid,cb){
    db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).doc(chatId).collection(defaultcollection).doc(defaultid).get().then((doc)=>{
        cb(doc.data())
    })
}
export const updatePollAnswer2 = function(COMAPNYID,SOCIALMESSAGE,defaultcollection,chatId,defaultid,uploadData,pollquestions,cb){
    db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).doc(chatId).collection(defaultcollection).doc(defaultid).update({
        uploadPollData: uploadData,
        questions:pollquestions
    })
    cb('update')
}
export const updateAnswerCollection2 = function(COMAPNYID,SOCIALMESSAGE,defaultcollection,chatId,defaultid,userid,option,cb){
    db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).doc(chatId).collection(defaultcollection).doc(defaultid).collection('answer').doc(userid).get().then(doc =>{
        if(!doc.exists){
            db.collection(COMAPNYID).doc(COMAPNYID).collection(SOCIALMESSAGE).doc(chatId).collection(defaultcollection).doc(defaultid).collection('answer').doc(userid).set({
                ans:option,
                chatId:defaultid
            })
            cb('updateAnswerCollection')
        }
    })
}


export const checkIfRequestIsAlreadySend2 = function(COMAPNYID,cb){
    // debugger // eslint-disable-line
   var index = 0;
    db.collection('companies').doc(COMAPNYID).get().then(doc=>{
       var socialRequestArray = []
       if(doc.data().users.length > 0){
            var users = doc.data().users 
            users.forEach(data => {
                socialRequestArray.push(data.email)
                index++; 
            }) 
        }
        if(doc.data().users.length == index){
            cb(socialRequestArray)
        }
        
    })
}

export const getMessageSeenCount2 = function(docId,collectionName,uid,cb)
{
    db
        .collection('businessMessages')
        .doc(docId)
        .collection(collectionName)
        .get().then(querySnapshot=>{
            if(!querySnapshot.empty)
            {
                var count = 0
                querySnapshot.forEach(doc=>{
                    var found = false
                    doc.data().messageSeen.forEach(ids=>{
                        if(ids.id==uid)
                        {
                            found = true
                        }
                    })
                    if(!found)
                    {
                        count++
                    }
                })

                cb(count)
            }
        })
        .catch(error=>{
        console.log(error)
        })
}
export const Invitation2 = function(companyId,businessinvite,cb){
   var inViteUserId = []
    db.collection(companyId).doc(companyId).collection(businessinvite).onSnapshot((querySnapshot) => {
        querySnapshot.forEach(doc=>{
            inViteUserId.push(doc.data())  
        })
        cb(inViteUserId)
    })
}

export const getCompanies2 = function(userid,cb)
{
    var data = []
    db.collection('users').doc(userid)
    .onSnapshot(doc=>{
       if(doc.exists)
        {
            data=doc.data().companies
            // console.log('test',data)
            cb(data)
        }
    })
}
export const getComapnyFromId2 = function(COMPANY,companyid,cb){
    var planLists = []
    db.collection(COMPANY).doc(companyid).get().then(doc => {
        var planList = doc.data().plan
        planList.forEach(data=>{
            if(data.plan_status != 'deactivated'){
                planLists.push(data); 
            }
        })
        
        cb(planLists)
    })
}
export const CompanyInfo = function(COMPANY,companyid,cb){
    var planLists = {}
    db.collection(COMPANY).doc(companyid).get().then(doc => {
        planLists = doc.data();
        cb(planLists)
    })

}
export const getFreePackageId2 = function(COMPANY,companyid,cb){
    var planList = {}
    var planArray = []
    db.collection(COMPANY).doc(companyid).get().then(doc => {
        // console.log('doc.data().plan',doc.data())
        planArray = doc.data().plan
        planArray.forEach(data => {
            if(data.PackageType == 'Free'){
                planList = data
                
            }
        })
       
            cb(planList)
       
    }) 
    
}
export const getFreePlanObject2 = function(COMPANY,companyid,cb){
    var freeplanobject = {}
    db.collection(COMPANY).get().then(querySnapshot => {
        var index = querySnapshot.size
        freeplanobject = {}
        querySnapshot.forEach(doc => {
            if(doc.data().id == companyid){
                var plans = doc.data().plan
                plans.forEach(docData => {
                    if(docData.payment_status == 'free' && docData.plan_type == 'business'){
                        freeplanobject = docData
                    }
                })
                index--
                // console.log(index,querySnapshot.size)
                if(index==0)
                {
                    // console.log('called')
                    cb(freeplanobject)
                }
            }
            else
            {
                index--
                // console.log(index,querySnapshot.size)
                if(index==0)
                {
                    // console.log('called')
                    cb(freeplanobject)
                }
            }
        })
    })

}
export const getUsersData2 = function(USER,emailAddress,cb){
    var index = 1
    var receiverData = {}
    db.collection(USER)
        .get()
        .then(querySnapshot=>{
            receiverData = {}
            querySnapshot.forEach(doc=>{
                if(doc.data().email == emailAddress && (doc.data().deactivedate === undefined || doc.data().deactivedate === "")){
                    receiverData = doc.data()
                }
                if(index == querySnapshot.size){
                    cb(receiverData)
                }
                index++
            })
        
    })
}
export const planChange2 = function(CompanyUserData,cb){
    debugger // eslint-disable-line
    for(var j=0; j<CompanyUserData.length;j++){
        // if(todayDatesplit > dateexpired.dateobject && todayMonthsplit >= dateexpired.monthobject && todayYearsplit >= dateexpired.yearobject)

        if(CompanyUserData[j].planId != CompanyUserData[j].selectplanOption.plan_id && CompanyUserData[j].selectplanOption != '' && CompanyUserData[j].selectplanOption.plan_id != undefined){
            // if(CompanyUserData[j].switchobject == undefined || CompanyUserData[j].switchobject == ''){
            //     CompanyUserData[j].switchobject = {
            //         planName:CompanyUserData[j].selectplanOption.plan_name,
            //         planId:CompanyUserData[j].selectplanOption.plan_id,
            //         next_billing:CompanyUserData[j].next_billing_date,
            //         planNextBilling : CompanyUserData[j].selectplanOption.nextbillingOriginaltime,
            //         max_file_size:CompanyUserData[j].selectplanOption.max_file_size,
            //         noOfSelfie:CompanyUserData[j].selectplanOption.noOfSelfie,
            //         userUsageLimit:CompanyUserData[j].selectplanOption.userUsageLimit,
            //     }
            // }
            CompanyUserData[j].planId = CompanyUserData[j].selectplanOption.plan_id
            CompanyUserData[j].planName = CompanyUserData[j].selectplanOption.plan_name
            // CompanyUserData[j].dataUsage = 0
            // CompanyUserData[j].selfieTime = new Date()
            CompanyUserData[j].max_file_size = CompanyUserData[j].selectplanOption.max_file_size
            CompanyUserData[j].noOfSelfie = CompanyUserData[j].selectplanOption.noOfSelfie
            CompanyUserData[j].userUsageLimit = CompanyUserData[j].selectplanOption.userUsageLimit
            CompanyUserData[j].messages_history = CompanyUserData[j].selectplanOption.messages_history
            CompanyUserData[j].device_plan = CompanyUserData[j].selectplanOption.device_plan
        }
    }
    
    // console.log('wrefefcd')
    cb(CompanyUserData)
}
export const updateusercollection2 = function(res,USER,cpmpanyid,cb){
    debugger // eslint-disable-line
    var company = []
    // console.log('res',res)
    var index = 1
    res.forEach(data=>{
        if(data.id != '' && data.id != undefined){
            db.collection(USER).doc(data.id).get().then(doc=>{
                company = doc.data().companies || []

                company.forEach(data2 => {
                    if(data2.id == cpmpanyid.id){
                        // if(data2.switchobject == undefined  || data2.switchobject == ''){
                        //     data2.switchobject =  data.switchobject
                        // }else{
                        data2.planId = data.planId,
                        data2.planName = data.planName
                        // }
                        
                        // data2.planId = data.planId,
                        // data2.planName = data.planName
                    }
                })
                // console.log('Data',company)
            
                db.collection(USER).doc(data.id).update({
                    companies : company
                })
                .then(() => {
                    if(index == res.length)
                    {
                        cb('True')
                    }
                    index++
                })
                .catch(error=>{
                    console.log(error)
                })
            })
            .catch(error=>{
                console.log(error)
            })
        }
        else
        {
            if(index == res.length)
            {
                cb('True')
            }
            index++
        }
    })
       
   
}

