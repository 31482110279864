import firebase from "../views/firebaseInit";
const db = firebase.firestore();
var user = localStorage.getItem("customerDocKey");
let socialFunctionCalled = false;
import Vue from "vue";
export const getAcseptUserData = function(USER,user,cb){
    db.collection(USER).doc(user).collection('socialContact').onSnapshot(querySnapshot => {
    // db.collection(USER).doc(user).collection('socialContact').get().then(querySnapshot => {
        var acseptUserData=[];
        querySnapshot.forEach (docdata => {
            if(docdata.data().chatId != ''){
                if(docdata.data().block == undefined){
                    acseptUserData.push({
                        'docid':docdata.data().id,
                        'chatId':docdata.data().chatId,
                        'block':''
                    })
                }else{
                    acseptUserData.push({
                        'docid':docdata.data().id,
                        'chatId':docdata.data().chatId,
                        'block':docdata.data().block || ''
                    })
                }
            }
        })
        cb(acseptUserData)
    })
}
export const getActiveChat = function(SOCIALMESSAGE,socialMessage,cb)
{
    // var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    db.collection(SOCIALMESSAGE).where("connectedUser", "array-contains", firebase.auth().currentUser.uid).orderBy('lastMsgtime','desc').onSnapshot(querySnapshot => {
    // db.collection(SOCIALMESSAGE).where("connectedUser", "array-contains", firebase.auth().currentUser.uid).orderBy('lastMsgtime','desc').get().then(querySnapshot => {
        socialMessage = []
        querySnapshot.forEach (docdata => {
            if(docdata.data().id && docdata.data().lastMsgtime !== ""){
                var currentData  = 
                ((new Date().getHours() < 10
                    ?
                    '0'+new Date().getHours()
                    :
                    new Date().getHours())+':'+(new Date().getMinutes() < 10
                    ?
                    '0'+new Date().getMinutes()
                    :
                    new Date().getMinutes()))
                if(docdata.data().lastMsgtime){
                    var tdata =  
                    ((new Date(docdata.data().lastMsgtime.seconds * 1000).getHours() < 10
                    ?
                    '0'+new Date(docdata.data().lastMsgtime.seconds * 1000).getHours()
                    :
                    new Date(docdata.data().lastMsgtime.seconds * 1000).getHours())+':'+(new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes() < 10
                    ?
                    '0'+new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes()
                    :
                    new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes()))
                }
                // console.log('tdata',tdata)
                socialMessage.push({
                    'blockBy':docdata.data().blockBy || '',
                    'connectedUser':docdata.data().connectedUser,
                    'msgid':docdata.data().id,
                    'lastmsg':docdata.data().updateChat,
                    'lastmsgTime':docdata.data().lastMsgtime,
                    // 'lastMsgArrivedTime' : docdata.data().lastMsgtime ? ((new Date(docdata.data().lastMsgtime.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().lastMsgtime.seconds * 1000).getHours() : new Date(docdata.data().lastMsgtime.seconds * 1000).getHours())+':'+(new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes() : new Date(docdata.data().lastMsgtime.seconds * 1000).getMinutes())):''(new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours()+':'+new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes()),
                    'lastMsgArrivedTime' : tdata ,
                    'currentTime' : currentData,
                    'lastMsgDate' :docdata.data().lastMsgtime ? ((new Date(docdata.data().lastMsgtime * 1000).getDate() < 10 ?'0'+ new Date(docdata.data().lastMsgtime * 1000).getDate():new Date(docdata.data().lastMsgtime * 1000).getDate())+'/'+((new Date(docdata.data().lastMsgtime * 1000).getMonth()+1) < 10 ? '0'+ (new Date(docdata.data().lastMsgtime * 1000).getMonth()+1):(new Date(docdata.data().lastMsgtime * 1000).getMonth()+1))):'',
                    'title':docdata.data().title,
                    'isGroup':docdata.data().isGroup,
                    'groupProfile':docdata.data().profileUrl,
                    'firstName':'',
                    'lastName':'',  
                    'fullname':'',
                    'profileUrl':'',
                    'customer_id':'',
                    'messages':[],
                    'block':'',
                    'isOnline':'',
                    'docid':'',
                    'isDelete' : '',
                    'isDeleted':docdata.data().isDeleted||false,
                    'adminId':docdata.data().adminId||"",
                    'mainBranches':docdata.data().mainBranches||[],
                    muteArray:docdata.data().muteArray||[],
                    messageSeenIds:docdata.data().messageSeenIds||[],
                    showMessageCount:true
                })
            } else {
                if(docdata.data().firstName === "" && docdata.data().title === "") {
                    // console.log("to be deleted",docdata.data());
                    // db..collection(SOCIALMESSAGE).doc(docdata.id).delete();
                }
            }
        })
    
    cb(socialMessage)    
    })
   
}

export const getCount = function(socialMessage,acseptUserData,activeChatListArray,user,cb)
{
    //  //debugger // eslint-disable-line
    activeChatListArray = []
    let count = 0;
    const getrequestDataFunction = (row) => {
        if(count >= socialMessage.length){
            cb(activeChatListArray)   
            return;
        }
        // const key = row.msgid;
        var invIndex = acseptUserData.findIndex(item => {
            // if(item.chatId != undefined){
            if(item.docid != undefined){
                return row.connectedUser.includes(item.docid);
                // return item.chatId === key 
            }
            
        })
        if(invIndex != -1){
            row.docid = acseptUserData[invIndex].docid
            row.customer_id = acseptUserData[invIndex].docid
            row.block = acseptUserData[invIndex].block
        }
        activeChatListArray.push(socialMessage[count])
        count += 1;
        getrequestDataFunction(socialMessage[count]);
    }
    getrequestDataFunction(socialMessage[count]);
}

export const getCounting = function(USER,getUserData,activeChatListArray,cb)
{
    // db.collection(USER).onSnapshot((querySnapshot) => {
    db.collection(USER).get().then((querySnapshot) => {
        getUserData = []
        querySnapshot.forEach((doc) => {
          getUserData.push(doc.data());

            if(doc.data().isAdmin)
            {
                Vue.prototype.$adminDetails=doc.data()
            }
        })
          let counting = 0;
          const getrequestDataFunction = (row) => {
              if(counting >= activeChatListArray.length){
                cb(activeChatListArray)  
                  return;
              }
              const key = row.customer_id;
              var invIndex = getUserData.findIndex(item => {
                  return item.id === key;
              })
              if(invIndex != -1){
                row.firstName = getUserData[invIndex].firstName
                row.lastName = getUserData[invIndex].lastName
                row.profileUrl = getUserData[invIndex].profileUrl
                row.isOnline = getUserData[invIndex].isOnline
                row.isDelete = getUserData[invIndex].isDeleted
                row.fullname = `${getUserData[invIndex].firstName} ${getUserData[invIndex].lastName}`
              }
            //   chatListArray = activeChatListArray
              //console.log('>>>>>>>>>>>>',chatListArray);
              counting += 1;
              getrequestDataFunction(activeChatListArray[counting]);
          }
          //console.log('>>self.socialMessage',self.activeChatListArray);
           
          getrequestDataFunction(activeChatListArray[counting]);
    })
               
}
export const getActiveMsgArray = function(SOCIALMESSAGE,chatlistArray,cb){
    //  debugger // eslint-disable-line

    // if(socialFunctionCalled) {
    //     cb(chatlistArray);
    //     return;
    // }
    socialFunctionCalled = true

    let count = 0;
    let subbranch = []
    let subSubBranches = []
    let mainBranchArray = []
        const getrequestDataFunction = (row) => {
            if(count >= chatlistArray.length){
                cb(chatlistArray)   
                return;
            }
            const key = row.msgid;
            if(key != '' && key != undefined){
                mainBranchArray = row.mainBranches;

                // db.collection(SOCIALMESSAGE).doc(key).collection('default').onSnapshot((querySnapshot) => {
                db.collection(SOCIALMESSAGE).doc(key).collection('default').get().then((querySnapshot) => {
                    row.messages = [];
                    querySnapshot.forEach((doc) => {
                        row.messages.push({
                            docid:doc.data().id,
                            msg : doc.data().msg,
                            name : doc.data().name,
                            type: doc.data().type,
                            mediaUrl :doc.data().mediaUrl,
                            firstName : doc.data().from.name,
                            createdAt:doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ (new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear() : '',
                            createDate:doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) : '',
                            lastWeekStartDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                            lastWeekEndDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                            lastMonthDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                        })
                    })
                })
                count += 1;
                getrequestDataFunction(chatlistArray[count]);
                mainBranchArray.forEach(branchName => {
                    var found = false;
                    const branchname = branchName.name;
                    subbranch = branchName.subBranches

                    // db.collection(SOCIALMESSAGE).doc(key).collection(branchname).onSnapshot((querySnapshot) => {
                    db.collection(SOCIALMESSAGE).doc(key).collection(branchname).get().then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {

                            row.messages.forEach(match => {
                                if(match.docid == doc.data().id){
                                    found = true;
                                }
                            })
                            if(!found){
                                row.messages.push({
                                    docid : doc.data().id,
                                    msg : doc.data().msg,
                                    name : doc.data().name,
                                    type: doc.data().type,
                                    mediaUrl :doc.data().mediaUrl,
                                    firstName : doc.data().from.name,
                                    createdAt:doc.data().createdAt ? (new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear()) : '',
                                    createDate:doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) : '',
                                    lastWeekStartDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                                    lastWeekEndDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                                    lastMonthDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                                })
                            }
                        })
                    })
                    if(subbranch){
                        subbranch.forEach(subbranchname => {
                            var founds = false;
                            var subbranchName = subbranchname.name;
                            subSubBranches  = subbranchname.subSubBranches
                            // db.collection(SOCIALMESSAGE).doc(key).collection(subbranchName).onSnapshot((querySnapshot) => {
                            db.collection(SOCIALMESSAGE).doc(key).collection(subbranchName).get().then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    row.messages.forEach(matchs => {
                                        if(matchs.docid == doc.data().id){
                                            founds = true;
                                        }
                                    })
                                    if(!founds){
                                        row.messages.push({
                                            docid : doc.data().id,
                                            msg : doc.data().msg,
                                            name : doc.data().name,
                                            type: doc.data().type,
                                            mediaUrl :doc.data().mediaUrl,
                                            firstName : doc.data().from.name,
                                            createdAt:doc.data().createdAt ? (new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear()) : '',
                                            createDate:doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) : '',
                                            lastWeekStartDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                                            lastWeekEndDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                                            lastMonthDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                                        })
                                    }
                                })
                            })
                            if(subSubBranches){
                                subSubBranches.forEach(subsubbranchname => {
                                    const foundss = false;
                                    const subsubbranchName = subsubbranchname.name
                                    // db.collection(SOCIALMESSAGE).doc(key).collection(subsubbranchName).onSnapshot((querySnapshot) => {
                                    db.collection(SOCIALMESSAGE).doc(key).collection(subsubbranchName).get().then((querySnapshot) => {
                                        querySnapshot.forEach((doc) => {
                                            row.messages.forEach(matchss => {
                                                if(matchss.docid == doc.data().id){
                                                    foundss = true;
                                                }
                                            })
                                            if(!foundss){
                                                row.messages.push({
                                                    docid : doc.data().id,
                                                    msg : doc.data().msg,
                                                    name : doc.data().name,
                                                    type: doc.data().type,
                                                    mediaUrl :doc.data().mediaUrl,
                                                    firstName : doc.data().from.name,
                                                    createdAt:doc.data().createdAt ? (new Date(doc.data().createdAt.seconds * 1000).getDate()+'/'+ [(new Date(doc.data().createdAt.seconds * 1000).getMonth()) + 1]+'/'+ new Date(doc.data().createdAt.seconds * 1000).getFullYear()) : '',
                                                    createDate:doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) : '',
                                                    lastWeekStartDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()-6),
                                                    lastWeekEndDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-new Date().getDay()),
                                                    lastMonthDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-30),
                                                })
                                            }
                                        })
                                    })
                                })
                            }
                        })
                    }
                })
            } else {
                count += 1;
                getrequestDataFunction(chatlistArray[count]);
            }
        }
       
        getrequestDataFunction(chatlistArray[count]);
}
export const Contactlist = function(USER,acseptUserData,cb)
{
    // debugger // eslint-disable-line
    var user = localStorage.getItem("customerDocKey");
    var index = 1
    db.collection(USER).doc(user).collection('socialContact').onSnapshot(querySnapshot => {
        acseptUserData = []
        querySnapshot.forEach ((docdata) => {
            acseptUserData.push({
                'docid':docdata.data().id,
                'chatid':docdata.data().chatId || '',
                'id':docdata.data().id,
                'firstName':'',
                'lastName':'',
                'profileUrl':'',
                'messages':[],
                'block': docdata.data().block || '',
                'messageSeenIds':[],
                'FullName':'',
                'disable': false,
                'online':false
            })
        })
        // console.log('acseptUserData',acseptUserData)
        let count = 0;
        const getrequestDataFunction = (row) => {
            if(count >= acseptUserData.length){
                cb(acseptUserData)
                return;
            }
            const key = row.chatid;
            if(key != ''){
                db.collection('socialMessages').doc(key).get().then(doc=>{
                    if(doc.exists){
                        row.messageSeenIds = doc.data().messageSeenIds || []
                    }
                })
            }
            // console.log('acseptUserDataNEWWWWWWW',acseptUserData)
            count += 1;
            getrequestDataFunction(acseptUserData[count]);
        }
        getrequestDataFunction(acseptUserData[count]);
    })
}
export const getGroupListCount = function(userSearchList,getUserData,cb){
    // setTimeout(() => {               
        let count = 0;
        const getrequestDataFunction = (row) => {
            if(count >= userSearchList.length){
                cb(userSearchList)
                return;
            }
            const key = row.docid;
            var invIndex = getUserData.findIndex(item => {
                return item.id === key 
            })
            if(invIndex != -1){
                row.id = getUserData[invIndex].id;
                row.firstName = getUserData[invIndex].firstName ;
                row.lastName = getUserData[invIndex].lastName;
                row.profileUrl = getUserData[invIndex].profileUrl  ;
                row.FullName = getUserData[invIndex].fullname
                
            }
            //console.log('>>>>>>>>>>>>',userSearchList);


            count += 1;
            getrequestDataFunction(userSearchList[count]);
        }
        getrequestDataFunction(userSearchList[count]);
    // }, 1000);
}
export const getUnblockperson = function(unblockgroupList,cb){
    // setTimeout(() => {  
        var userserchlist = []             
        let count = 0;
        const getrequestDataFunction = (row) => {
            if(count >= unblockgroupList.length){
                cb(userserchlist)
                return;
            }
            if(row.block != true){
                userserchlist.push(row)
                
            }

            count += 1;
            getrequestDataFunction(unblockgroupList[count]);
        }
        
        getrequestDataFunction(unblockgroupList[count]);
    // }, 1000);
}
// export const getContactCount = function(USER,getUserData,acseptUserData,cb)
// {
//     // debugger // eslint-disable-line
//     db.collection(USER).onSnapshot((querySnapshot) => {
//         getUserData = []
//         querySnapshot.forEach((doc) => {
//             getUserData.push({
//                 'id':doc.data().id,
//                 'firstName':doc.data().firstName,
//                 'lastName':doc.data().lastName,
//                 'profileUrl':doc.data().profileUrl,
//                 'isDeleted':doc.data().isDeleted,
//                 'FullName' :`${doc.data().firstName} ${doc.data().lastName}`,
//                 'online':doc.data().isOnline
//             })
//         })
//     let count = 0;
//         const getrequestDataFunction = (row) => {
//             if(count >= acseptUserData.length){
//                 cb(acseptUserData)
//                 return;
//             }
//             const key = row.docid;
//             var invIndex = getUserData.findIndex(item => {
//                 return item.id === key 
//             })
//             if(invIndex != -1){
//                 row.id = getUserData[invIndex].id;
//                 row.firstName = getUserData[invIndex].firstName ;
//                 row.lastName = getUserData[invIndex].lastName;
//                 row.profileUrl = getUserData[invIndex].profileUrl  ;
//                 row.isDeleted = getUserData[invIndex].isDeleted;
//                 row.FullName = getUserData[invIndex].FullName;
//                 row.online = getUserData[invIndex].online
//             }
//             count += 1;
//             getrequestDataFunction(acseptUserData[count]);
//         }
//         getrequestDataFunction(acseptUserData[count]);
//     })
// }
export const getContactCount = function(USER,getUserData,acseptUserData,cb)
{
    // debugger // eslint-disable-line
    let count = 0;
        const getrequestDataFunction = (row) => {
            if(count >= acseptUserData.length){
                cb(acseptUserData)
                return;
            }
            if(row.docid){
                const key = row.docid;
                db.collection(USER).doc(key).get().then(docs => {
                    if(docs.exists && docs.data().firstName!==''){
                    // row.id = docs.data().id;
                        row.firstName = docs.data().firstName || "";
                        row.lastName = docs.data().lastName || "";
                        row.profileUrl = docs.data().profileUrl;
                        row.isDeleted = docs.data().isDeleted;
                        row.FullName = `${docs.data().firstName} ${docs.data().lastName}`;
                        row.online = docs.data().isOnline
                    }
                })
            }
            count += 1;
            getrequestDataFunction(acseptUserData[count]);
        }
        getrequestDataFunction(acseptUserData[count]);
    // })
}
// export const getDefaultCollection = function(SOCIALMESSAGE,contactListData,cb){
//     // debugger // eslint-disable-line
//     let count = 0;
//         const getrequestDataFunction = (row) => {
//             if(count >= contactListData.length){
//                 cb(contactListData)
//                 return;
//             }
//             const key = row.chatid;
//             if(key != ''){
//                 db.collection(SOCIALMESSAGE).doc(key).collection('default').onSnapshot((querySnapshot) => {
//                     querySnapshot.forEach((doc) => {
//                         row.messages.push({
//                             msg : doc.data().msg,
//                             name : doc.data().name,
//                             type: doc.data().type
//                         })
//                     })
//                 })
//             }
         
//             count += 1;
//             getrequestDataFunction(contactListData[count]);
//         }   
//         getrequestDataFunction(contactListData[count]);
// }
// FOR OUTGOING REQUEST SEND
export const outgoingUserdata = function(SOCIALREQUEST,outgoingRequestArray,senderId,cb){
    
    db.collection(SOCIALREQUEST).
    onSnapshot(querySnapshot=>{
        outgoingRequestArray = []
        querySnapshot.forEach(doc=>{
            if(doc.data().senderId == senderId && doc.data().status == 0){
               outgoingRequestArray.push({
                    id:doc.data().id,
                    receiverId:doc.data().receiverId,
                    senderId:doc.data().senderId,
                    status:doc.data().status,
                    firstName:'',
                    lastName:'',
                    profileUrl:''
                }) 
            }
        })
        cb(outgoingRequestArray)
    })
}
export const getUserData = function(USER,getUserData,cb){
    db.collection(USER).
    onSnapshot(querySnapshot=>{
    getUserData = []
        querySnapshot.forEach(doc=>{
            getUserData.push({
                'id':doc.data().id,
                'firstName':doc.data().firstName,
                'lastName':doc.data().lastName,
                'profileUrl':doc.data().profileUrl,
                'fullname':(doc.data().firstName +' '+ doc.data().lastName)  
            })
        })
    cb(getUserData)
    })
}
export const getCountingFunction = function(outgoingRequestArray,getUserData,cb){
    // setTimeout(() => {               
        let count = 0;
        const getInvFunction = (row) => {
            if(count >= outgoingRequestArray.length){
                cb(outgoingRequestArray);
                return;
            }
            const key = row.receiverId;
            var invIndex = getUserData.findIndex(item => {
                return item.id === key 
            })
            if(invIndex != -1){
                row.firstName = getUserData[invIndex].firstName ;
                row.lastName = getUserData[invIndex].lastName;
                row.profileUrl = getUserData[invIndex].profileUrl  ;
                
            }
            //console.log('>>>>>>>>>>>>',outgoingRequestArray);


            count += 1;
            getInvFunction(outgoingRequestArray[count]);
        }
        
        getInvFunction(outgoingRequestArray[count]);
    // }, 1000);
}

// ADMIN SIDE DATA GET
export const getContactManagment = function(USER,totalArrayLength,customerListing,cb){
    // debugger // eslint-disable-line
    var BusinessPlan = [];
    var businessPlan = [];
    var companies = [];
    var count = 0;
    var newArrayForNewMember = [];
    db.collection(USER).orderBy('createdAt','desc').onSnapshot(querySnapshot=>{
        BusinessPlan = [];
        businessPlan = []
        companies = []
        newArrayForNewMember = [];
        count = 0;
        totalArrayLength = querySnapshot.size
        for (let index = 0; index < totalArrayLength; index++) {
            if((totalArrayLength/10)<=index)
            {
               break;
            }
        }
        querySnapshot.forEach(doc=>{
           
            if(newArrayForNewMember.length == 0){
                newArrayForNewMember.push(doc.data());
                // count++
               
            }else{
                var found = false;
                newArrayForNewMember.forEach(userData => {
                    if(userData.email == doc.data().email || userData.phoneNo == doc.data().phoneNo){
                        found = true;
                    }
                })
                if(!found){
                    newArrayForNewMember.push(doc.data());
                    // count++ 
                }
            }
           
        })
        // console.log('businessPlanbusinessPlan',newArrayForNewMember,count)
        // if(count == newArrayForNewMember.length){
            let counting = 0;
            const getInvFunction = (row) => {
                if(counting >= newArrayForNewMember.length){
                    cb(customerListing)
                    return;
                }
                const arrayId = row;
                companies = arrayId.companies || [];
                companies.forEach(cmp => {
                    businessPlan = cmp.plan || [],
                    
                    businessPlan.forEach(planName => {
                        var match = false;
                        
                        if(BusinessPlan.length == 0){
                            // companiess.forEach(bplans => {
                            //     if(bplans.id == cmp.id){
                            //         var plans = bplans.plan;
                            //         plans.forEach(planData => {
                            //             if(planData.plan_status != 'deactivated' && planData.plan_name == planName.planName){
                                            BusinessPlan.push(planName.planName);
                            //             }
                            //         })
                            //     }
                            // })
                        }else{
                            BusinessPlan.forEach(data => {
                                if(data == planName.planName){
                                    match = true;
                                }
                            })
                            if(!match){
                                // companiess.forEach(bplans => {
                                //     if(bplans.id == cmp.id){
                                //         var plans = bplans.plan;
                                //         plans.forEach(planData => {
                                //             if(planData.plan_status != 'deactivated' && planData.plan_name == planName.planName){
                                                BusinessPlan.push(planName.planName);
                                //             }
                                //         })
                                //     }
                                // })
                            }
                        }
                    })
                })
                // if(arrayId.email != 'dooey@mailinator.com')
                // {
                    // console.log('arrayId.firstName',arrayId.firstName,BusinessPlan)
                    if(!arrayId.isAdmin && arrayId.email != 'dooey@mailinator.com'){
                        if(customerListing.length == 0){
                            customerListing.push({
                                profileUrl : arrayId.profileUrl,
                                firstName : arrayId.firstName,
                                lastName : arrayId.lastName,
                                email : arrayId.email,
                                phoneNo : arrayId.phoneNo,
                                userStatus : arrayId.userStatus,
                                id:arrayId.id,
                                createdAt:arrayId.createdAt,
                                socialPlan : arrayId.socialPlanName,
                                isDeleted : arrayId.isDeleted,
                                businessPlan : BusinessPlan
                            })
                        }else{
                            var match = false;
                            customerListing.forEach(data => {
                                if(data.id == arrayId.id){
                                    match = true;
                                   if(data.userStatus != arrayId.userStatus){
                                        data.userStatus = arrayId.userStatus
                                   }
                                }
                            })
                            if(!match){
                                customerListing.push({
                                    profileUrl : arrayId.profileUrl,
                                    firstName : arrayId.firstName,
                                    lastName : arrayId.lastName,
                                    email : arrayId.email,
                                    phoneNo : arrayId.phoneNo,
                                    userStatus : arrayId.userStatus,
                                    id:arrayId.id,
                                    createdAt:arrayId.createdAt,
                                    socialPlan : arrayId.socialPlanName,
                                    isDeleted : arrayId.isDeleted,
                                    businessPlan : BusinessPlan
                                }) 
                            }
                        }
                        BusinessPlan = []
                        
                        
                    }
                // }
        
                
                // console.log('customerListing',customerListing);
                counting += 1;
                getInvFunction(newArrayForNewMember[counting]);
            }
            getInvFunction(newArrayForNewMember[counting]);
           
        // }
    })
}
export const getContactManagmentData = function(tableData,customerListing,cb){
    // debugger // eslint-disable-line
    var index = 1
    var dataArray=[]
    tableData=[]
    customerListing.forEach(data=>{
    dataArray.push(data)
    if(index % 10 == 0)
    {
        tableData.push(dataArray)
        dataArray=[];
    }
    else if(index == customerListing.length)
    {
        tableData.push(dataArray)
        dataArray=[];
    }

    index++;

    })
    cb(tableData)
          
}

//FOR INCOMING REQUEST ARRAY
 export const incomingUserData = function(SOCIALREQUEST,IncomingRequestArray,senderId,cb){
    db.collection(SOCIALREQUEST).
    onSnapshot(querySnapshot=>{
        IncomingRequestArray = []
        querySnapshot.forEach(doc=>{
            if(doc.data().id != ''){
                if(doc.data().receiverId == senderId && doc.data().status == 0){
                    IncomingRequestArray.push({
                        id:doc.data().id,
                        receiverId:doc.data().receiverId,
                        senderId:doc.data().senderId,
                        status:doc.data().status,
                        firstName:'',
                        lastName:'',
                        profileUrl:''

                    }) 
                }
            }
        })
        cb(IncomingRequestArray)
    })
 }

 export const getIncomingrequestCounting = function(IncomingRequestArray,getUserData,cb){
    // setTimeout(() => {               
        let count = 0;
        const getInvFunction = (row) => {
            if(count >= IncomingRequestArray.length){
                cb(IncomingRequestArray)
                return;
            }
            const key = row.senderId;
            var invIndex = getUserData.findIndex(item => {
                return item.id === key 
            })
            if(invIndex != -1){
                row.firstName = getUserData[invIndex].firstName ;
                row.lastName = getUserData[invIndex].lastName;
                row.profileUrl = getUserData[invIndex].profileUrl  ;
                
            }
            //console.log('>>>>>>>>>>>>',IncomingRequestArray);


            count += 1;
            getInvFunction(IncomingRequestArray[count]);
        }
        getInvFunction(IncomingRequestArray[count]);
    // }, 1000);
 }


 // FOR GET OLD AND  NEW MWMBER
 export const  getnewmember = function(USER,NewGroupMemberId,loginCustomeId,cb){
    // setTimeout(() => { 
       var  NewGroupMember = []
        let count = 0;
        const getInvFunction = (row) => {
            if(count >= NewGroupMemberId.length){
                cb(NewGroupMember)
                return;
            }
            const key = row;
            if(key != loginCustomeId){
                db.collection(USER).doc(key).get().then(docdata => {
                    // console.log("Check User",docdata.exists ,docdata.id)
                    if(docdata.exists && docdata.data().email != Vue.prototype.$email){
                        db.collection(USER).doc(firebase.auth().currentUser.uid).collection('socialContact').doc(key).get().then(doc=>{
                            if(!doc.data().block )
                            {
                                NewGroupMember.push({
                                    'firstName' : docdata.data().firstName,
                                    'lastName' : docdata.data().lastName,
                                    'profileUrl' : docdata.data().profileUrl,
                                    'id' : docdata.data().id,
                                })
                            }
                        })
                    }
                }) 
            }
            count += 1;
            getInvFunction(NewGroupMemberId[count]);
        }
        // console.log('New members',NewGroupMember)
        getInvFunction(NewGroupMemberId[count]);
    // }, 1000);
 }

 export const  getoldmember = function(USER,NewGroupMemberId,loginCustomeId,cb){
    // setTimeout(() => { 
      var NewGroupMember = []              
        let count = 0;
        const getInvFunction = (row) => {
            if(count >= NewGroupMemberId.length){
                cb(NewGroupMember)
                return;
            }
            const key = row;
            if(key != loginCustomeId){
                db.collection(USER).doc(key).get().then(docdata => {
                   if(docdata.exists){
                       NewGroupMember.push({
                           'firstName' : docdata.data().firstName,
                           'lastName' : docdata.data().lastName,
                           'profileUrl' : docdata.data().profileUrl,
                           'id' : docdata.data().id,
                       })
                   }
                }) 
            }
            count += 1;
            getInvFunction(NewGroupMemberId[count]);
        }
        getInvFunction(NewGroupMemberId[count]);
    // }, 1000);
 }

export const getSocialContactData = function(USER,senderId,cb){

    db.collection(USER).doc(senderId).collection('socialContact').onSnapshot((querySnapshot) => {
        var contactList = []
        querySnapshot.forEach(doc => {
            if(doc.exists){
                if(doc.data().isDeleted == false){
                    db.collection(USER).doc(doc.id).get().then(snap => {
                        if(snap.exists && snap.data().firstName!=='') {
                            contactList.push(snap.data().email) 
                        }
                    })
                }
            }
        })
       
        cb(contactList)
      })
      
}
export const getSocialphonenumberData = function(USER,senderId,cb){

    db.collection(USER).doc(senderId).collection('socialContact').onSnapshot((querySnapshot) => {
        var contactList = []
        querySnapshot.forEach(doc => {
           if(doc.exists){
            db.collection(USER).doc(doc.id).get().then(snap => {
                if(snap.exists && snap.data().firstName!==''){
                    contactList.push(Number(snap.data().phoneNo)) 
                }
            })
           }
        })
       
        cb(contactList)
      })
      
}
export const selectPollOption = function(SOCIALMESSAGE,defaultcollection,chatId,defaultid,cb){
    db.collection(SOCIALMESSAGE).doc(chatId).collection(defaultcollection).doc(defaultid).get().then((doc)=>{
        cb(doc.data())
    })
}
export const updatePollAnswer = function(SOCIALMESSAGE,defaultcollection,chatId,defaultid,uploadData,pollquestions,cb){
    db.collection(SOCIALMESSAGE).doc(chatId).collection(defaultcollection).doc(defaultid).update({
        uploadPollData: uploadData,
        questions:pollquestions
    })
    cb('update')
}
export const updateAnswerCollection = function(SOCIALMESSAGE,defaultcollection,chatId,defaultid,userid,option,cb){
    db.collection(SOCIALMESSAGE).doc(chatId).collection(defaultcollection).doc(defaultid).collection('answer').doc(userid).get().then(doc =>{
        if(!doc.exists){
            db.collection(SOCIALMESSAGE).doc(chatId).collection(defaultcollection).doc(defaultid).collection('answer').doc(userid).set({
                ans:option,
                chatId:defaultid
            })
            cb('updateAnswerCollection')
        }
    })
}


export const checkIfRequestIsAlreadySend = function(SOCIALREQUEST,cb){
    db.collection(SOCIALREQUEST).
    onSnapshot(querySnapshot=>{
       var socialRequestArray = []
        querySnapshot.forEach(doc=>{
            socialRequestArray.push({
                id:doc.data().id,
                receiverId:doc.data().receiverId,
                senderId:doc.data().senderId,
                status:doc.data().status,
            }) 
        })
        cb(socialRequestArray)
    })
}

export const getMessageSeenCount = function(docId,collectionName,uid,cb)
{
    db
        .collection('socialMessages')
        .doc(docId)
        .collection(collectionName)
        .get().then(querySnapshot=>{
            if(!querySnapshot.empty)
            {
                var count = 0
                querySnapshot.forEach(doc=>{
                    var found = false
                    doc.data().messageSeen.forEach(ids=>{
                        if(ids.id==uid)
                        {
                            found = true
                        }
                    })
                    if(!found)
                    {
                        count++
                    }
                })

                cb(count)
            }
        })
        .catch(error=>{
        // console.log(error)
        })
}
export const Invitation = function(socialinvite,cb){
   var inViteUserId = []
    db.collection(socialinvite).onSnapshot((querySnapshot) => {
        querySnapshot.forEach(doc=>{
            inViteUserId.push(doc.data())  
        })
        cb(inViteUserId)
    })
}
export const getFreePlanObject = function(COMPANY,companyid,cb){
    var freeplanobject = {}
    db.collection(COMPANY).get().then(querySnapshot => {
        var index = querySnapshot.size
        freeplanobject = {}
        querySnapshot.forEach(doc => {
            if(doc.data().id == companyid){
                var plans = doc.data().plan
                plans.forEach(docData => {
                    if(docData.payment_status == 'free' && docData.plan_type == 'business'){
                        freeplanobject = docData
                    }
                })
                index--
                // console.log(index,querySnapshot.size)
                if(index==0)
                {
                    // console.log('called')
                    cb(freeplanobject)
                }
            }
            else
            {
                index--
                // console.log(index,querySnapshot.size)
                if(index==0)
                {
                    // console.log('called')
                    cb(freeplanobject)
                }
            }
        })
    })

}
export const getCompanyUserArray = function(COMPANY,cpmpanyid,cb){
    var companyuserArray = []
    db.collection(COMPANY).doc(cpmpanyid).get().then(doc=>{
        var users = doc.data().users
        users.forEach(data => {
            companyuserArray.push({'email':data.email,'status':data.status})
        })
        cb(companyuserArray)
    })
}
export const getBotuserData = function(USER,cb){
    db.collection(USER).where('email','==',Vue.prototype.$email).get().then(docs => {
        var botUser = {};
        if(!docs.empty){
            docs.forEach(doc => {
                botUser = doc.data();
            })
            cb(botUser)
        }
    })
}
export const getLoginUserData = function(USER,cb){
    var userData = {};
    db.collection(USER).doc(firebase.auth().currentUser.uid).onSnapshot(doc=>{
        userData = doc.data();
        cb(userData);
    })
}
export const getAllEmailArray = function(USER,cb){
    db.collection(USER).onSnapshot((querySnapshot) => {
        var emailArray = [];
        querySnapshot.forEach(doc=>{
            emailArray.push(doc.data());
            if(doc.data().isAdmin){
                Vue.prototype.$adminDetails = doc.data();
            }
        })
        cb(emailArray)
    })
}
export const getAllPhoneArray = function(USER,cb){
    db.collection(USER).onSnapshot((querySnapshot) => {
        var phonenumberArray = [];
        querySnapshot.forEach(doc=>{
            if(doc.data().phoneNo != '' && doc.data().phoneNo != null){
                phonenumberArray.push(doc.data());
            }
              if(doc.data().isAdmin){
                Vue.prototype.$adminDetails = doc.data();
              }
        })
        cb(phonenumberArray)
    })
}
export const getAllUserIdArray = function(USER,cb){
    db.collection(USER).onSnapshot((querySnapshot) => {
        var UserId = [];
        querySnapshot.forEach(doc=>{
            UserId.push({'uid':doc.data().id});
            if(doc.data().isAdmin){
                Vue.prototype.$adminDetails = doc.data();
            }
        })
        cb(UserId)
    })
}
export const getSocialPlanData = function(USER,userid,cb){
    var socialPlanObject = {}
    db.collection(USER).doc(userid).collection('socialPlan').doc(userid).onSnapshot(doc => {
        if(doc.exists){
            socialPlanObject = doc.data();
        }
        cb(socialPlanObject)
    })
}