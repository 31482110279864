import Vue from 'vue'
import Vuex from 'vuex'

import firebase from '../views/firebaseInit'
import { getAcseptUserData,Contactlist,getContactCount,getDefaultCollection,getActiveChat,getCounting,getCount,incomingUserData,getIncomingrequestCounting,getUserData,getActiveMsgArray } from "../utils/getQueeryActiveChatList";
import { getAcseptUserData2,getActiveChat2,getCounting2,getCount2,getActiveMsgArray2 } from "../businessUtills/getQueeryActiveChatList";
import { firebasecollection } from "../utils/firebaseCollection";


const db = firebase.firestore()

Vue.use(Vuex);

let chatDataSnap = null;

export default new Vuex.Store({
  state: {
    companies: [],
    userData:{},
    chatId:'',
    chatData:{},
    chatMessages:[],

    lastVisible:{},

    socialChatList:[],
    socialContactList : [],
    businessChatList:[],

    selectedCompany:'',

    collectionName:'',

    newMessageInSocial:false,
    newMessageInBusiness:false,
    newMessageInDirectMessage:false,
    newMessageIngroup:false,

    incomingRequests:[]
  },
  mutations: { //syncronous
    //Set User data using uid
    setUser(state,payload){
      state.userData = payload;
      state.companies = payload.companies||[];
      localStorage.removeItem('SelectedCompany','')
      if(payload.companies!= undefined)
      {
        if(payload.currentSelectedCompany!="")
        {
          payload.companies.forEach(data=>{
            if(data.id==payload.currentSelectedCompany)
            {
              Vue.prototype.$companyName = data
              localStorage.setItem('SelectedCompany',JSON.stringify(data))
              state.selectedCompany=data
              // console.log('selectedCompany',data)
            }
          })
        }
        else
        {
          Vue.prototype.$companyName = payload.companies[0]||''
          localStorage.setItem('SelectedCompany',JSON.stringify(payload.companies[0]||''))
          state.selectedCompany=payload.companies[0]||''
          // console.log('selectedCompany',payload.companies[0]||'')
        }
      }
    },

    //get incomingRequests
    setIncomingRequests(state,payload){
      // console.log("IncomingRequestArray",payload)
      state.incomingRequests=payload
    },

    //set the chatId of the opened Chat
    setChatId(state,payload)
    {
      state.chatId=payload
    },

    //Get the opened chat data from database and assign it.
    setChatData(state,payload)
    {
      state.chatData = payload
    },
    setLastVisible(state,payload)
    {
      state.lastVisible= payload
    },

    //get messages of the opened chat and assign it.
    setMessages(state,payload){
      state.chatMessages=payload
    },
    setSocialContactList(state,payload){
      state.socialContactList=payload
    },
    //Get chat list for social and business
    setSocialChatList(state,payload){
      state.socialChatList=payload
    },
    setBusinessChatList(state,payload){
      state.businessChatList=payload
    },

    //Set current selected Collection
    setCollectionName(state,payload){
      state.collectionName=payload
    },

    //Check if a new message arrived in social/business
    setNewMessageInSocial(state,payload){
      // console.log("checkCountSocial",payload)
      var count = 0
      var channelPresent = ''
      payload.forEach(data=>{
        var valid = false
        if(data.firstName=="" && data.title!="" || data.firstName!="" && data.title=="" )
        {
          valid = true
        }
        if(!data.isDeleted && !data.block && valid && data.blockBy != firebase.auth().currentUser.uid)
        {
          data.messageSeenIds.forEach(ids=>{
            if(ids.count>0 && ids.id==firebase.auth().currentUser.uid)
            {
              count=ids.count;
            }
          })
          if(data.mainBranches.length!=0)
          {
            data.mainBranches.forEach(mb=>{
              mb.messageSeenIds.forEach(ids1=>{
                if(ids1.count>0 && ids1.id==firebase.auth().currentUser.uid)
                {
                  count=ids1.count;
                }
              })
              if(mb.subBranches){
              mb.subBranches.forEach(sb=>{
                sb.messageSeenIds.forEach(ids2=>{
                  if(ids2.count>0 && ids2.id==firebase.auth().currentUser.uid)
                  {
                    count=ids2.count;
                  }
                })
                if(sb.subSubBranches){
                sb.subSubBranches.forEach(ssb=>{
                  ssb.messageSeenIds.forEach(ids3=>{
                    if(ids3.count>0 && ids3.id==firebase.auth().currentUser.uid)
                    {
                      count=ids3.count;
                    }
                  })
                })
                }
              })
              }
            })
          }
        }
      })
      // console.log("SocialCount",count,state.userData)

      if(count>0)
      {
        state.newMessageInSocial=true
        if(state.userData.userRole == 'Social'){
          const favicon = document.getElementById("favicon");
          favicon.href="../../favicon-16x16.png";
          document.addEventListener("visibilitychange", function() {
            if (document.hidden) {
              const favicon = document.getElementById("favicon");
              favicon.href="../../favicon-16x16.png";
            }
            else {
              const favicon = document.getElementById("favicon");
              favicon.href="../../favicon-16x16.png";
            }
          });
        }
      }
      else
      {
        state.newMessageInSocial=false
       
        if(state.userData.userRole == 'Social'){
          // if (document.hasFocus()) {
            // console.log('hasFocus')
            const favicon = document.getElementById("favicon");
            favicon.href="../../icon.png";
            document.addEventListener("visibilitychange", function() {
              if (document.hidden) {
                const favicon = document.getElementById("favicon");
                favicon.href="../../icon.png";
              }
              else {
                const favicon = document.getElementById("favicon");
                favicon.href="../../icon.png";
              }
            });
          // }
          // else{
          //     // console.log('hasNotFocus')
          //     const favicon = document.getElementById("favicon");
          //     favicon.href="../../favicon-16x16.png";
          //   }
        }
      }
    },
    setNewMessageInBusiness(state,payload){
      // console.log("checkCountBusiness",payload)
      var count = 0
      payload.forEach(data=>{
        var valid = false
        if(data.firstName=="" || data.title=="")
        {
          valid = true
        }
        // console.log("valid",valid,data)
        if(!data.isDeleted && !data.block && valid &&  data.blockBy != firebase.auth().currentUser.uid)
        {
          data.messageSeenIds.forEach(ids=>{
            if(ids.count>0 && ids.id==firebase.auth().currentUser.uid)
            {
              count=ids.count;
            }
          })
          if(data.mainBranches.length!=0)
          {
            data.mainBranches.forEach(mb=>{
              mb.messageSeenIds.forEach(ids1=>{
                if(ids1.count>0 && ids1.id==firebase.auth().currentUser.uid)
                {
                  count=ids1.count;
                }
              })
              mb.subBranches.forEach(sb=>{
                sb.messageSeenIds.forEach(ids2=>{
                  if(ids2.count>0 && ids2.id==firebase.auth().currentUser.uid)
                  {
                    count=ids2.count;
                  }
                })
                sb.subSubBranches.forEach(ssb=>{
                  ssb.messageSeenIds.forEach(ids3=>{
                    if(ids3.count>0 && ids3.id==firebase.auth().currentUser.uid)
                    {
                      count=ids3.count;
                    }
                  })
                })
              })
            })
          }
        }
      })
      // console.log("BusinessCount",count,state.userData)
      if(count>0)
      { 
        state.newMessageInBusiness=true
        
        if(state.userData.userRole == 'Business'){
          
          const favicon = document.getElementById("favicon");
          favicon.href="../../favicon-16x16.png";
          document.addEventListener("visibilitychange", function() {
            if (document.hidden) {
              const favicon = document.getElementById("favicon");
              favicon.href="../../favicon-16x16.png";
            }
            else {
              const favicon = document.getElementById("favicon");
              favicon.href="../../favicon-16x16.png";
            }
          });
        }
      

// test

      }
      else
      { 
        state.newMessageInBusiness=false
        
          if(state.userData.userRole == 'Business'){
            // if (document.hasFocus()) {
              // console.log('hasFocus')
            const favicon = document.getElementById("favicon");
            favicon.href="../../icon.png";
          document.addEventListener("visibilitychange", function() {
            
              if (document.hidden) {
                const favicon = document.getElementById("favicon");
                favicon.href="../../icon.png";
              }
              else {
                const favicon = document.getElementById("favicon");
                favicon.href="../../icon.png";
              }
          
            });
          // }
          // else{
          //     console.log('hasNotFocus')
          //     const favicon = document.getElementById("favicon");
          //     favicon.href="../../favicon-16x16.png";
          //   }
           
        }
       
        
      }
    },
    setNewMessageInDirectChat(state,payload){
      // debugger // eslint-disable-line
      var count = 0
      payload.forEach(data=>{
        var valid = false
        if(data.title =="")
        {
          valid = true
        }
        // console.log("valid",valid,data)
        if(!data.isDeleted && !data.block && valid)
        {
          data.messageSeenIds.forEach(ids=>{
            if(ids.count>0 && ids.id==firebase.auth().currentUser.uid)
            {
              count+=ids.count;
            }
          })
          if(data.mainBranches.length!=0)
          {
            data.mainBranches.forEach(mb=>{
              mb.messageSeenIds.forEach(ids1=>{
                if(ids1.count>0 && ids1.id==firebase.auth().currentUser.uid)
                {
                  count+= ids1.count;
                }
              })
              mb.subBranches.forEach(sb=>{
                sb.messageSeenIds.forEach(ids2=>{
                  if(ids2.count>0 && ids2.id==firebase.auth().currentUser.uid)
                  {
                    count+=ids2.count;
                  }
                })
                sb.subSubBranches.forEach(ssb=>{
                  ssb.messageSeenIds.forEach(ids3=>{
                    if(ids3.count>0 && ids3.id==firebase.auth().currentUser.uid)
                    {
                      count+=ids3.count;
                    }
                  })
                })
              })
            })
          }
        }
      })
      // console.log("BusinesstitleCount",count)
      if(count>0)
      { 
        state.newMessageInDirectMessage=true

      }
      else
      { 
        state.newMessageInDirectMessage=false
      
       
      }
      
    },
    setNewMessageInGroup(state,payload){
      var count = 0
      payload.forEach(data=>{
        var valid = false
        if(data.title!="")
        {
          valid = true
        }
        // console.log("valid",valid,data)
        if(!data.isDeleted && !data.block && valid)
        {
          data.messageSeenIds.forEach(ids=>{
            if(ids.count>0 && ids.id==firebase.auth().currentUser.uid)
            {
              count=ids.count;
            }
          })
          if(data.mainBranches.length!=0)
          {
            data.mainBranches.forEach(mb=>{
              mb.messageSeenIds.forEach(ids1=>{
                if(ids1.count>0 && ids1.id==firebase.auth().currentUser.uid)
                {
                  count=ids1.count;
                }
              })
              mb.subBranches.forEach(sb=>{
                sb.messageSeenIds.forEach(ids2=>{
                  if(ids2.count>0 && ids2.id==firebase.auth().currentUser.uid)
                  {
                    count=ids2.count;
                  }
                })
                sb.subSubBranches.forEach(ssb=>{
                  ssb.messageSeenIds.forEach(ids3=>{
                    if(ids3.count>0 && ids3.id==firebase.auth().currentUser.uid)
                    {
                      count=ids3.count;
                    }
                  })
                })
              })
            })
          }
        }
      })
      // console.log("BusinesstitleCount",count,state.userData)
      if(count>0)
      { 
        state.newMessageIngroup=true

      }
      else
      { 
        state.newMessageIngroup=false
      
       
      }
    }
  },
  actions: { //asyncronous
    //Set User Data
    setUserData(state,payload){
      return new Promise((resolve,reject)=>{
        db
          .collection('users')
          .doc(payload)
          .onSnapshot(doc=>{
            if(doc.exists)
            {
              // console.log('UserData',doc.data())
              state.commit("setUser",doc.data())
              resolve(true)
            }
          },error=>{
            reject(error)
          })
      })
    },

    //Set selected ChatID
    setStoreChatId(state,payload){
      state.commit('setChatId',payload)
    },

    //get chat Data of opened chat in social side
    getSetSocialChatData(state,payload){
      // console.log("ChatData",payload)
      if(payload.chatId){
      state.commit('setChatId',payload.chatId)

      return new Promise((resolve, reject)=>{
          if(chatDataSnap !== null) {
            chatDataSnap();
          }

          chatDataSnap = db
            .collection(payload.collection)
            .doc(payload.chatId)
            .onSnapshot(doc=>{
              if(doc.exists){
                state.commit('setChatData',doc.data())
                // console.log('ChatData',doc.data())
                resolve(true)
              } else {
                reject(false)
              }
            })
        })
      }
    },

    //get chat Data of opened chat in business side
    getSetBusinessChatData(state,payload){

      state.commit('setChatId',payload.chatId)

      if(payload.chatId != ''){
        if(chatDataSnap !== null) {
            chatDataSnap();
        }

        chatDataSnap = db
        .collection(payload.companyId)
        .doc(payload.companyId)
        .collection(payload.collection)
        .doc(payload.chatId)
        .onSnapshot(doc=>{
          state.commit('setChatData',doc.data())
          // console.log('ChatData',doc.data())

          // return new Promise({value:true})
        })
      }
    },

    //get messages of the opened chat.
    getSetChatMessages(state,payload){
      // console.log("UserData",state.state)
      const userData = state.state.userData
      const chatData = state.state.chatData

      // console.log('data',userData,chatData,payload)
      var messages = []
      var mypollanswer=''
      var finalMessages=[]

      var lastVisible = {}

      const compareObjects=(object1, object2, key)=>{
        const obj1 = object1[key];
        const obj2 = object2[key];
        if (obj1 < obj2) {
          return -1
        }
        if (obj1 > obj2) {
          return 1
        }
        return 0
      }

      const checkPollAnswer=function(){
        // var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // finalMessages = [];
        // console.log('======================',collectionName)
        messages.forEach(data=>{
          var answered = false
          data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
        // data.beforeTime = (new Date(data.createdAt.seconds * 1000).getMonth()+1)+'/'+ new Date(data.createdAt.seconds * 1000).getDate()
          
          data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
          data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
          data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())

          var userId = userData.id

          if(data.messageType=='receiver'){
            var seen = false
            var connected  = false
            var uidObj = ''

            chatData.connectedUser.forEach(uids=>{
              if(uids==userId)
              {
                connected = true
                uidObj = uids
              }
            })
            // console.log('msgSeen',data)
            data.messageSeen.forEach((val)=>{
              if(val.id == userId)
              {
                seen=true
              }
            })

            var doReadReceipt = false

            if(userData.SocialreadReceipts || chatData.isGroup)
            {
              doReadReceipt = true
            }

            // console.log('shradddggggghhhahahahh',!seen,connected,doReadReceipt,data)
            if(!seen && connected && doReadReceipt)
            {
              // console.log('AAVSACASDAWD',self.userData,data)
              // db
              //   .collection(firebasecollection.USER)
              //   .doc(localStorage.getItem("customerDocKey"))
              //   .get().then(doc=>{
              //     if(doc.exists)
              //     {
                    data.messageSeen.push({
                      id:userId,
                      name:userData.firstName,
                      avtar:userData.profileUrl,
                      messageSeenTime:new Date(),
                    })
                    if(data.defaultid!='')
                    {
                      // console.log('data.defaultid',data.chatId,self.currCollection,data.defaultid)
                      db
                        .collection(payload.collection)
                        .doc(payload.chatId)
                        .collection(payload.messageCollection)
                        .doc(data.defaultid)
                        .update({
                          messageSeen: data.messageSeen
                          // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                          //   id:userId,
                          //   name:self.userData.firstName,
                          //   avtar:self.userData.profileUrl,
                          //   messageSeenTime:new Date(),
                          // }),
                        })
                        .catch(error=>{
                          // console.log('123',error)
                        })
                    }
                //   }
                // })
                // .catch(error=>{
                  // console.log('456',error)
                // })

            }
            else
            {
              if(data.type=="Text_Poll" || data.type=="Image_Poll")
              {
                var timesplit = data.question.time.split(':')
                var datesplit = data.question.date.split('-')
      
                // console.log('ABCD',timesplit,datesplit)
      
                data.expireyTimeObjects.hrs = timesplit[0]
                data.expireyTimeObjects.min = timesplit[1]
      
                data.expireyTimeObjects.year = datesplit[0]
                data.expireyTimeObjects.month = datesplit[1]
                data.expireyTimeObjects.date = datesplit[2]
      
                db
                  .collection(payload.collection)
                  .doc(payload.chatId)
                  .collection(payload.messageCollection)
                  .doc(data.defaultid)
                  .collection('answer')
                  .get().then(querySnapshot=>{
                    if(!querySnapshot.empty)
                    {
                      querySnapshot.forEach(doc=>{
                        if(doc.id==userData.id)
                        {
                          mypollanswer = doc.data().ans
                          answered=true
                        }
                      })
                    }
                    if(answered)
                    {
                      data.mypollanswer = mypollanswer
                      data.answered=true
                    }
                    else
                    {
                      data.answered=false
                    }
                    
                  })
                  // .catch(error=>{console.log(error)})
              }
            }
          }
          else
          {
            if(data.type=="Text_Poll" || data.type=="Image_Poll")
            {
              var timesplit = data.question.time.split(':')
              var datesplit = data.question.date.split('-')
    
              // console.log('ABCD',timesplit,datesplit)
    
              data.expireyTimeObjects.hrs = timesplit[0]
              data.expireyTimeObjects.min = timesplit[1]
    
              data.expireyTimeObjects.year = datesplit[0]
              data.expireyTimeObjects.month = datesplit[1]
              data.expireyTimeObjects.date = datesplit[2]
    
              db
                .collection(payload.collection)
                .doc(payload.chatId)
                .collection(payload.messageCollection)
                .doc(data.defaultid)
                .collection('answer')
                .get().then(querySnapshot=>{
                  if(!querySnapshot.empty)
                  {
                    querySnapshot.forEach(doc=>{
                      if(doc.id==userData.id)
                      {
                        mypollanswer = doc.data().ans
                        answered=true
                      }
                    })
                  }
                  if(answered)
                  {
                    data.mypollanswer = mypollanswer
                    data.answered=true
                  }
                  else
                  {
                    data.answered=false
                  }
                  
                })
                // .catch(error=>{console.log(error)})
            }
          }
        })
        finalMessages = messages
        finalMessages.sort((book1, book2) => {
          return compareObjects(book1, book2, 'createdAt')
        })
        // console.log("Messages",finalMessages)
        state.commit('setMessages',finalMessages)
        state.commit('setLastVisible',lastVisible)
        // self.sendreceArrayBKP=finalMessages
      }

      db
        .collection(payload.collection)
        .doc(payload.chatId)
        .collection(payload.messageCollection)
        .orderBy('createdAt','desc')
        .limit(15)
        .onSnapshot(querySnapshot=>{
          // console.log('Test',querySnapshot)
          messages=[]
          lastVisible=querySnapshot.docs[querySnapshot.docs.length-1]||state.state.lastVisible
          querySnapshot.forEach(docdata=>{
            if(userData.id == docdata.data().from.id){
              // console.log('helloooooooooooooIn','fdddfdgdfgdgdf',docdata.data());
              messages.push({
                messageType: 'sender',
                sendermsg :docdata.data().msg,
                createdAt : docdata.data().createdAt,
                Time:((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())),
                senderName : docdata.data().from.name,
                type:docdata.data().type,
                name:docdata.data().name,
                msg:docdata.data().msg,
                question:docdata.data().questions,
                polldata:docdata.data().uploadPollData,
                defaultid:docdata.data().id,
                 messageSeen:docdata.data().messageSeen,
                chatId:payload.chatId,
                answered:false,
                mypollanswer:'',
                expireyTimeObjects:{
                  date:'',
                  month:'',
                  year:'',
                  hrs:'',
                  min:''
                },
                 beforeDate:{
                  Datesplit:'',
                  monthsplit:'',
                  yearsplit:''
                },
                branch:docdata.data().branch||'',
              })
            }else{
              messages.push({
                messageType: 'receiver',
                receivermsg :docdata.data().msg,
                Time : new Date(docdata.data().createdAt.seconds * 1000).toLocaleTimeString().replace(/:\d+ /, ' '),
                createdAt:docdata.data().createdAt,
                senderName : docdata.data().from.name,
                type:docdata.data().type,
                name:docdata.data().name,
                msg:docdata.data().msg,
                question:docdata.data().questions,
                polldata:docdata.data().uploadPollData,
                defaultid:docdata.data().id,
                 messageSeen:docdata.data().messageSeen,
                chatId:payload.chatId,
                answered:false,
                mypollanswer:'',
                expireyTimeObjects:{
                  date:'',
                  month:'',
                  year:'',
                  hrs:'',
                  min:''
                },
                 beforeDate:{
                  Datesplit:'',
                  monthsplit:'',
                  yearsplit:''
                },
                branch:docdata.data().branch||'',
              })
            }
          })
          checkPollAnswer()
        })
    },
    getsetChatScrollMessages(state,payload){
      // console.log("UserData",state.state)
      const userData = state.state.userData
      const chatData = state.state.chatData

      // console.log('data',userData,chatData,payload)
      var messages = []
      var mypollanswer=''
      var finalMessages=[]

      var lastVisible={}

      const compareObjects=(object1, object2, key)=>{
        const obj1 = object1[key];
        const obj2 = object2[key];
        if (obj1 < obj2) {
          return -1
        }
        if (obj1 > obj2) {
          return 1
        }
        return 0
      }

      const checkPollAnswer=function(){
        // var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // finalMessages = [];
        // console.log('======================',collectionName)
        messages.forEach(data=>{
          var answered = false
          data.beforeTime = ((new Date(data.createdAt.seconds * 1000).getDate() < 10 ?'0'+ new Date(data.createdAt.seconds * 1000).getDate():new Date(data.createdAt.seconds * 1000).getDate())+'/'+((new Date(data.createdAt.seconds * 1000).getMonth()+1) < 10 ? '0'+ (new Date(data.createdAt.seconds * 1000).getMonth()+1):(new Date(data.createdAt.seconds * 1000).getMonth()+1)))
        // data.beforeTime = (new Date(data.createdAt.seconds * 1000).getMonth()+1)+'/'+ new Date(data.createdAt.seconds * 1000).getDate()
          
          data.beforeDate.Datesplit = (new Date(data.createdAt.seconds * 1000).getDate())
          data.beforeDate.monthsplit = (new Date(data.createdAt.seconds * 1000).getMonth())
          data.beforeDate.yearsplit = (new Date(data.createdAt.seconds * 1000).getFullYear())

          var userId = userData.id

          if(data.messageType=='receiver'){
            var seen = false
            var connected  = false
            var uidObj = ''

            chatData.connectedUser.forEach(uids=>{
              if(uids==userId)
              {
                connected = true
                uidObj = uids
              }
            })
            // console.log('msgSeen',data)
            data.messageSeen.forEach((val)=>{
              if(val.id == userId)
              {
                seen=true
              }
            })

            var doReadReceipt = false

            if(userData.SocialreadReceipts || chatData.isGroup)
            {
              doReadReceipt = true
            }

            // console.log('shradddggggghhhahahahh',!seen,connected,doReadReceipt,data)
            if(!seen && connected && doReadReceipt)
            {
              // console.log('AAVSACASDAWD',self.userData,data)
              // db
              //   .collection(firebasecollection.USER)
              //   .doc(localStorage.getItem("customerDocKey"))
              //   .get().then(doc=>{
              //     if(doc.exists)
              //     {
                    data.messageSeen.push({
                      id:userId,
                      name:userData.firstName,
                      avtar:userData.profileUrl,
                      messageSeenTime:new Date(),
                    })
                    if(data.defaultid!='')
                    {
                      // console.log('data.defaultid',data.chatId,self.currCollection,data.defaultid)
                      db
                        .collection(payload.collection)
                        .doc(payload.chatId)
                        .collection(payload.messageCollection)
                        .doc(data.defaultid)
                        .update({
                          messageSeen: data.messageSeen
                          // messageSeen:firebaseTimestamp.firestore.FieldValue.arrayUnion({
                          //   id:userId,
                          //   name:self.userData.firstName,
                          //   avtar:self.userData.profileUrl,
                          //   messageSeenTime:new Date(),
                          // }),
                        })
                        .catch(error=>{
                          // console.log('123',error)
                        })
                    }
                //   }
                // })
                // .catch(error=>{
                  // console.log('456',error)
                // })

            }
            else
            {
              if(data.type=="Text_Poll" || data.type=="Image_Poll")
              {
                var timesplit = data.question.time.split(':')
                var datesplit = data.question.date.split('-')
      // 
                // console.log('ABCD',timesplit,datesplit)
      
                data.expireyTimeObjects.hrs = timesplit[0]
                data.expireyTimeObjects.min = timesplit[1]
      
                data.expireyTimeObjects.year = datesplit[0]
                data.expireyTimeObjects.month = datesplit[1]
                data.expireyTimeObjects.date = datesplit[2]
      
                db
                  .collection(payload.collection)
                  .doc(payload.chatId)
                  .collection(payload.messageCollection)
                  .doc(data.defaultid)
                  .collection('answer')
                  .get().then(querySnapshot=>{
                    if(!querySnapshot.empty)
                    {
                      querySnapshot.forEach(doc=>{
                        if(doc.id==userData.id)
                        {
                          mypollanswer = doc.data().ans
                          answered=true
                        }
                      })
                    }
                    if(answered)
                    {
                      data.mypollanswer = mypollanswer
                      data.answered=true
                    }
                    else
                    {
                      data.answered=false
                    }
                    
                  })
                  // .catch(error=>{console.log(error)})
              }
            }
          }
          else
          {
            if(data.type=="Text_Poll" || data.type=="Image_Poll")
            {
              var timesplit = data.question.time.split(':')
              var datesplit = data.question.date.split('-')
    
              // console.log('ABCD',timesplit,datesplit)
    
              data.expireyTimeObjects.hrs = timesplit[0]
              data.expireyTimeObjects.min = timesplit[1]
    
              data.expireyTimeObjects.year = datesplit[0]
              data.expireyTimeObjects.month = datesplit[1]
              data.expireyTimeObjects.date = datesplit[2]
    
              db
                .collection(payload.collection)
                .doc(payload.chatId)
                .collection(payload.messageCollection)
                .doc(data.defaultid)
                .collection('answer')
                .get().then(querySnapshot=>{
                  if(!querySnapshot.empty)
                  {
                    querySnapshot.forEach(doc=>{
                      if(doc.id==userData.id)
                      {
                        mypollanswer = doc.data().ans
                        answered=true
                      }
                    })
                  }
                  if(answered)
                  {
                    data.mypollanswer = mypollanswer
                    data.answered=true
                  }
                  else
                  {
                    data.answered=false
                  }
                  
                })
                // .catch(error=>{console.log(error)})
            }
          }
        })
        finalMessages = state.state.chatMessages.concat(messages)
        finalMessages.sort((book1, book2) => {
          return compareObjects(book1, book2, 'createdAt')
        })
        // console.log("Messages",finalMessages)
        state.commit('setMessages',finalMessages)
        state.commit('setLastVisible',lastVisible)
        // self.sendreceArrayBKP=finalMessages
      }

      db
        .collection(payload.collection)
        .doc(payload.chatId)
        .collection(payload.messageCollection)
        .orderBy('createdAt','desc')
        .startAfter(state.state.lastVisible)
        .limit(15)
        .onSnapshot(querySnapshot=>{
          // console.log('Test',querySnapshot)
          messages=[]
          lastVisible=querySnapshot.docs[querySnapshot.docs.length-1]
          querySnapshot.forEach(docdata=>{
            if(userData.id == docdata.data().from.id){
              // console.log('helloooooooooooooIn','fdddfdgdfgdgdf',docdata.data());
              messages.push({
                messageType: 'sender',
                sendermsg :docdata.data().msg,
                createdAt : docdata.data().createdAt,
                Time:((new Date(docdata.data().createdAt.seconds * 1000).getHours() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getHours() : new Date(docdata.data().createdAt.seconds * 1000).getHours())+':'+(new Date(docdata.data().createdAt.seconds * 1000).getMinutes() < 10 ? '0'+new Date(docdata.data().createdAt.seconds * 1000).getMinutes() : new Date(docdata.data().createdAt.seconds * 1000).getMinutes())),
                senderName : docdata.data().from.name,
                type:docdata.data().type,
                name:docdata.data().name,
                msg:docdata.data().msg,
                question:docdata.data().questions,
                polldata:docdata.data().uploadPollData,
                defaultid:docdata.data().id,
                 messageSeen:docdata.data().messageSeen,
                chatId:payload.chatId,
                answered:false,
                mypollanswer:'',
                expireyTimeObjects:{
                  date:'',
                  month:'',
                  year:'',
                  hrs:'',
                  min:''
                },
                 beforeDate:{
                  Datesplit:'',
                  monthsplit:'',
                  yearsplit:''
                },
                branch:docdata.data().branch||'',
              })
            }else{
              messages.push({
                messageType: 'receiver',
                receivermsg :docdata.data().msg,
                Time : new Date(docdata.data().createdAt.seconds * 1000).toLocaleTimeString().replace(/:\d+ /, ' '),
                createdAt:docdata.data().createdAt,
                senderName : docdata.data().from.name,
                type:docdata.data().type,
                name:docdata.data().name,
                msg:docdata.data().msg,
                question:docdata.data().questions,
                polldata:docdata.data().uploadPollData,
                defaultid:docdata.data().id,
                 messageSeen:docdata.data().messageSeen,
                chatId:payload.chatId,
                answered:false,
                mypollanswer:'',
                expireyTimeObjects:{
                  date:'',
                  month:'',
                  year:'',
                  hrs:'',
                  min:''
                },
                 beforeDate:{
                  Datesplit:'',
                  monthsplit:'',
                  yearsplit:''
                },
                branch:docdata.data().branch||'',
              })
            }
          })
          checkPollAnswer()
        })
    },
    getSetContactList(state,payload){
      
      var acseptUserData = [];
      var getUserData = [];
      var contactListingData = [];
      var searchContactArray = []
      var user = localStorage.getItem("customerDocKey");
      Contactlist(
        firebasecollection.USER,
        acseptUserData,
        (res) => {
          acseptUserData = res;
          // console.log(' self.acseptUserData', acseptUserData)
          getContactCount(
            firebasecollection.USER,
            getUserData,
            acseptUserData,
            (res2) => {
              contactListingData = res2;
              searchContactArray = res2;
              state.commit("setSocialContactList",contactListingData)
              // console.log('self.contactListingData',contactListingData)
              // getDefaultCollection(
              //   firebasecollection.SOCIALMESSAGE,
              //   contactListingData,
              //   (res) => {
              //     contactListingData = res;
              //     searchContactArray = res;
              //     // setTimeout(() => {
              //       state.commit("setSocialContactList",contactListingData)
              //     // },2000)
              //     // console.log('self.contactListingData',contactListingData)
              //   }
              // );
            }
          );
        }
      );
    },
    //get social chat list and assign it.
    getSetSocialChatList(state, payload){
      var socialMessage = [];
      var chatListArray = [];
      var activeChatListArray = [];
      var searchContactArray = [];
      var acseptUserData = [];
      var getUserData = [];
      var user = state.state.userData.id;

      let calledOnce = false;

      const sortArray = function (mainBranchArray)
      {  
        var tmp = mainBranchArray||[];
        var tmp2 = '';  
        for (let index = 0; index < tmp.length; index++) {
          for (let j = index+1; j < tmp.length; j++) {
            if(tmp[index].lastMsgTime.seconds < tmp[j].lastMsgTime.seconds)
            {
              tmp2 = tmp[index]
              tmp[index] = tmp[j]
              tmp[j]=tmp2
            }
          }
        }
        return tmp
      }
      getAcseptUserData(firebasecollection.USER, user, (res) => {
        acseptUserData = res;
        getActiveChat(firebasecollection.SOCIALMESSAGE, socialMessage, (activeRes) => {
          socialMessage = activeRes;
          // console.log('abc',socialMessage)
          getCount(socialMessage, acseptUserData, activeChatListArray, user, (countRes) => {
            activeChatListArray = countRes;
            // console.log('def', activeChatListArray)
            getCounting(firebasecollection.USER, getUserData, activeChatListArray, (countingRes) => {
              chatListArray = countingRes.filter(x => !x.isDeleted && (x.firstName !== "" || x.title !== ""));
              // console.log('ghi',countingRes)
              if(calledOnce) {
                chatListArray.forEach( dt => {
                  sortArray(dt.mainBranches)
                })
                state.commit("setSocialChatList",chatListArray)
                state.commit("setNewMessageInSocial",chatListArray)
              } else {
                getActiveMsgArray(firebasecollection.SOCIALMESSAGE, chatListArray, (activeMsgRes) => {
                  calledOnce = true;
                  chatListArray = activeMsgRes
                  searchContactArray = activeMsgRes
                  // console.log('chatListArray',chatListArray)
                  chatListArray.forEach( dt => {
                    sortArray(dt.mainBranches)
                  })
                  state.commit("setSocialChatList",chatListArray)
                  state.commit("setNewMessageInSocial",chatListArray)
                })
              }
            })
          })
        })
      })
    },

    //get business chat list and assign it.
    getSetBusinessChatList(state,payload){
      // console.log('payloaddddddddddddddd')
      var socialMessage=[]
      var chatListArray = []
      var activeChatListArray=[]
      var acseptUserData=[]
      var getUserData=[]
      var searchContactArray=[]
      var user = firebase.auth().currentUser.uid
      let companyId = Vue.prototype.$companyName
      var selectCompany = JSON.parse(localStorage.getItem('SelectedCompany'))

      let calledOnce = false;

      const sortArray = function (mainBranchArray)
      {
        // console.log('sortCalled')
        // console.log('arr',mainBranchArray)
  
        var tmp = mainBranchArray||[]
        var tmp2 = ''
  
        for (let index = 0; index < tmp.length; index++) {
          for (let j = index+1; j < tmp.length; j++) {
            if(tmp[index].lastMsgTime.seconds < tmp[j].lastMsgTime.seconds)
            {
              tmp2 = tmp[index]
              tmp[index] = tmp[j]
              tmp[j]=tmp2
            }
          }
        }
        return tmp
      }
      // console.log("getAcseptUserDataBusiness",firebasecollection.USER,user,companyId.id)
      if(selectCompany){
        getAcseptUserData2(firebasecollection.USER,user,selectCompany.id, (userRes)=>{
            acseptUserData = userRes;
            if(Vue.prototype.$companyName){
                getActiveChat2(Vue.prototype.$companyName.id,firebasecollection.BUSINESSMESSAGE,socialMessage, (matchChatRes)=>{
                    socialMessage = matchChatRes;
                    getCount2(socialMessage,acseptUserData,activeChatListArray,user, (countRes)=>{
                        activeChatListArray = countRes;
                        getCounting2(firebasecollection.USER,getUserData,activeChatListArray, (countingRes)=>{                          
                            chatListArray = countingRes.filter(x => !x.isDeleted && (x.firstName !== "" || x.title !== ""))
                            if(calledOnce) {
                              // chatListArray = messageRes
                              // searchContactArray = messageRes
                              chatListArray.forEach(dt=>{
                                  sortArray(dt.mainBranches);
                              })

                              state.commit("setBusinessChatList",chatListArray)
                              state.commit("setNewMessageInBusiness",chatListArray)
                              state.commit("setNewMessageInDirectChat",chatListArray)
                              state.commit("setNewMessageInGroup",chatListArray)

                            } else {
                              getActiveMsgArray2(Vue.prototype.$companyName.id,firebasecollection.BUSINESSMESSAGE,chatListArray, (messageRes)=>{
                                calledOnce = true;
                                chatListArray = messageRes
                                searchContactArray = messageRes
                                chatListArray.forEach(dt=>{
                                    sortArray(dt.mainBranches);
                                })
                                state.commit("setBusinessChatList",chatListArray)
                                state.commit("setNewMessageInBusiness",chatListArray)
                                state.commit("setNewMessageInDirectChat",chatListArray)
                                state.commit("setNewMessageInGroup",chatListArray)
                              })
                            }
                        })
                    })
                })
            }
        })
      }
    },

    setCurrentCollection(state,payload){
      state.commit("setCollectionName",payload)
    },

    getSetIncomingRequests(state,payload){
      var IncomingRequestArray = [];
      var userData = [];
      var senderId = firebase.auth().currentUser.uid
      incomingUserData(
        firebasecollection.SOCIALREQUEST,
        IncomingRequestArray,
        senderId,
        (res) => {
          IncomingRequestArray = res;
          getUserData(
            firebasecollection.USER,
            userData,
            (res) => {
              userData = res;
              getIncomingrequestCounting(
                IncomingRequestArray,
                userData,
                (res) => {
                  IncomingRequestArray = res;
                  state.commit("setIncomingRequests",IncomingRequestArray)
                }
              );
            }
          );
        }
      );
    }
  },
  modules: {
  },
  getters:{
    // getUserData(state){
    //   return state.userData
    // }
    getUserData : state => state.userData,

    getCompanies : state => state.companies,

    getChatId : state => state.chatId,

    getChatData : state => state.chatData,

    getChatMessages : state => state.chatMessages,

    getSocialChatList : state => state.socialChatList,

    getBusinessChatList : state => state.businessChatList,

    getSelectedCompany : state => state.selectedCompany,

    getCurrentCollection : state => state.collectionName,

    getNewMessageInSocial : state => state.newMessageInSocial,

    getNewMessageInBusiness : state => state.newMessageInBusiness,

    getSocialIncomingRequests : state => state.incomingRequests,

    getNewDirectMessage : state => state.newMessageInDirectMessage,

    getNewMessageInGroup : state => state.newMessageIngroup,

    getSocialContactList : state => state.socialContactList,
  }
})
