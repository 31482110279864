<!-- Shraddha Chaniyara, 21/06/2021 added validation and bind with firebase and also make code for stayloggin-->


<template>
    <div class="login-wrapper">
            <div class="logo">
                <img src="@/assets/images/logo.png" class="logo-login">
            </div>
            <ValidationObserver ref="log" v-slot="{ handleSubmit }">
            <form class="login-form c_form" @submit.prevent="handleSubmit(AdminLogin)">
                <div class="form-title text-center">
                    <h2>Login</h2>
                    <img src="@/assets/images/afterimg.png">
                </div>
                <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                <div class="form-group mb-5">
                    <!-- <label>Email *</label> -->
                    <input type="text" class="form-control" v-model="email" placeholder="Enter email*" @blur="IsfirstLetterCapital()">
                    <span class="InvalidInput">{{ errors[0] }}</span>
                </div>
                </ValidationProvider>
                <ValidationProvider rules="required|min:6" name="password" v-slot="{ errors }">
                <div class="form-group">
                    <!-- <label>Password *</label> -->
                    <input :type="passwordType" maxlength="25" class="form-control" v-model="password" placeholder="Enter password*" id="password">
                    <!--<i class="bi bi-eye-slash" id="togglePassword" style=" margin-right: px; cursor: pointer; position: absolute;top: 30px;
                    right: 0;" @click="showPassword()"></i>-->
                    <img v-if="passwordType=='password'" src="@/assets/images/eye_close.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye">
                    <img v-else src="@/assets/images/eye_open.png" alt="signin" @click="showPassword();" id="togglePassword" class="password_eye">
                    <span class="InvalidInput">{{ errors[0] }}</span>
                </div>
                </ValidationProvider>
                <div class="forget-link">
                    <div>
                       <label class="checkbox-c">Remember me
                            <input type="checkbox" checked="checked" v-model="stayLoggedIn" @change="stayLogged(email)">
                            <span class="checkmark"></span>
                        </label>
                     </div>
                     <router-link to="/admin-forgotpassword" class="link"> Forgot Password?</router-link>
                </div>
                <div class="btn-wrapper">
                     <!-- <b-button type="button" variant="primary" disabled v-if="isSpinner">                                        
                        Loading....
                    </b-button> -->
                    <button class="btn btn-yellow" v-bind:class="{disabled: isSpinner == true}">Login</button>
                    <span style="color:#42ba96; margin-right:10px;" v-if="resendTimer!=0">You can resend verification mail after 0:{{resendTimer &lt; 10 ?'0'+resendTimer:resendTimer}} min</span>

                </div>
            </form>
            </ValidationObserver>
        <FrontFooter />
    </div>
</template>
<script>
import Vue from "vue";
import firebase from "../firebaseInit";
import firebaseTimestamp from "firebase";
import FrontFooter from "@/components/forntfooter.vue";
// import firebaseData from '../../../src/utils/firebaseCollection.js';
import firebaseData from '../../utils/firebaseCollection.js';
// import SignInFunction from '../../utils/signinFunction.js';
// import {signinFunction} from '../../utils/signinFunction.js'

const db = firebase.firestore()

export default ({
   name:'adminsignin',
    components: {
        FrontFooter,
        firebaseData,
        // signinFunction
    },
   data(){
       return{
           isSpinner: false,
           email:null,
           password:null,
           stayLoggedIn:null,
           resendTimer:0,
           signInClicked:false,
           passwordType:"password"
       }
   },
    created(){   
        //check if remember me is checked   
        if(localStorage.getItem("rememberMe")!=null){
            this.stayLoggedIn=true
            var remember = JSON.parse(localStorage.getItem("rememberMe"))
            this.email = remember.email
        }
        else{
            this.stayLoggedIn=false
        }
    },
    methods: {
    // ADMIN LOGIN(Shraddha)
        AdminLogin(){
            debugger // eslint-disable-line
            var self = this
            //console.log(firebaseData)
            const db = firebase.firestore()
            var isCustomer=false
            // var emailPasswordValid=false
            self.isSpinner = true;
            db.collection(firebaseData.firebasecollection.USER).get().then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    if(doc.exists){
                        if(doc.data().email==self.email && doc.data().isAdmin){
                            isCustomer=true
                        }
                    }
                })
                if(!isCustomer){
                    self.isSpinner = false;
                    self.$notify({
                        title:"Error",
                        text:'no such user exists.',
                        type:"error"
                    })
                }
                else{
                    firebase.auth().signInWithEmailAndPassword(self.email,self.password)
                    .then((userCredential) => {
                        var user = userCredential.user;
                        //console.log(user)

                        if(user.emailVerified){
                            self.$axios.post(firebaseData.firebasecollection.APIDOMAIN + '/userVerificationEmail',{email:self.email,docId:user.uid})
                            .then((result)=>{
                                if(result.data.status==200)
                                {
                                    firebase.auth().signOut()
                                    .then(()=>{
                                        self.$notify({
                                            title:"Success",
                                            text:'Please verify your email in the link sent to your email.',
                                            type:"success"
                                        })
                                        self.isSpinner = false;
                                    })
                                }
                                else
                                {
                                    self.$notify({
                                        title:"Error",
                                        text:result.data.message,
                                        type:"error"
                                    })
                                }
                            })
                            .catch(error=>{
                                self.isSpinner = false;
                                self.signInClicked=true
                                if(self.resendTimer==0){
                                    self.resentLinkTimer()
                                }
                            })
                        }
                        else
                        {
                            //Look for the user doc if Exists
                            db.collection(firebaseData.firebasecollection.USER)
                                .doc(user.uid)
                                .get()
                                .then(doc=>{
                                    //If doc does not exist then create one
                                    if(!doc.exists)
                                    {
                                        db.collection(firebaseData.firebasecollection.USER)
                                            .doc(user.uid)
                                            .set({

                                                BioDiscription : '',
                                                company : '',
                                                countryCode : '',
                                                createdAt:firebaseTimestamp.firestore.FieldValue.serverTimestamp(),
                                                currentSelectedCompany : '',
                                                email : self.email,
                                                emailVerified :true,
                                                firstName : '',
                                                lastName : '',
                                                id : user.uid,
                                                isOnline : '',
                                                lastOnlineTimestamp : '',
                                                phoneNo : '',
                                                profileUrl : Vue.prototype.$defaultProfile,
                                                pushTokens : '',
                                                isAdmin : true,
                                                userSignupNotification:true,
                                                userUpgradeNotification:true,
                                                userSupportTicket:true
                                                // userRole : "Business"
                                            })
                                            .then(()=>{
                                                self.isSpinner = false;
                                                localStorage.setItem("SuperAdminKey",doc.id)
                                                self.$notify({
                                                    title:"Success",
                                                    text:'Admin login successfully.',
                                                    type:"success"
                                                })
                                            })
                                            .catch(error=>{
                                                self.isSpinner = false;
                                                self.$notify({
                                                    title:"Error",
                                                    text:error.message,
                                                    type:"error"
                                                })
                                            })
                                    }
                                    //Else just login
                                    else
                                    {
                                        self.isSpinner = false;
                                        //console.log("Doc Exists!")
                                        localStorage.setItem("SuperAdminKey",doc.id)
                                        self.$notify({
                                            title:"Success",
                                            text:'Admin login successfully.',
                                            type:"success"
                                        })
                                    }
                                    localStorage.setItem("IsLoginUser",'Admin');
                                    // localStorage.setItem("rememberMe",JSON.stringify({email:self.email}))
                                    self.$router.push({name:'admin-panel'});
                                })
                                .catch(error=>{
                                    self.$notify({
                                        title:"Error",
                                        text:error.message,
                                        type:"error"
                                    })
                                })
                        }
                    })
                    .catch((error) => {
                        self.isSpinner = false;
                        self.$notify({
                            title:"Error",
                            text:'We could not match an account to what you have entered, please try again.',
                            type:"error"
                        })
                    });
                }
            })
            .catch(error=>{
                self.isSpinner = false;
                console.log(error)
            })
        },
        // REMEMBER ME (Shraddha)
        stayLogged(email){
            //console.log(this.stayLoggedIn)
            localStorage.setItem('StayAdminLoggedIn',this.stayLoggedIn)
            if(!this.stayLoggedIn){
                localStorage.removeItem('rememberMe')
                localStorage.removeItem("SuperAdminKey");
            }else{
                localStorage.setItem("rememberMe",JSON.stringify({email:email}))
            }
        },
        // EMAIL IS CONVERT IN LOWERCASE
        IsfirstLetterCapital(){
            //email small case
            if(this.email!=''){
                this.email= this.email.toLowerCase()
            }
        },
        resentLinkTimer(){
            var self = this
            if(self.signInClicked && self.resendTimer==0){
                self.resendTimer=60
                self.$notify({
                    title:"Error in verification",
                    text:'Please check your mail if verification link is sent recently else wait for 1 minutes to sent another verification mail',
                    type:"error",
                    duration: 10000
                })
                var interval = setInterval(()=>{
                    if(self.resendTimer>0)
                    {
                        self.resendTimer--
                    }
                    if(self.resendTimer==0)
                    {
                        self.signInClicked=false
                        self.resendTimer=0
                        clearInterval(interval)
                    }
                },1000)
            }
        },
        // TOGGLE PASSWORD (Shraddha)        
        showPassword(){
            if(this.passwordType=='password')
            {
                this.passwordType='text'
            }
            else
            {
                this.passwordType='password'
            }
        },     
    }
})
</script>